import React, { useState, useEffect } from 'react';
import {
    Card,
    Table,
    TableHead,
    TableBody,
    TableSortLabel,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";

import { useHistory, useParams } from 'react-router-dom';
import API from "../../apis/API";
import Routes from "../../router/Routes";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    highlight: {
        color: theme.palette.green_yellow,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    pagination_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color,
        },
    },
}));

const Badges = props => {
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();

    const columns = [
        {
            title: 'ID',
            name: 'badges.id',
        },
        {
            title: 'Nazwa odznaki',
            name: 'badges.name'
        },
        {
            title: 'Typ',
            name: 'badge_type'
        },
        {
            title: 'Punkty w rankingu',
            name: 'points'
        },
    ];
    const [params, setParams] = useState({
        column: 'badges.id',
        direction: 'desc',
        page: 1
    });
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [params]);

    const getData = () => {
        setData(null);
        API.Students.badgeByOption(id, params).then(response => {
            setData(response);
        });
    }

    const handleShowItem = id => history.push(Routes.Badges.Edit(id));

    const handleColumnPress = column => () => {
        if (column === params.column)
            setParams(prev => ({ ...prev, direction: prev.direction === 'desc' ? 'asc' : 'desc' }))
        else
            setParams(prev => ({ ...prev, column, direction: 'desc' }))
    };

    const handlePageChange = (e, page) => {
        setParams(prev => ({ ...prev, page }));
    };

    const renderRow = (item, index) => {
        let name = typeof item?.name === 'string' ? JSON.parse(item?.name) : item?.name;

        return (
            <TableRow key={index} onClick={() => handleShowItem(item.id)}>
                <TableCell>{item.id}</TableCell>
                <TableCell className={classes.highlight}>{name?.pl}</TableCell>
                <TableCell>{item.badge_type}</TableCell>
                <TableCell>{item.points}</TableCell>
            </TableRow>
        );
    }

    return (
        <Card className={classes.table}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((info, index) => (
                            <TableCell key={index}>
                                <TableSortLabel
                                    active={params.column === info.name}
                                    direction={params.direction}
                                    onClick={handleColumnPress(info.name)}
                                >
                                    <label htmlFor={info.name}>
                                        {info.title}
                                    </label>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data === null && (
                        <TableRow>
                            <TableCell colSpan={columns.length} align={'center'}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {!!data?.data?.length && data.data.map(renderRow)}
                </TableBody>
            </Table>
            <div className={classes.pagination}>
                <Pagination
                    className={classes.pagination_class}
                    count={(data?.per_page && data?.total_count) ? Math.ceil(data.total_count / data.per_page) : null}
                    onChange={handlePageChange}
                    page={params.page}
                    showFirstButton
                    showLastButton
                />
            </div>
        </Card>
    );
};

export default Badges;
