import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { TableCell, TableRow } from '@material-ui/core';
import API from "apis/API";
import Routes from "router/Routes";
import FillButton from "components/Buttons/FillButton";
import PaginatedTable from "components/Table/PaginatedTable";
import { faculties } from "apis/Dictionaries";
import moment from "moment";

const Programs = props => {
    const history = useHistory();

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Wydział',
            name: 'faculties.name'
        },
        {
            title: 'Kierunek',
            name: 'field_of_study'
        },
        {
            title: 'Data ostatniej aktywności',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nowy program',
            onClick: () => history.push(Routes.Programs.Create)
        },
    ];

    const [filters, setFilters] = useState([
        {
            options: [ { value: null, label: 'Any' } , ...faculties ],
            name: 'filter',
            label: 'Pokaż wszystkie wydziały',
        },
    ]);

    // useEffect(() => {
    //     API.programs.faculties().then(response => {
    //         let options = [];
    //         let faculties = response.data;

    //         faculties.forEach(item => {
    //             options.push({ value: item.id, label: item.name });
    //         });

    //         setFilters(prev => {
    //             prev[0].options = options;
    //             return prev;
    //         });
    //     });
    // }, []);

    const handleEditItem = id => history.push(Routes.Programs.Edit(id));

    const renderRow = (item, index) => (
        <TableRow key={index} onClick={() => handleEditItem(item.id)}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{parseInt(item.faculty_id) === 0 ? 'Any' : item?.faculty_name}</TableCell>
            <TableCell>{item?.field_of_study}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            filters={filters}
            endpoint={API.programs.byOption}
            renderRow={renderRow}
            columns={columns}
        />
    );
};

export default Programs;

