import React from "react";
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

import DateInput from './DateInput';


const useStyles = makeStyles((theme) => ({
    label: {
        fontWeight: 400,
        color: 'rgb(38, 50, 56)',
        fontSize: '0.875em',
        textAlign: 'center'
    }
}));

const DateRangeInput = ({ name, value, onChange, errors, label = 'data zakończenia' }) => {
    const classes = useStyles();

    const handleChange = ({ target }) => {
        let _value = { ...value };
        _value[target.name] = target.value;

        if (_value.start_date > _value.end_date) {
            if (target.name === 'start_date') {
                _value.end_date = _value.start_date;
            }
            if (target.name === 'end_date') {
                _value.start_date = _value.end_date;
            }
        }
        onChange({ target: { name, value: _value } });
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <DateInput
                    name={'start_date'}
                    value={value.start_date}
                    onChange={handleChange}
                    error={errors?.start_date}
                />
            </Grid>
            <Grid item xs={4} justifycontent="center" className={classes.label}>{label}</Grid>
            <Grid item xs={4}>
                <DateInput
                    name={'end_date'}
                    value={value.end_date}
                    onChange={handleChange}
                    error={errors?.end_date}
                />
            </Grid>
        </Grid>
    );
};

export default DateRangeInput;
