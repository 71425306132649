export default {
    day: {
        color: 'white',
        fontSize: 18,
    },
    daySelected: {
        backgroundColor: '#FFC42B',
    },
    dayDisabled: {
        color: 'gray',
    },
    current: {
        color: '#FFC42B',
    },
}
