import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import {AppBar, Button, IconButton, Menu, MenuItem} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faFont, faLink, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/styles";
import SiteInfoContext from "../../utils/SiteInfoContext";
import Routes from "../../router/Routes";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.15), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 10px 0px rgba(0,0,0,0.02)',
        // left: '249',
        width: 'calc(100% - 249px)',
        height: theme.spacing(7),
        backgroundColor: theme.palette.topbar_background,
        display: 'flex',
        zIndex: '101',
        borderBottom: theme.palette.card_border,
    },
    flexGrow: {
        flexGrow: 1,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '10px',
        color: theme.palette.topbar_color,
        margin: theme.spacing(0, 0, 0, 2),
        cursor: 'pointer',
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
    toolbar: {
        display: 'flex',
        width: '100%',
        padding: '0px 10px',
        justifyContent: 'space-between',
    },
    close_drawer_icon: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.topbar_color,
        '& .MuiSvgIcon-root': {
            fontSize: '1.3em',
        },
    },
    avatar: {},
    titlebar: {
        display: 'flex',
        color: theme.palette.topbar_color,
    },
    avataricon: {
        fontSize: '1.3em',
        color: theme.palette.topbar_color,
    },
    rightControllerArea: {
        display: 'flex',
    },
    controllerArea: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    helper: {
        color: theme.palette.topbar_color,
        fontSize: '8px',
        margin: theme.spacing(0, 2.5),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    vertical_separator: {
        height: '100%',
        width: '1px',
        backgroundColor: theme.palette.topbar_color,
    },
}));

export default function Topbar({className, onSidebarOpen, onSidebarClose, openSidebar, ...rest}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [avatarOpen, setAvatarOpen] = useState(Boolean(anchorEl));
    const [underlineStatus, setUnderlineStatus] = useState(false);
    const [contrastStatus, setContrastStatus] = useState(false);
    const classes = useStyles();
    const siteInfo = useContext(SiteInfoContext);
    let history = useHistory();

    const onMaxTopbar = () => {
        if (openSidebar === false)
            onSidebarOpen();
        else
            onSidebarClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAvatarOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setAvatarOpen(true);
    };

    const handleEdit = () => {
        handleClose();
        if (siteInfo.user?.id)
            history.push(Routes.Users.Edit(siteInfo.user.id));
    };

    const handleLogout = () => {
        handleClose();
        siteInfo.logout();
        history.push('/login');
    };

    const changeFontSize = type => {
        let body = document.body,
            fontSize = parseInt(window.getComputedStyle(body).fontSize.replace("px", ""));

        if (type === 'less' && fontSize > 10) fontSize -= 1;
        if (type === 'more' && fontSize < 22) fontSize += 1;
        if (type === 'normal') fontSize = 16;

        fontSize += "px";
        body.style.fontSize = fontSize;
    };

    const toggleUnderlineLinks = e => {
        e.preventDefault();
        document.body.classList.toggle("links-underline");
        setUnderlineStatus(!underlineStatus);
    };

    const toggleContrast = (e, handle) => {
        e.preventDefault();
        handle();
        setContrastStatus(!contrastStatus);
    };

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <div className={classes.toolbar}>
                <div className={classes.titlebar}>
                    <Button
                        className={classes.close_drawer_icon} onClick={onMaxTopbar}
                        aria-label={!openSidebar ? 'Rozwiń menu' : 'Zwiń menu'}
                        title={!openSidebar ? 'Rozwiń menu' : 'Zwiń menu'}
                    >
                        <MenuIcon/>
                    </Button>
                </div>
                <div className={classes.rightControllerArea}>
                    <div className={classes.controllerArea}>
                        <div className={classes.vertical_separator}/>
                        <Button title="Wczytaj domyślny rozmiar tekstu" onClick={(e) => changeFontSize('normal')}>
                            <div className={classes.helper} name="normal">
                                <FontAwesomeIcon icon={faFont} size="2x"/>
                            </div>
                        </Button>
                        <div className={classes.vertical_separator}/>
                        <Button title="Pomniejsz tekst na stronie" onClick={(e) => changeFontSize('less')}>
                            <div className={classes.helper} name="minus">
                                <FontAwesomeIcon icon={faFont} size="2x"/>
                                <FontAwesomeIcon icon={faMinus} size="1x"/>
                            </div>
                        </Button>
                        <div className={classes.vertical_separator}/>
                        <Button title="Powiększ tekst na stronie" onClick={(e) => changeFontSize('more')}>
                            <div className={classes.helper} name="plus">
                                <FontAwesomeIcon icon={faFont} size="2x"/>
                                <FontAwesomeIcon icon={faPlus} size="1x"/>
                            </div>
                        </Button>
                        <div className={classes.vertical_separator}/>
                        <Button
                            title={!contrastStatus ? "Wyłącz tryb wysokokontrastowy" : "Włącz tryb wysokokontrastowy"}
                            onClick={(e) => toggleContrast(e, siteInfo.toggleContrast)}
                        >
                            <div className={classes.helper}>
                                <FontAwesomeIcon icon={faEye} size="2x"/>
                            </div>
                        </Button>
                        <div className={classes.vertical_separator}/>
                        <Button
                            title={!underlineStatus ? "Wyłącz podkreślenie linków" : "Włącz podkreślenie linków"}
                            onClick={(e) => toggleUnderlineLinks(e)}
                        >
                            <div className={classes.helper}>
                                <FontAwesomeIcon icon={faLink} size="2x"/>
                            </div>
                        </Button>
                        <div className={classes.vertical_separator}/>
                    </div>
                    <div className={classes.title} onClick={handleMenu}>
                        {siteInfo.user?.name + (siteInfo.user?.role?.name ? ` (${siteInfo.user?.role?.name})` : '')}
                    </div>
                    <div className={classes.avatar}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                        >
                            <AccountCircle className={classes.avataricon}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={avatarOpen}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleEdit}>Edytuj profil</MenuItem>
                            <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </AppBar>
    );
};
