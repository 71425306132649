import React, {useEffect, useState} from 'react';
import {
    Modal,
    Backdrop,
    Fade,
    Grid,
    Card,
    Button
} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: theme.palette.card_border,
        boxShadow: theme.shadows[5],
        padding: '25px',
        outline: 'none',
        color: theme.palette.gray,
        fontSize: '0.9375em',
        fontFamily: 'roboto',
        fontWeight: 400,
        width: '400px',
    },
    closeIcon: {
        color: theme.palette.black_white,
        backgroundColor: theme.palette.pink
    },
    closeIconBlock: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    input_box: {
        '& svg': {
            fill: theme.palette.text.secondary
        },
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '12px 30px',
        fontSize: '1em',
        width: '100%',
        fontWeight: '500',
        fontFamily: 'roboto',
        '&::placeholder': {
            color: theme.palette.gray,
            fontWeight: '500',
            fontStyle: 'italic'
        }
    },
    btnSave: {
        height: '100%',
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.9375em',
        },
        '& .MuiButton-containedSecondary:hover': {},
        '&:hover': {
            backgroundColor: theme.palette.pink,
        },
        padding: '4px',
        borderRadius: '0px',
        fontWeight: '400',
        backgroundColor: theme.palette.pink,
        color: theme.palette.black_white,
        width: '100%'
    },
    btnClose: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.9375em',
        },
        '& .MuiButton-containedSecondary:hover': {},
        '&:hover': {
            backgroundColor: theme.palette.gray,
        },
        padding: '4px',
        borderRadius: '0px',
        fontWeight: '400',
        backgroundColor: theme.palette.gray,
        color: theme.palette.black_white,
        width: '100%'
    },
}));


const DeleteModal = (props) => {
    const {openModal, handleClose, handleDelete, title} = props;
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <Card className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {title}
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" className={classes.btnSave} onClick={() => {
                                handleDelete();
                                handleClose();
                            }}>
                                Tak
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" className={classes.btnClose} onClick={() => {
                                handleClose();
                            }}>
                                Nie
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Fade>
        </Modal>
    );
};

export default withRouter(DeleteModal);
