import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications'
import API from "../../apis/API";
import BackToMain from "../../components/Buttons/BackToMain";
import Title from "../../components/Text/Title";
import ValidatorInput from "../../components/Form/ValidatorInput";
import CreateButton from "../../components/Buttons/CreateButton";
import Progress from "../../components/Main/Progress";
import Courses from './Courses';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(20)
    },
    progressContainer: {
        position: 'absolute',
        top: '50%',
        width: 'calc(100% - 600px)',
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        color: theme.palette.pink
    }
}));

const GroupsAdd = props => {
	const { history, title } = props;
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts()
	const [progressStatus, setProgressStatus] = useState(false);
	const [name, setName] = useState('');
	const [courses, setCourses] = useState([]);
	const [selected_courses, setSelectedCourses] = useState([]);
	const [error_name, setErrorName] = useState(false);

	// Get courses.
	useEffect(() => {
		API.groups.courses().then(response => setCourses(response));
	}, []);

	const handleChange = (order, value, error) => {
		if (order === 1) {
			setErrorName(error);
			setName(value);
		} else if (order === 2) {
			setSelectedCourses(value);
		}
	}

	const checkValidate = () => {
		return name.length === 0;
	}

	const handleValidate = () => {
		setErrorName(name.length === 0);
	}

	const handleSave = () => {
		let _courses = [];
		selected_courses.forEach(course => {
			if(course.id) _courses.push(course.id);
		});

		removeAllToasts();
		if (checkValidate()) {
			handleValidate();
			addToast(<label>Proszę wypełnić wszystkie wymagane pola.</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false })
		} else {
			setProgressStatus(true);

			API.groups.create(name, _courses)
				.then(response => {
					if (response.code === 401) {
						history.push('/login');
					} else {
						addToast(<label>{response.message}</label>, { appearance: response.code === 200 ? 'success' : 'error', autoDismissTimeout: response.code === 200 ? 1000 : 3000, autoDismiss: response.code === 200 ? true : false })
						if (response.code === 200) {
							setTimeout(function () { history.push('/groups'); }, 1000);
						}
						setProgressStatus(false);
					}
				})
		}
	}

	return (
		<>
			<BackToMain title="Powrót do listy grup" to="/groups" />
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={classes.card}>
						<ValidatorInput
							title="Nazwa grupy"
							type="text"
							value={name}
							handleChange={(value, error) => handleChange(1, value, error)}
							hasError={error_name}
							error_message="Proszę wypełnić to pole."
						/>
						<Courses
							title="przedomioty w grupie"
							list={courses}
							value={selected_courses}
							handleChange={(value, error) => handleChange(2, value, error)}
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<CreateButton title="Dodaj" onClick={handleSave} />
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default GroupsAdd;
