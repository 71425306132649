import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import FillButton from '../../components/Buttons/FillButton';
import SingleSelect from '../../components/Form/SingleSelect';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center'
    },
    root: {
        display: 'flex',
    },
    input_box: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '10px 20px',
        width: '100%',
        fontSize: '0.8750em'
    },
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.green_yellow
        },
        padding: theme.spacing(0.5),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.green_yellow,
        color: theme.palette.black_white,
        minWidth: 0
    },
    divider: {
        backgroundColor: '#999',
        margin: '5px 0 10px 0'
    },
    truncate: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingInline: '5px'
    }
}));

const ConditionValues = props => {
    const classes = useStyles();
    const { condition, handleChange } = props;
    const ValueForm = () => {
        const [inputVal, setInputVal] = useState('');
        useEffect(() => {
            if (condition.value) { setInputVal(condition.value); }
        }, []);

        let form = null;

        switch (condition.id) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                form = <SingleSelect
                    value={condition.value}
                    handleChange={(selectedId) => { handleChange({ value: selectedId }); }}
                    list={condition.children} />;
                break;
            default:
                form = <input
                    className={classes.input_box}
                    value={inputVal}
                    onBlur={e => handleChange({ value: inputVal })}
                    onChange={e => setInputVal(e.target.value)} />;

        }

        return form;
    };
    const RangeInput = (props) => {
        const { item, handleChange } = props;
        const [min, setMin] = useState('');
        const [max, setMax] = useState('');

        useEffect(() => {
            if (item.min) setMin(item.min);
            if (item.max) setMax(item.max);
        }, []);

        const handleInputChange = (val, type) => {
            val = parseFloat(val);
            if(item.id === 5) {
                val = val < 1 ? 1: val;
            } else {
                val = val < 2 ? 2 : val;
                val = val > 5.5 ? 5.5 : val;
            }
            val = type === 'max' && min && val < min ? min : val;
            val = type === 'min' && max && val > max ? max : val;
            type === 'min' ? setMin(val) : setMax(val);
        };

        return <Grid container alignItems="center" style={{ marginTop: '10px' }}>
            <Grid item xs={4}>
                <input
                    className={classes.input_box}
                    type="number"
                    value={min}
                    onChange={e => handleInputChange(e.target.value, 'min')}
                    onBlur={e => handleChange(min, max)} />
            </Grid>
            <Grid item xs={4} className={`${classes.label}`}>
                &lt;
                <span className={classes.truncate} style={{ paddingInline: '5px' }}>{item.name}</span>
                &lt;
            </Grid>
            <Grid item xs={4}>
                <input
                    className={classes.input_box}
                    type="number"
                    value={max}
                    onChange={e => handleInputChange(e.target.value, 'max')}
                    onBlur={e => handleChange(min, max)} />
            </Grid>
        </Grid>;
    };
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}><div className={classes.truncate} title={condition.label}>{condition.label}</div></Grid>
                <Grid item xs>
                    <ValueForm />
                </Grid>
            </Grid>
            {condition.id === 4 || condition.id === 5 || condition.id === 7
                ? <RangeInput item={condition} handleChange={(min, max) => { handleChange({ conditionId: condition.id, min, max }); }} />
                : <></>
            }
        </>
    );
};


const Conditions = props => {
    const { title, value, conditions, handleChange } = props;
    const classes = useStyles();

    const handleValueChange = (val, index) => {
        let _value = [...value];
        if (val.value) _value[index]['value'] = val.value;

        if (val.conditionId && (val.conditionId === 4 || val.conditionId === 5 || val.conditionId === 7)) {
            _value[index]['min'] = val.min;
            _value[index]['max'] = val.max;
        }
        handleChange(_value);
    };

    const handleConditionSelect = (selectedId, index) => {
        if (!selectedId) return;

        let _value = [...value];
        let selectedCondition = conditions.find(c => parseInt(c.id) === parseInt(selectedId));

        if (selectedCondition.id !== 0 && selectedCondition.id !== 6 && selectedCondition.id !== 7) {
            selectedCondition['value'] = selectedCondition.children[0]?.id;
        }

        if (index > -1) {
            _value[index] = selectedCondition;
        } else {
            _value.push(selectedCondition);
        }
        handleChange(_value);
    };

    const handleDelete = (index) => {
        let _value = [...value];
        _value.splice(index, 1);
        handleChange(_value);
    };

    const handleCreate = () => {
        let _value = [...value];
        _value.push({ id: 0 });

        if (_value.length < 2) {
            _value.push({ id: 0 });
        }
        handleChange(_value);
    };
    return (
        <Grid container spacing={3} alignItems="flex-start" >
            {title &&
                <Grid item xs={3} className={classes.title}>
                    <label>{title}</label>
                </Grid>
            }
            <Grid item xs={title ? 9 : 12}>
                {value.map((item, index) => (
                    <React.Fragment key={index}>
                        {/* main Conditions */}
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={1}><div>{index + 1}.</div></Grid>
                            <Grid item xs>
                                <SingleSelect
                                    value={item.id}
                                    handleChange={(selectedId) => { handleConditionSelect(selectedId, index); }}
                                    list={conditions} />
                            </Grid>
                            {value.length > 1 &&
                                <Grid item>
                                    <Button variant="contained" color="secondary" className={classes.filled_button}
                                        onClick={() => handleDelete(index)}>
                                        <DeleteIcon onClick={() => handleDelete(index)} />
                                    </Button>
                                </Grid>
                            }
                        </Grid>{/* main conditions */}

                        {/* condition values */}
                        {item.children &&
                            <ConditionValues condition={item} handleChange={value => handleValueChange(value, index)} />
                        } {/*condition values */}
                        {index < value.length - 1 &&
                            <Divider className={classes.divider} />
                        }
                    </React.Fragment>
                ))}
                {value.length < 1 &&
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1}><div>1.</div></Grid>
                        <Grid item xs>
                            <SingleSelect
                                value=''
                                handleChange={(selectedId) => { handleConditionSelect(selectedId, -1); }}
                                list={conditions} />
                        </Grid>
                    </Grid>
                }
                {value.length < 7 &&
                    <Grid item style={{ marginTop: '20px' }}>
                        <FillButton title="Dodaj kolejny warunek" onClick={handleCreate} />
                    </Grid>
                }
            </Grid>
        </Grid >
    );
};

export default Conditions;
