import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {Box, Button, Grid, TextField} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import FillButton from "../../components/Buttons/FillButton";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center'
    },
    input_box: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '10px 20px',
        width: '100%',
        fontSize: '0.8750em'
    },
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.green_yellow
        },
        padding: theme.spacing(0.5),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.green_yellow,
        color: theme.palette.black_white,
    },
}));

const Courses = props => {
    const { title, value, list, handleChange } = props;
    const classes = useStyles();
    const handleLocalChange = (index, val) => {
        let _value = [...value];
        _value[index] = val?.id ? val : {id: 0, name: ''};
        handleChange(_value);
    }

    const handleDelete = (index) => {
        let _value = [...value];
        _value.splice(index, 1);
        handleChange(_value);
    }

    const handleCreate = () => {
        let _value = [...value];
        _value.push({id: 0, name: ''});
        handleChange(_value);
    }
    return (
        <Grid container spacing={3}>
            {title &&
                <Grid item xs={3} className={classes.title}>
                    <label>{title}</label>
                </Grid>
            }
            <Grid item xs={title ? 9 : 12}>
                <Grid container spacing={1} alignItems="center">
                    {
                        value.map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={1}>
                                    <div>
                                        {index + 1}.
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <Autocomplete
                                        options={list}
                                        value={item}
                                        getOptionLabel={option => option.name}
                                        getOptionDisabled={option => value.find(val => val.id == option.id && option.id !== item.id) ? true : false}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {props.name}  ({props.usos_id})
                                            </Box>
                                        )}
                                        onChange={(e, newValue) => handleLocalChange(index, newValue)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" color="secondary" className={classes.filled_button}
                                        onClick={() => handleDelete(index)}>
                                        <DeleteIcon onClick={() => handleDelete(index)} />
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        ))
                    }
                    {value.length < list.length &&
                        <FillButton title="Dodaj kolejny przedmiot" onClick={handleCreate} />
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Courses;
