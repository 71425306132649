import React, {useMemo} from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Card,
    CircularProgress
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Pagination from "@material-ui/lab/Pagination";
import API from "../../apis/API";
import {useHistory, useLocation} from "react-router-dom";
import Title from "../Text/Title";
import FillButton from "../Buttons/FillButton";
import SearchInput from "../Form/SearchInput";
import FilterInput from "../Form/FilterInput";

const useStyles = makeStyles(theme => ({
    highlight: {
        color: theme.palette.green_yellow,
    },
    palette: {
        width: '15px',
        height: '15px',
        border: theme.palette.card_border,
    },
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    controlBlockButtons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    controlBlockFilters: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray,
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white,
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%',
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0),
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em',
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    pagination_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color,
        },
    },
}));

export default function PaginatedTable({title, buttons, filters, columns, endpoint, renderRow, customParams = {}}) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const page = parseInt(urlParams.get('page')) || 1;
    const search = urlParams.get('search') || '';
    const direction = urlParams.get('direction') || 'desc';
    const selectedFilters = useMemo(() => [...urlParams.entries()].filter(
        ([key,]) => filters?.some(({ name }) => key === name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [urlParams]);
    const column = parseInt(urlParams.get('column')) || 0;

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if (!endpoint)
            return;

        setData(null);

        const params = {
            page,
            direction,
            column: columns[column].name,
            search,
            ...Object.fromEntries(selectedFilters),
            ...customParams
        };

        let promise;

        switch (typeof endpoint) {
            case 'string':
                promise = API.get(endpoint, {params});
                break;
            case 'function':
                promise = endpoint(params);
                break;
            default:
                throw new TypeError('Endpoint of type ' + typeof endpoint + ' is not supported');
        }

        promise.then(response => setData(response.data)).catch(() => setData(false));
    }, [endpoint, columns, page, search, direction, column, selectedFilters]);

    const pushHistory = () => history.push({
        pathname: location.pathname,
        search: new URLSearchParams( [...urlParams.entries()].filter( ([, value]) => value !== '' ) ).toString(),
    });

    const handleSearch = value => {
        urlParams.set('search', value);
        urlParams.set('page', '1');
        pushHistory();
    };
    const handleFilter = (name, onChange) => value => {
        if(onChange) onChange(value);
        urlParams.set(name, value);
        pushHistory();
    };
    const onColumnPress = index => () => {
        if (index === column)
            urlParams.set('direction', direction === 'asc' ? 'desc' : 'asc');
        else
            urlParams.set('column', index);
        pushHistory();
    };
    const changePage = (e, page) => {
        urlParams.set('page', page);
        pushHistory();
    };

    return (
        <>
            <Title value={title}/>
            <div className={classes.controlBlock}>
                <div className={classes.controlBlockButtons}>
                    {buttons?.map((button, index) => (
                        <FillButton key={index} title={button.title} onClick={button.onClick}/>
                    ))}
                </div>
                <div className={classes.controlBlockFilters}>
                    {filters?.map((filter, index) => (
                        <FilterInput key={index} value={Object.fromEntries(selectedFilters)[filter.name]} id={index} label={filter.label} options={filter.options} onFilterChange={handleFilter(filter.name, filter.onChange)}/>
                    ))}
                    <SearchInput handleSearch={handleSearch}/>
                </div>
            </div>
            <Card className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((info, index) => (
                                <TableCell key={info.name}>
                                    {
                                        column.isOrderable
                                        ? (
                                            <TableSortLabel
                                            active={column === index}
                                            direction={direction}
                                            onClick={onColumnPress(index)}
                                            >
                                                <label htmlFor={info.name}>
                                                    {info.title}
                                                </label>
                                            </TableSortLabel>
                                        )
                                        : (
                                            <label htmlFor={info.name}>
                                                {info.title}
                                            </label>
                                        )
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data === null && (
                            <TableRow>
                                <TableCell colSpan={columns.length} align={'center'}>
                                    <CircularProgress/>
                                </TableCell>
                            </TableRow>
                        )}
                        {!!data?.data?.length && data.data.map(renderRow)}
                    </TableBody>
                </Table>
                <div className={classes.pagination}>
                    <Pagination
                        className={classes.pagination_class}
                        count={(data?.per_page && data?.total) ? Math.ceil(data.total / data.per_page) : null}
                        onChange={changePage}
                        page={page}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Card>
        </>
    );
};
