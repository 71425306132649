import axios from "axios";

export const API = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Auth
 */

API.auth = {
	login: data => API.post(`/auth/login`, data),
	forgot: (email) => {
		return API.post(`/auth/forgot`, {
			email: email
		})
			.then(response => {
				return response.data;
			}).catch(error => {
				return error;
			});
	},
	resetPassword: (password) => {
		return API.post(`/auth/reset_password`, {
			password: password
		})
			.then(response => {
				return response.data;
			}).catch(error => {
				return error;
			});
	},
	validateToken: () => API.get(`/user/validate_token`),
};

/**
 * Lang
 */

API.lang = {
	get: () => {
		return API
			.get(`/language`)
			.then(response => response.data).catch(error => {
				return error;
			});
	}
};

/**
 * Users
 */

API.users = {
	single: id => API.get(`/users/${id}`),
	all: params => API.get('/users', { params }),
	create: data => API.post('/users/create', data),
	edit: id => API.post(`/users/${id}`),
	delete: id => API.delete(`/users/${id}`),
};

/*
* Semester Organizations
 */

API.SemesterOrganizations = {
	all: () => API.get('calendar_events/semester_organizations'),
	edit: data => API.put(`calendar_events/semester_organizations/${data.id}`, data),
};

API.Terms = {
	all: () => API.get('terms'),
	last_key: () => API.get('terms/last_key'),
	delete: id => API.delete(`terms/${id}`),
};

/**
 * Badges
 */

API.badges = {
	single: (id) => {
		return API.get(`/badge`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	info: () => {
		return API.get(`/badge/info`)
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	create: (en_badge_name, pl_badge_name, en_badge_description, pl_badge_description, badge_type, amount, promoted, allow_certificate, image, selected_students, selected_conditions, addtional_badges, promotion_rules) => {

		let formData = new FormData();

		formData.append("name_en", en_badge_name);
		formData.append("name_pl", pl_badge_name);
		formData.append("description_en", en_badge_description);
		formData.append("description_pl", pl_badge_description);
		formData.append("type", badge_type);
		formData.append("points", amount);
		formData.append("promoted", promoted);
		formData.append("allow_certificate", allow_certificate ? 1 : 0);
		formData.append("image", image);

		// if badge type is manual, add student ids
		if (badge_type === 1) {
			formData.append("selected_students", selected_students);
		}

		// if badge type is automatic, add addtional badge types
		if (badge_type === 2) {
			let newVal = selected_conditions.map(c => {
				delete c.children;
				return c;
			});

			formData.append("selected_conditions", JSON.stringify(newVal));
			formData.append("addtional_badges", JSON.stringify(addtional_badges));
		}

		// if badge type is automatic and promoted, add promotion rules
		if (promoted === 0) {
			formData.append("promotion_rules", JSON.stringify(promotion_rules));
		}

		return API.post(`/badge`, formData, {
			'Content-Type': 'multipart/form-data'
		}).then(response => {
			let data = response.data;
			let ids = data.badge_sub_type_ids;
			if (ids) {
				let fileFormData = new FormData();
				ids.forEach((id) => {
					let badge = addtional_badges.find((badgeType) => parseInt(badgeType.id) === id.temp_id);
					if (badge.image) fileFormData.append(`${id.db_id}`, badge.image);
				});
				API.post(`badge/uploadBadgeTypeFile`, fileFormData, {
					'Content-Type': 'multipart/form-data'
				});
			}
			return data;
		}).catch(error => {
			return error;
		});
	},
	edit: (en_badge_name, pl_badge_name, en_badge_description, pl_badge_description, badge_type, amount, promoted, allow_certificate, image, id, selected_students, selected_conditions, addtional_badges, promotion_rules) => {

		let formData = new FormData();

		formData.append("name_en", en_badge_name);
		formData.append("name_pl", pl_badge_name);
		formData.append("description_en", en_badge_description);
		formData.append("description_pl", pl_badge_description);
		formData.append("type", badge_type);
		formData.append("points", amount);
		formData.append("promoted", promoted);
		formData.append("allow_certificate", allow_certificate ? 1 : 0);
		formData.append("image", image);
		formData.append("id", id);

		// if badge type is automatic, add addtional badge types
		if (badge_type === 1) {
			formData.append("selected_students", selected_students);
		}

		// if badge type is automatic, add addtional badge types
		if (badge_type === 2) {
			let newVal = selected_conditions.map(c => {
				delete c.children;
				return c;
			});

			formData.append("selected_conditions", JSON.stringify(newVal));
			formData.append("addtional_badges", JSON.stringify(addtional_badges));
		}

		// if badge type is automatic and promoted, add promotion rules
		if (promoted === 0) {
			formData.append("promotion_rules", JSON.stringify(promotion_rules));
		}

		return API.post(`/badge/update`, formData, {
			'Content-Type': 'multipart/form-data'
		}).then(response => {
			let data = response.data;
			let ids = data.badge_sub_type_ids;
			if (ids) {
				let fileFormData = new FormData();

				ids.forEach((id) => {
					let badge = addtional_badges.find((badgeType) => parseInt(badgeType.id) === id.temp_id);
					if (badge.image && typeof badge.image !== 'string') fileFormData.append(`${id.db_id}`, badge.image);
				});
				API.post(`badge/uploadBadgeTypeFile`, fileFormData, {
					'Content-Type': 'multipart/form-data'
				});
			}
			return data;
		}).catch(error => {
			return error;
		});
	},
	delete: (id) => {
		return API.delete(`/badge`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	byOption: (params) => {
		return API.get(`/badge/getListByOption`, { params })
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	scoring: () => {
		return API.get(`/badge/scoring`)
			.then(response => response.data).catch(error => {
				return error;
			});
	}
};

/**
 * Zdarzenia
 */

API.zdarzenia = {
	single: (id) => {
		return API.get(`/zdarzenia`, {
			params: {
				id: id
			},
		})
			.then(response => response.data.data.zdarzenia).catch(error => {
				return error;
			});
	},
	save: (data) => {
		let url = `/zdarzenia`;
		if (data.get('id')) {
			url += `/update`;
		}

		return API.post(url, data, {
			'Content-Type': 'multipart/form-data'
		}).then(response => response.data).catch(error => {
			return error;
		});
	},
	delete: (id) => {
		return API.delete(`/zdarzenia`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	byOption: (params) => {
		return API.get(`/zdarzenia/getListByOption`, {
			params
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	types: () => {
		return API.post(`/zdarzenia/getTypes`)
			.then(response => response.data).catch(error => {
				return error;
			});
	}
};

/**
 * Groups
 */

API.groups = {
    import: (file) => API.post('/courses/import', file),
	single: (id) => {
		return API.get(`/group`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	getAll: () => {
		return API.get('/group/all')
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	edit: (name, courses, id) => {
		return API.put(`/group`, {
			id, name, courses
		}).then(response => response.data)
			.catch(error => {
				return error;
			});
	},
	create: (name, courses) => {
		return API.post(`/group`, {
			name: name,
			courses: courses,
		}).then(response => response.data).catch(error => {
			return error;
		});
	},
	delete: (id) => {
		return API.delete(`/group`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	byOption: (sort_column) => {
		return API.post(`/group/getListByOption`, {
			sort_column: sort_column,
		}).then(response => response.data).catch(error => {
			return error;
		});
	},
	courses: () => {
		return API.get(`courses/all`).then(response => response.data).catch(error => error);
	}
};

/**
 * Categories
 */

API.categories = {
	single: (id) => {
		return API.get(`/category`, {
			params: {
				id: id
			},
		})
			.then(response => response.data.code === 200).catch(error => {
				return error;
			});
	},
	create: (name_pl, name_en, color) => {
		return API.post(`/category`, {
			name_en: name_en,
			name_pl: name_pl,
			color: color,
		}).then(response => response.data).catch(error => {
			return error;
		});
	},
	edit: (name_pl, name_en, color, id) => {
		return API.put(`/category`, {
			name_pl: name_pl,
			name_en: name_en,
			color: color,
			id: id
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	delete: (id) => {
		return API.delete(`/category`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	byOption: (sort_column, sort_order, page, searchKey) => {
		return API.post(`/category/getListByOption`, {
			sort_column: sort_column,
			sort_order: sort_order,
			page: page,
			searchKey: searchKey,
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	}
};

// Events
API.CalendarEvents = {
	all: () => API.get('/calendar_events'),
	single: id => API.get(`/calendar_events/single?id=${id}`),
	byOption: (params) => API.get(`/calendar_events`, { params }),
	create: data => API.post('/calendar_events/create', data),
	edit: data => {
		data.append('_method', 'PUT');
		return API.post('/calendar_events/update', data);
	},
	delete: id => API.delete(`/calendar_events/${id}`),
};

API.CalendarSuggestedEvents = {	
	byCategories: (params) => API.get('calendar_events/suggested/by_categories', { params }),
	byOption: (params) => API.get(`/calendar_events/suggested`, { params }),
	create: data => API.post('/calendar_events/suggested/create', data),
	edit: data => {
		data.append('_method', 'PUT');
		return API.post('/calendar_events/suggested/update', data);
	},
	delete: id => API.delete(`/calendar_events/suggested/${id}`),
	massDelete: params => API.post(`/calendar_events/suggested/mass/delete`, params),
	massConfirm: params => API.post(`/calendar_events/suggested/mass/confirm`, params),
	massUnpublish: params => API.post(`/calendar_events/suggested/mass/unpublish`, params),
}

// Events categories
API.CalendarEventsCategories = {
	single: id => API.get(`/calendar_events/categories/single?id=${id}`),
	filter: params => API.get('/calendar_events/categories', { params }),
	all: () => API.get('/calendar_events/categories/all'),
	create: data => API.post('/calendar_events/categories/create', data),
	edit: data => API.post('/calendar_events/categories/edit', data),
	delete: id => API.delete(`/calendar_events/categories/delete?id=${id}`),
};

// Markers
API.Markers = {
	index: params => API.get('/markers', { params }),
	show: id => API.get(`/markers/${id}`),
	create: data => API.post('/markers', data),
	edit: formData => {
		formData.append('_method', 'PUT');
		return API.post('/markers/' + formData.get('id'), formData);
	},
	delete: id => API.delete('markers', { params: { id } }),
};

// Marker categories
API.MarkerCategories = {
	index: params => API.get('/marker_categories', { params }),
	all: () => API.get('/marker_categories/all'),
	show: id => API.get(`/marker_categories/${id}`),
	create: data => API.post('/marker_categories', data),
	edit: formData => {
		formData.append('_method', 'PUT');
		return API.post('/marker_categories/' + formData.get('id'), formData);
	},
	delete: id => API.delete('marker_categories', { params: { id } }),
};

/**
 * Students
 */

API.Students = {
	index: params => API.get(`/students`, { params }),
	show: id => API.get(`/student/${id}`),
	export: () => API.get(`/students/export`, { responseType: 'blob' }),
	iportGraduationDates: data => API.post(`/student_graduation_dates/import`, data),
	badgeByOption: (student_id, params) => API.get(`/students/getBadgesByOption`, {
		params: {
			student_id, params
		}
	}).then(response => response.data).catch(error => {
		return error;
	}),
	delete: id => API.delete(`/students/${id}`),
};

/**
 * Programs
 */


API.programs = {
	single: (id) => {
		return API.get(`/programs`, {
			params: {
				id: id
			},
		})
			.then(response => response.data.data).catch(error => {
				return error;
			});
	},
	save: (data) => {
		let url = `/programs`;
		if (data.get('id')) {
			url += `/update`;
		}

		return API.post(url, data).then(response => response.data).catch(error => {
			return error;
		});
	},
	delete: (id) => {
		return API.delete(`/programs`, {
			params: {
				id: id
			},
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	byOption: (params) => {
		return API.get(`/programs/getListByOption`, {
			params
		})
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	faculties: () => {
		return API.get(`/programs/faculties`)
			.then(response => response.data).catch(error => {
				return error;
			});
	}
};

/**
 * Studies maps
 */

API.studiesMaps = {
	byOption: params => API.get(`/studies_maps/getListByOption`, { params }).then(res => res.data),
	single: (studyType, studyMode) => API.get(`/studies_maps/${studyType}/${studyMode}`),
	update: (data) => API.post(`/studies_maps`, { ...data })
};

API.studentGraduationDates = {
	delete: (id) => {
		return API.delete(`/student_graduation_dates/${id}`)
			.then(response => response.data).catch(error => {
				return error;
			});
	},
	deleteAll: () => {
		return API.delete(`/student_graduation_dates/all`)
			.then(response => response.data).catch(error => {
				return error;
			});
	},
};

export default API;
