import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { TableCell, TableRow } from '@material-ui/core';

import API from "../../apis/API";
import Routes from "../../router/Routes";
import useApiResource from "../../hooks/useApiResource";
import SiteInfoContext from "../../utils/SiteInfoContext";
import PaginatedTable from "../../components/Table/PaginatedTable";
import FillButton from "../../components/Buttons/FillButton";

const Zdarzenia = props => {
    const history = useHistory();
    const siteInfo = useContext(SiteInfoContext);
    const [zdarzeniaTypes] = useApiResource(API.zdarzenia.types);

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa zdarzenia',
            name: 'name_pl'
        },
        {
            title: 'Rodzaj',
            name: 'badge_type'
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj informacje o zdarzeniu',
            onClick: () => history.push(Routes.Zdarzenia.Create)
        },
    ];

    const [filters, setFilters] = useState([
        {
            options: [
                { value: 1, label: '1' },
            ],
            name: 'type',
            label: 'Rodzaj zdarzenia',
            onChange: value => { },
        },
    ]);
    
    useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

    useEffect(() => {
        let options = [];
        if (!zdarzeniaTypes) return;

        zdarzeniaTypes.types.forEach(type => {
            options.push({ value: type.id, label: type.name });
        });

        setFilters(prev => {
            prev[0].options = options;
            return prev;
        });

    }, [zdarzeniaTypes]);

    const handleEditItem = id => history.push(Routes.Zdarzenia.Edit(id));

    const renderRow = (item, index) => (
        <TableRow key={index} onClick={() => handleEditItem(item.id)}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.name?.pl}</TableCell>
            <TableCell>{item.zdarzenia_type}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            filters={filters}
            endpoint={API.zdarzenia.byOption}
            renderRow={renderRow}
            columns={columns}
        />
    );
};

export default Zdarzenia;
