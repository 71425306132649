import Repository from "./Repository";
import Routes from "../router/Routes";
import moment from "moment";
import Validator, {AfterOrEqual, BeforeOrEqual, Required, RequiredIf, ValidISOString, LengthLimit, LengthMin} from "../utils/Validator";
import Messages from "../utils/Messages";

const handleDate = (state, date, field_name) => {    
    state[field_name] = date?.isValid() ? date.toISOString() : date._i
    return state
};

const handleYear = (state, year) => {
    return {
        ...state, 
        year, 
        name: generateName(year),
        usos_id: generateUsosId(year)
    }
};

const handleOrderKey = (state, order_key) => {
    return {
        ...state,
        order_key,
        order_key_sem1: parseInt(order_key) + 10,
        order_key_sem2: parseInt(order_key) + 20,
    }
}

const generateUsosId = (year) => {
    const firstYear = parseInt(year.toString().substring(0, 4))

    let lastYear = year.toString().substring(0, 4) % 100 + 1
    if (lastYear < 10)
    {
        lastYear = '0' + lastYear.toString()
    }
    else if (lastYear >= 100)
    {
        lastYear = firstYear + 1
    }

    return firstYear + '/' + lastYear
}

const generateName = (year) => 'Rok akademicki ' + generateUsosId(year);

export default new class AcademicYearsRepository extends Repository {

    Path = 'terms';

    Routes = Routes.AcademicYears;

    Validators = {
        year: new Validator(Required, LengthLimit(4)),
        order_key: new Validator(Required, LengthMin(2)),

        start_date: new Validator(Required, ValidISOString(), BeforeOrEqual('end_date', Messages.BeforeEndDate)),
        end_date: new Validator(Required, ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date', Messages.AfterStartDate)),
        finish_date: new Validator(Required, ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date', Messages.AfterStartDate)),

        start_date_sem1: new Validator(Required, ValidISOString(), BeforeOrEqual('end_date_sem1', Messages.BeforeEndDate)),
        end_date_sem1: new Validator(Required, ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date_sem1', Messages.AfterStartDate)),
        finish_date_sem1: new Validator(Required, ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date_sem1', Messages.AfterStartDate)),

        start_date_sem2: new Validator(Required, ValidISOString(), BeforeOrEqual('end_date_sem2', Messages.BeforeEndDate)),
        end_date_sem2: new Validator(Required, ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date_sem2', Messages.AfterStartDate)),
        finish_date_sem2: new Validator(Required, ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date_sem2', Messages.AfterStartDate))        
    };

    OnChangeCustomizers = {
        order_key: (e, state) => handleOrderKey(state, e.target.value),
        year: (e, state) => handleYear(state, e.target.value),

        start_date: (e, state) => handleDate(state, moment(e.target.value), 'start_date'),
        end_date: (e, state) => handleDate(state, moment(e.target.value), 'end_date'),
        finish_date: (e, state) => handleDate(state, moment(e.target.value), 'finish_date'),

        start_date_sem1: (e, state) => handleDate(state, moment(e.target.value), 'start_date_sem1'),
        end_date_sem1: (e, state) => handleDate(state, moment(e.target.value), 'end_date_sem1'),
        finish_date_sem1: (e, state) => handleDate(state, moment(e.target.value), 'finish_date_sem1'),

        start_date_sem2: (e, state) => handleDate(state, moment(e.target.value), 'start_date_sem2'),
        end_date_sem2: (e, state) => handleDate(state, moment(e.target.value), 'end_date_sem2'),
        finish_date_sem2: (e, state) => handleDate(state, moment(e.target.value), 'finish_date_sem2'),
    };

    defaultValues = () => {
        const year = moment().year()

        return {
            year,
            name: generateName(year),
            usos_id: generateUsosId(year),
            start_date: null,
            end_date: null,
            finish_date: null,
            start_date_sem1: null,
            end_date_sem1: null,
            finish_date_sem1: null,
            start_date_sem2: null,
            end_date_sem2: null,
            finish_date_sem2: null,
            order_key: null,
            order_key_sem1: null,
            order_key_sem2: null,
        };
    };

    statePreparer = data => data;

    savePreparer = state => state;
}
