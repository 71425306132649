import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button, CircularProgress} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.success.main,
        },
        padding: theme.spacing(0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
}));

export default function SaveButton({title = 'Zapisz', onClick, saving}) {
    const classes = useStyles();

    return (
        <Button variant="contained" color="secondary" className={classes.filled_button} onClick={onClick} disabled={!!saving}>
            {!saving && title}
            {!!saving && <CircularProgress size={20}/>}
        </Button>
    );
};
