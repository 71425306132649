import React from "react";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    text: {
        color: theme.palette.pink_yellow,
        cursor: 'pointer',
        fontFamily: 'roboto',
    },
}));

export default function RedButton({className, variant, children, ...rest}) {
    const classes = useStyles();
    return (
        <Button
            className={clsx({
                [classes.text]: !variant || variant === 'text',
                [className]: !!className,
            })}
            variant={variant}
            {...rest}
        >
            {children}
        </Button>
    );
};
