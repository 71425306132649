import React from 'react';
import {Button, IconButton} from '@material-ui/core';
import {EnFlagIcon, PlFlagIcon} from '../../svg/icons';
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";

const regularClasses = makeStyles(theme => ({
    back: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.green_yellow,
        fontFamily: 'roboto',
        marginBottom: theme.spacing(2),
        fontSize: '0.78em',
        '& svg': {
            fontSize: '0.78em',
        },
    },
    button: {
        textTransform: 'none',
        boxShadow: 'none',
        backgroundColor: '#CECECE',
        padding: '0 15px',
        marginRight: theme.spacing(1),
        border: `1px solid ${theme.palette.background.default}`,
        '&:hover': {
            backgroundColor: '#B1D0E0',
            color: theme.palette.green_yellow,
            border: `1px solid ${theme.palette.green_yellow}`,
        },
    },
    active: {
        textTransform: 'none',
        boxShadow: 'none',
        padding: '0 15px',
        marginRight: theme.spacing(1),
        backgroundColor: '#B1D0E0',
        color: theme.palette.green_yellow,
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#B1D0E0',
        },
    },
    error: {
        border: '1px solid red',
    },
    icon: {
        width: '20px',
    },
}));

const iconsOnlyClasses = makeStyles(theme => ({
    back: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginLeft: theme.spacing(1),
        border: `1px solid ${theme.palette.background.default}`,
        padding: '7px',
        '&:hover': {
            backgroundColor: '#B1D0E0',
            border: `1px solid ${theme.palette.green_yellow}`,
        },
    },
    active: {
        backgroundColor: '#B1D0E0',
        border: '1px solid',
    },
    error: {
        border: '1px solid red',
    },
    icon: {
        maxWidth: '20px',
        maxHeight: '20px',
    },
}));

const extractStyles = (classes, locale, activeLocale, errors) => clsx({
    [classes.button]: true,
    [classes.active]: locale === activeLocale,
    [classes.error]: !!errors?.[locale],
});

export default function LocaleButtons({value, iconsOnly, errors, onChange, className}) {
    const classes = iconsOnly ? iconsOnlyClasses() : regularClasses();

    const setEnglish = () => onChange('en');
    const setPolish = () => onChange('pl');

    if (iconsOnly) {
        return (
            <div className={clsx(classes.back, className)}>
                <IconButton
                    variant="contained"
                    color="default"
                    className={extractStyles(classes, 'pl', value, errors)}
                    onClick={setPolish}
                >
                    <PlFlagIcon className={classes.icon}/>
                </IconButton>
                <IconButton
                    variant="contained"
                    color="default"
                    className={extractStyles(classes, 'en', value, errors)}
                    onClick={setEnglish}
                >
                    <EnFlagIcon className={classes.icon}/>
                </IconButton>
            </div>
        );
    }

    return (
        <div className={clsx(classes.back, className)}>
            <Button
                variant="contained"
                color="default"
                className={extractStyles(classes, 'pl', value, errors)}
                startIcon={<PlFlagIcon className={classes.icon}/>}
                onClick={setPolish}
            >
                {!iconsOnly && 'Polski'}
            </Button>
            <Button
                variant="contained"
                color="default"
                className={extractStyles(classes, 'en', value, errors)}
                startIcon={<EnFlagIcon className={classes.icon}/>}
                onClick={setEnglish}
            >
                {!iconsOnly && 'English'}
            </Button>
        </div>
    );
};
