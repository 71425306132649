import React, {useState} from 'react';
import Title from "../../components/Text/Title";
import Routes from "../../router/Routes";
import BackToMain from "../../components/Buttons/BackToMain";
import {Card, Grid} from "@material-ui/core";
import Html from "../../components/Form/Html";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";
import SaveButton from "../../components/Buttons/SaveButton";
import {makeStyles} from "@material-ui/styles";
import API from "../../apis/API";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
    },
    card: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(20),
    },
    input: {
        display: 'none',
    },
    formControl: {
        display: 'flex',
        alignItems: 'center',
    },
    calendarEventDateInput: {
        marginLeft: 10,
        marginRight: 10,
    },
}));

export const SemesterOrganizationScreen = () => {
    const classes = useStyles();
    const history = useHistory();
    const [selectedYear, setSelectedYear] = useState('');
    const [data, setData] = useState({});
    const [academicalYearsWithSemesters, setAcademicalYearsWithSemesters] = useState([]);

    React.useEffect(() => {
        API.SemesterOrganizations.all().then(res => {
            // ToDo set initial data when user open this page ?
            // let initialSelectedData = res?.data[0];
            //
            // setSelectedYear(initialSelectedData);
            // setData(initialSelectedData?.children[0]);
            setAcademicalYearsWithSemesters(res?.data);
        });
    },[]);

    const sortedSemesters = React.useMemo(() => {
        return academicalYearsWithSemesters?.find(semester => semester.usos_id.includes(selectedYear.usos_id))?.children
    },[selectedYear]);

    const selectSemester = e => setData(sortedSemesters.find(org => String(org.id) === String(e.target.value)));
    const onChangeSelectedYear = e => setSelectedYear(academicalYearsWithSemesters.find(year => String(year.id) === String(e.target.value)))

    const onChange = e => setData({
        ...data,
        organization: {
            ...data?.organization,
            [e.target.dataset.locale]: e.target.value,
        },
    });

    const save = () => API.SemesterOrganizations.edit(data).then(() => history.push(Routes.CalendarEvents.List));

    if (!data)
        return null;

    return(
        <>
            <BackToMain title="Powrót do listy" to={Routes.CalendarEvents.List}/>
            <Title value={'Organizacja semestru'}/>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}>
                                                <label>Wybierz rok akademicki</label>
                                            </Grid>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Dropdown
                                                    name={'id'}
                                                    options={academicalYearsWithSemesters}
                                                    onChange={onChangeSelectedYear}
                                                    titleField={'name'}
                                                    value={selectedYear.id}
                                                />
                                            </Grid>
                                        </Grid>
                                        {!!selectedYear && (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={3} className={classes.title}>
                                                    <label>Wybierz semestr</label>
                                                </Grid>
                                                <Grid item xs={12} md={9} className={classes.formControl}>
                                                    <Dropdown
                                                        name={'id'}
                                                        options={sortedSemesters}
                                                        onChange={selectSemester}
                                                        titleField={'name'}
                                                        value={data?.id}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {(!!selectedYear && !!data?.id) && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className={classes.title}>
                                        <label>Opisz organizację w wybranym semestrze</label>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Html
                                            key={String(data?.id)}
                                            value={data?.organization || ''}
                                            onChange={onChange}
                                            name={'organization'}
                                            translatable
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {/*{!!id && (
                                    <DeleteButton
                                        title="Czy na pewno chcesz usunąć ten zdarzenia?"
                                        handleDelete={handleDelete}/>
                                )}*/}
                            </Grid>
                            <Grid item xs={6}>
                                <SaveButton onClick={() => save()}/>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
