import React from 'react';
import {Grid, Card, CircularProgress} from '@material-ui/core';
import API from "../../apis/API";
import {useHistory, useParams} from "react-router-dom";
import Routes from "../../router/Routes";
import BackToMain from "../../components/Buttons/BackToMain";
import Title from "../../components/Text/Title";
import {makeStyles} from "@material-ui/styles";
import useApiResource from "../../hooks/useApiResource";
import useEditData from "../../hooks/useEditData";
import AcademicYearsRepository from "../../repositories/AcademicYearsRepository";
import FormInput from "../../components/Form/FormInput";
import Html from "../../components/Form/Html";
import DateInput from "../../components/Form/DateInput";
import TimeInput from "../../components/Form/TimeInput";
import Checkbox from "../../components/Form/Checkbox";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";
import Input from "../../components/Form/PlainInputs/Input";
import DateTimeInput from "../../components/Form/DateTimeInput";
import ThumbnailPicker from "../../components/Form/ThumbnailPicker";
import FilesPicker from "../../components/Form/FilesPicker";
import SaveButton from "../../components/Buttons/SaveButton";
import DeleteButton from "../../components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
    },
    card: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(20),
    },
    input: {
        display: 'none',
    },
    formControl: {
        display: 'flex',
        alignItems: 'center',
    },
    calendarEventDateInput: {
        marginLeft: 10,
        marginRight: 10,
    },
}));

const getLastKey = async () => {
            
    const last_key = (await API.Terms.last_key())?.data?.order_key
    return last_key
        ? parseInt(last_key) + 10
        : 3000
}

export default function AcademicYearsEditScreen() {
    const {id} = useParams();
    const history = useHistory();
    const classes = useStyles();
    const {data, setData, errors, saving, onChange, save} = useEditData(AcademicYearsRepository);

    const handleDelete = () => API.Terms.delete(id).then(() => history.push(Routes.AcademicYears.List));

    if (!data) {
        return (
            <>
                <BackToMain title="Powrót do listy" to={Routes.AcademicYears.List}/>
                {(data === null) && <CircularProgress/>}
            </>
        );
    }

    if (!id && data.order_key === null)
    {
        (async () => {
            const last_key = await getLastKey()
            setData({...data, 
                'order_key': last_key,
                'order_key_sem1': last_key + 10,
                'order_key_sem2': last_key + 20,
            })
        })()
    }    

    return (
        <>
            <BackToMain title="Powrót do listy" to={Routes.AcademicYears.List}/>
            <Title value={'Rok Akademicki ' + (data?.title?.pl || '')}/>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <FormInput
                                            title={'Rok początkowy'}
                                            name={'year'}
                                            value={data.year}
                                            onChange={onChange}
                                            error={errors?.year}                                            
                                        />
                                        <FormInput
                                            title={'Nazwa (Wygenerowane automatycznie)'}
                                            name={'name'}
                                            value={data.name}
                                            onChange={onChange}
                                            error={errors?.name}
                                            disabled
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Card className={classes.card}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                         <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Klucz sortowania</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <Input
                                                    name={'order_key'}
                                                    value={data.order_key}
                                                    error={errors?.order_key}
                                                    errorOnNextLine={true}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>USOS ID (Wygenerowane automatycznie)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <Input
                                                    name={'usos_id'}
                                                    value={data.usos_id}
                                                    onChange={onChange}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card className={classes.card}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Rok akademicki</label>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data rozpoczęcia</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'start_date'}
                                                    value={data.start_date}
                                                    onChange={onChange}
                                                    error={errors?.start_date}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data zakończenia (end_date)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'end_date'}
                                                    value={data.end_date}
                                                    onChange={onChange}
                                                    error={errors?.end_date}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data zakończenia (finish_date)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'finish_date'}
                                                    value={data.finish_date}
                                                    onChange={onChange}
                                                    error={errors?.finish_date}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card className={classes.card}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Semestr zimowy</label>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Klucz sortowania (Wygenerowane automatycznie)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <Input
                                                    name={'order_key_sem1'}
                                                    value={data.order_key_sem1}
                                                    error={errors?.order_key_sem1}
                                                    errorOnNextLine={true}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data rozpoczęcia</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'start_date_sem1'}
                                                    value={data.start_date_sem1}
                                                    onChange={onChange}
                                                    error={errors?.start_date_sem1}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data zakończenia (end_date)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'end_date_sem1'}
                                                    value={data.end_date_sem1}
                                                    onChange={onChange}
                                                    error={errors?.end_date_sem1}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data zakończenia (finish_date)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'finish_date_sem1'}
                                                    value={data.finish_date_sem1}
                                                    onChange={onChange}
                                                    error={errors?.finish_date_sem1}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card className={classes.card}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Semestr letni </label>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Klucz sortowania (Wygenerowane automatycznie)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <Input
                                                    name={'order_key_sem2'}
                                                    value={data.order_key_sem2}
                                                    error={errors?.order_key_sem2}
                                                    errorOnNextLine={true}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data rozpoczęcia</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'start_date_sem2'}
                                                    value={data.start_date_sem2}
                                                    onChange={onChange}
                                                    error={errors?.start_date_sem2}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data zakończenia (end_date)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'end_date_sem2'}
                                                    value={data.end_date_sem2}
                                                    onChange={onChange}
                                                    error={errors?.end_date_sem2}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className={classes.title}>
                                                <label>Data zakończenia (finish_date)</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <DateInput
                                                    name={'finish_date_sem2'}
                                                    value={data.finish_date_sem2}
                                                    onChange={onChange}
                                                    error={errors?.finish_date_sem2}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {!!id && (
                                    <DeleteButton
                                        title="Czy na pewno chcesz usunąć ten rok?"
                                        handleDelete={handleDelete}/>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <SaveButton saving={saving} onClick={save}/>
                            </Grid>
                        </Grid>
                    </Card>                    
                </Grid>
            </Grid>
        </>
    );
};
