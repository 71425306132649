import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Card, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

import API from "../../apis/API";
import Routes from "../../router/Routes";
import useEditData from "../../hooks/useEditData";
import useApiResource from "../../hooks/useApiResource";
import SiteInfoContext from "../../utils/SiteInfoContext";
import UsersRepository from "../../repositories/UsersRepository";
import RolesRepository from "../../repositories/RolesRepository";
import BackToMain from "../../components/Buttons/BackToMain";
import SaveButton from "../../components/Buttons/SaveButton";
import DeleteButton from "../../components/Buttons/DeleteButton";
import Title from "../../components/Text/Title";
import FormInput from "../../components/Form/FormInput";
import PasswordInput from "../../components/Form/PasswordInput";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function UsersEditScreen() {
    const history = useHistory();
    const classes = useStyles();
    const siteInfo = useContext(SiteInfoContext);
    const [roles] = useApiResource(RolesRepository.all);
    const { id, data, errors, saving, onChange, save } = useEditData(UsersRepository);
    const isOwnAccount = String(id) === String(siteInfo.user?.id);

    useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

    const handleDelete = () => API.users.delete(id).then(() => {
        if (isOwnAccount) {
            siteInfo.logout();
            history.push('/login');
            return;
        }
        history.push(Routes.Users.List);
    });

    if (!data || !roles) {
        return (
            <>
                <BackToMain title="Powrót do listy użytkowników" to={Routes.Users.List} />
                {(data === null || roles === null) && <CircularProgress />}
            </>
        );
    }

    return (
        <>
            <BackToMain title="Powrót do listy użytkowników" to={Routes.Users.List} />
            <Title value={data.email} />
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card>
                        <FormInput
                            title={'E-mail'}
                            name={'email'}
                            value={data.email}
                            onChange={onChange}
                            error={errors?.email}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} className={classes.title}>
                                <label>Rola</label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.formControl}>
                                <Dropdown
                                    name={'role_id'}
                                    value={data.role_id}
                                    titleField={'name'}
                                    options={roles}
                                    onChange={onChange}
                                    error={errors?.role_id}
                                />
                            </Grid>
                        </Grid>
                        {(isOwnAccount || window.location.pathname.endsWith('create')) && (
                            <>
                                <PasswordInput
                                    title={'Nowe hasło'}
                                    name={'password'}
                                    value={data.password}
                                    onChange={onChange}
                                    error={errors?.password}
                                />
                            </>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DeleteButton
                                    title={isOwnAccount
                                        ? "Czy an pewno chcesz usunąć swoje konto? Operacja jest nieodwracalna"
                                        : 'Czy na pewno chcesz usunąć tego użytkownika?'
                                    }
                                    handleDelete={handleDelete}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SaveButton saving={saving} onClick={save} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
