import React, {useRef} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    btn_list: {
        fontFamily: 'roboto',
        fontSize: '13px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    edit: {
        color: theme.palette.green_yellow,
        cursor: 'pointer',
    },
    remove: {
        color: theme.palette.pink_yellow,
        cursor: 'pointer',
    },
    image: {
        cursor: 'pointer',
        width: '100%',
    },
}));

export default function ThumbnailPicker({value, multiple, name, deleteLabel = 'Usuń', editLabel = 'Edytuj', onChange, ...rest}) {
    const classes = useStyles();
    const ref = useRef();

    const onEdit = () => ref.current.click();
    const onDelete = () => onChange({target: {name, value: null}});
    const handleChangeImage = e => e.target.files.length > 0 && onChange({
        target: {
            name,
            value: multiple ? e.target.files : e.target.files[0],
        },
    });

    return (
        <Grid container spacing={3}>
            {value !== null && (
                <Grid item xs={12}>
                    <img
                        className={classes.image}
                        src={value && (typeof value === "string" ? value : URL.createObjectURL(value))}
                        onClick={onEdit}
                        alt={''}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.btn_list}>
                    <Grid item onClick={onDelete} className={classes.remove}>{deleteLabel}</Grid>
                    <Grid item onClick={onEdit} className={classes.edit}>{editLabel}</Grid>
                </Grid>
            </Grid>
            <input
                ref={ref}
                type={'file'}
                name={name}
                multiple={!!multiple}
                accept="image/*"
                style={{display: 'none'}}
                onChange={handleChangeImage}
                {...rest}
            />
        </Grid>
    );
};
