import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    back: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.green_yellow,
        fontFamily: 'roboto',
        fontSize: '0.78em',
        '& svg': {
            fontSize: '0.78em',
        }
    },
    title: {
        marginLeft: theme.spacing(1)
    }
}));

export default function BackToMain({ title, to }) {
    const classes = useStyles();

    return (
        <Box display="flex" mb={5}>
            <Link className={classes.back} to={to}>
                <ArrowBackIosIcon />
                <div className={classes.title}>{title}</div>
            </Link>
        </Box>
    );
};
