import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        color: theme.palette.pink
    },
}));

const Progress = ({status}) => {
    const classes = useStyles();

    return (
        status ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
            :
            <></>
    );
};

export default withRouter(Progress);
