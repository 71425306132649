import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FillButton from "../../components/Buttons/FillButton";
import ValidatorInput from "../../components/Form/ValidatorInput";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center'
    },
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.green_yellow
        },
        padding: theme.spacing(0.5),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.green_yellow,
        color: theme.palette.black_white,
    },
}));

const Prefixes = props => {
    const { title, value, handleChange } = props;
    const classes = useStyles();
    const handleLocalChange = (index, name) => {
        let _value = [...value];
        _value[index] = name;
        handleChange(_value);
    }

    const handleDelete = (index) => {
        let _value = [...value];
        _value.splice(index, 1);
        handleChange(_value);
    }

    const handleCreate = () => {
        let _value = [...value];
        _value.push('');
        handleChange(_value);
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={3} className={classes.title}>
                <label>{title}</label>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={1} alignItems="center">
                    {
                        value.map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={1}>
                                    <div>
                                        {index + 1}.
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <ValidatorInput
                                        type="text"
                                        value={item}
                                        handleChange={value => handleLocalChange(index, value)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" color="secondary" className={classes.filled_button}
                                        onClick={() => handleDelete(index)}>
                                        <DeleteIcon onClick={() => handleDelete(index)} />
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        ))
                    }
                    <FillButton title="Dodaj kolejny prefiks" onClick={handleCreate} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Prefixes;
