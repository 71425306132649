import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import {validate} from "../utils/Validator";
import Messages from "../utils/Messages";
import makeFormData from "../utils/makeFormData";

/**
 * @param {Repository} Repository Entity repository
 */
export default function useEditData(Repository) {
    const {id} = useParams();
    const history = useHistory();
    const {addToast, removeAllToasts} = useToasts();
    const [data, setData] = React.useState(null);
    const [errors, setErrors] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);

    useEffect(() => {
        if (!id) {
            setData(Repository.defaultValues?.() || {});
            return;
        }

        Repository.single(id).then(data => setData(Repository.statePreparer ? Repository.statePreparer({...data}) : data));
    }, []);

    const onChange = e => {
        const locale = e.target.dataset?.locale;
        const name = e.target.name;
        let value 
        
        if (locale && e.target.type !== 'checkbox')
        {
            value = data[name]
            value[locale] = e.target.value
        } else 
        {
            value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        }

        const newState = Repository.OnChangeCustomizers?.[name]?.(e, {...data}) || {
            ...data,
            [name]: /*locale
                ? {...data[name], [locale]: value}
                :*/ value,
        };

        setData(newState);

        // validation
        const validator = Repository.Validators[name];
        if (!validator)
            return;

        const error = validator(value?.trim ? value.trim() : value, newState);
        setErrors({
            ...(errors || {}),
            [name]: locale
                ? {...(errors?.[name] || {}), [locale]: error}
                : error,
        });
    };

    const save = () => {
        removeAllToasts();
        setSaving(true);
        const values = {...data};

        // validate
        setErrors(null);
        const errors = validate(values, Repository.Validators);
        if (errors) {
            addToast(Messages.SomeFieldsAreInvalid, {
                appearance: 'error',
                autoDismissTimeout: 3000,
                autoDismiss: true,
            });
            setErrors(errors);
            setSaving(false);
            return;
        }

        // submit
        (id ? Repository.edit : Repository.create)(makeFormData(Repository.savePreparer?.(values) || values))
            .then(() => history.push(Repository.Routes.List))
            .catch(() => setSaving(false));
    };

    const confirm = (props = false) => {
        removeAllToasts();
        setConfirming(true);
        const values = {...data};

        // validate
        setErrors(null);
        const errors = validate(values, Repository.Validators);
        if (errors) {
            addToast(Messages.SomeFieldsAreInvalid, {
                appearance: 'error',
                autoDismissTimeout: 3000,
                autoDismiss: true,
            });
            setErrors(errors);
            setConfirming(false);
            return;
        }

        if (props?.needDecline)
        {
            values.need_decline = true
        }
        else 
        {
            values.need_confirm = true            
        }        

        // submit
        (id ? Repository.edit : Repository.create)(makeFormData(Repository.savePreparer?.(values) || values))
            .then(() => history.push(Repository.Routes.List))
            .catch(() => setConfirming(false));
    };

    return {id, data, setData, errors, setErrors, saving, confirming, onChange, save, confirm};
};
