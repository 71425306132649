import {Redirect, Switch, Route} from "react-router-dom";
import React, {useEffect} from "react";
import Routes from "./Routes";
import Main from "../layouts/Main";
import Minimal from "../layouts/Minimal";
import {useToasts} from "react-toast-notifications";
import MarkersListScreen from "../pages/Markers/MarkersListScreen";
import MarkersEditScreen from "../pages/Markers/MarkersEditScreen";
import MarkerCategoriesListScreen from "../pages/MarkerCategories/MarkerCategoriesListScreen";
import MarkerCategoriesEditScreen from "../pages/MarkerCategories/MarkerCategoriesEditScreen";
import Badges from '../pages/Badges/Badges';
import BadgesAdd from "../pages/Badges/BadgesAdd";
import BadgesEdit from "../pages/Badges/BadgesEdit";
import Zdarzenia from '../pages/Zdarzenia/Zdarzenia';
import ZdarzeniaForm from '../pages/Zdarzenia/ZdarzeniaForm';
import CalendarEventsListScreen from "../pages/CalendarEvents/CalendarEventsListScreen";
import CalendarEventsSuggestedListScreen from "../pages/CalendarEvents/CalendarEventsSuggestedListScreen";
import CalendarEventsEditScreen from "../pages/CalendarEvents/CalendarEventsEditScreen";
import CalendarEventsSuggestedEditScreen from "../pages/CalendarEvents/CalendarEventsSuggestedEditScreen";
import AcademicYearsListScreen from "../pages/AcademicYears/AcademicYearsListScreen";
import AcademicYearsEditScreen from "../pages/AcademicYears/AcademicYearsEditScreen";
import Students from "../pages/Students/Students";
import StudentsProfile from "../pages/Students/StudentsProfile";
import Studios from "../pages/Studies/Studios";
import StudiosForm from "../pages/Studies/StudiosForm";
import Users from "../pages/Users/Users";
import UsersEditScreen from "../pages/Users/UsersEditScreen";
import NotFound from "../pages/Other/NotFound";
import Groups from "../pages/Groups/Groups";
import GroupsAdd from "../pages/Groups/GroupsAdd";
import GroupsEdit from "../pages/Groups/GroupsEdit";
import Programs from "../pages/Programs/Programs";
import ProgramsForm from "../pages/Programs/ProgramsForm";
import Categories from "../pages/EventCategories/Categories";
import CategoriesCreate from "../pages/EventCategories/CategoriesCreate";
import {SemesterOrganizationScreen} from "../pages/CalendarEvents/SemesterOrganizationScreen";
import {LogIn} from "../pages/Auth/LogIn";

const ComponentRoutes = [
    {
        component: Students,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Students.List,
    },
    {
        component: StudentsProfile,
        title: 'Studentów',
        path: Routes.Students.Show(),
    },
    {
        component: Studios,
        title: 'Mapa studiów',
        path: Routes.Studies.List,
    },
    {
        component: StudiosForm,
        title: 'Mapa studiów',
        path: Routes.Studies.Edit(),
    },
    {
        component: Badges,
        title: 'Lista odznak',
        path: Routes.Badges.List,
    },
    {
        component: BadgesAdd,
        title: 'Odznaka',
        path: Routes.Badges.Create,
    },
    {
        component: BadgesEdit,
        title: 'Groupa przedmiotów',
        path: Routes.Badges.Edit(),
    },
    {
        component: Zdarzenia,
        title: 'Lista zdarzenia',
        path: Routes.Zdarzenia.List,
    },
    {
        component: ZdarzeniaForm,
        title: 'Zdarzenia',
        path: Routes.Zdarzenia.Create,
    },
    {
        component: ZdarzeniaForm,
        title: 'Zdarzenia',
        path: Routes.Zdarzenia.Edit(),
    },
    {
        component: Groups,
        title: 'Lista grup przedmiotów',
        path: Routes.Groups.List,
    },
    {
        component: GroupsAdd,
        title: 'Groupa przedmiotów',
        path: Routes.Groups.Create,
    },
    {
        component: GroupsEdit,
        title: 'Groupa przedmiotów',
        path: Routes.Groups.Edit(),
    },
    {
        component: CalendarEventsListScreen,
        title: 'Kalendarz akademicki',
        path: Routes.CalendarEvents.List,
    },
    {
        component: CalendarEventsSuggestedListScreen,
        title: 'Sugerowane wydarzenia',
        path: Routes.CalendarSuggestedEvents.List,
    },
    {
        component: CalendarEventsEditScreen,
        title: 'Kalendarz akademicki - informacje',
        path: Routes.CalendarEvents.Create,
    },
    {
        component: CalendarEventsSuggestedEditScreen,
        title: 'Sugerowane wydarzenia - Dodaj',
        path: Routes.CalendarSuggestedEvents.Create,
    },
    {
        component: CalendarEventsSuggestedEditScreen,
        title: 'Sugerowane wydarzenia - Edytuj',
        path: Routes.CalendarSuggestedEvents.Edit(),
    },
    {
        component: AcademicYearsListScreen,
        title: 'Kalendarz akademicki - Zarządzaj Rokami Akademickimi',
        path: Routes.AcademicYears.List
    },
    {
        component: AcademicYearsEditScreen,
        title: 'Kalendarz akademicki - Zarządzaj Rokami Akademickimi - Dodaj nowy rok akademicki',
        path: Routes.AcademicYears.Create
    },
    {
        component: AcademicYearsEditScreen,
        title: 'Kalendarz akademicki - Zarządzaj Rokami Akademickimi - Edituj rok akademicki',
        path: Routes.AcademicYears.Edit()
    },
    {
        component: SemesterOrganizationScreen,
        title: 'Organizacja semestru',
        path: Routes.CalendarEvents.SemesterOrganization,
    },
    {
        component: CalendarEventsEditScreen,
        title: 'Zdarzenie - informacje',
        path: Routes.CalendarEvents.Edit(),
    },
    {
        component: Categories,
        title: 'Kategorie wydarzeń',
        path: Routes.CalendarEventCategories.List,
    },
    {
        component: CategoriesCreate,
        title: 'Edytuj/Dodaj kategorie wydarzeń',
        path: Routes.CalendarEventCategories.Create,
    },
    {
        component: CategoriesCreate,
        title: 'Edytuj/Dodaj kategorie wydarzeń',
        path: Routes.CalendarEventCategories.Edit(),
    },
    {
        component: MarkersListScreen,
        title: 'Markery',
        path: Routes.Markers.List,
    },
    {
        component: MarkersEditScreen,
        title: 'Markery',
        path: Routes.Markers.Create,
    },
    {
        component: MarkersEditScreen,
        title: 'Markery',
        path: Routes.Markers.Edit(),
    },
    {
        component: MarkerCategoriesListScreen,
        title: 'Kategorie markerów',
        path: Routes.MarkerCategories.List,
    },
    {
        component: MarkerCategoriesEditScreen,
        title: 'Edytuj/Dodaj kategorie punktów',
        path: Routes.MarkerCategories.Create,
    },
    {
        component: MarkerCategoriesEditScreen,
        title: 'Edytuj/Dodaj kategorie punktów',
        path: Routes.MarkerCategories.Edit(),
    },
    {
        component: Users,
        title: 'Uźytkownicy systemu',
        path: Routes.Users.List,
    },
    {
        component: UsersEditScreen,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Users.Create,
    },
    {
        component: UsersEditScreen,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Users.Edit(),
    },
    {
        component: Programs,
        title: 'Programy studiów',
        path: Routes.Programs.List,
    },
    {
        component: ProgramsForm,
        title: 'Programs studiów',
        path: Routes.Programs.Create,
    },
    {
        component: ProgramsForm,
        title: 'Programs studiów',
        path: Routes.Programs.Edit(),
    },
];

const MinimalLayoutRoutes = [
    {
        component: LogIn,
        title: 'Zaloguj się',
        path: Routes.Auth.login,
    },
];

const MainLayout = () => {
    return (
        <Switch>
            <Main>
                {ComponentRoutes.map((route, index) => (
                    <Route key={index} exact {...route}/>
                ))}
            </Main>
        </Switch>
    );
};

const MinimalLayout = () => {
    return (
        <Switch>
            <Minimal>
                {MinimalLayoutRoutes.map((route, index) => (
                    <Route key={index} exact {...route}/>
                ))}
            </Minimal>
        </Switch>
    );
};

export default function RoutesRouter() {
    const {removeAllToasts} = useToasts();

    useEffect(() => {
        removeAllToasts();
    }, [removeAllToasts]);

    return (
        <Switch>
            <Redirect exact from="/" to={Routes.Auth.login}/>

            <Route path={MinimalLayoutRoutes.map(route => route.path)} component={MinimalLayout}/>
            <Route path={ComponentRoutes.map(route => route.path)} component={MainLayout}/>

            <Route path={'*'} component={NotFound}/>
        </Switch>
    );
}
