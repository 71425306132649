import Repository from "./Repository";
import Routes from "../router/Routes";
import moment from "moment";
import Validator, {AfterOrEqual, BeforeOrEqual, Required, RequiredIf, ValidISOString} from "../utils/Validator";
import {ReminderOptions} from "../pages/CalendarEvents/CalendarEventsEditScreen";
import Messages from "../utils/Messages";

const handleEventDates = (state, start_date, end_date) => {
    if (start_date?.isValid() && !end_date?.isValid()) {
        end_date.date(start_date.date());
        end_date.month(start_date.month());
        end_date.year(start_date.year());
    }
    return {
        ...state,
        start_date: start_date?.isValid() ? start_date.toISOString() : start_date._i,
        end_date: end_date?.isValid() ? end_date.toISOString() : end_date._i,
    };
};

export default new class CalendarEventsRepository extends Repository {
    Path = 'calendar_events';
    Routes = Routes.CalendarEvents;
    Validators = {
        title: new Validator(Required),
        description: new Validator(Required),
        start_date: new Validator(Required, ValidISOString(), BeforeOrEqual('end_date', Messages.BeforeEndDate)),
        end_date: new Validator(RequiredIf('is_full_day', false, undefined, ''), ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date', Messages.AfterStartDate)),
        category_id: new Validator(Required),
        reminder_option: new Validator(RequiredIf('reminder_enabled', true)),
        custom_reminder: new Validator(RequiredIf({
            reminder_enabled: true,
            reminder_option: 'custom',
        }), ValidISOString(), BeforeOrEqual('start_date', Messages.BeforeStartDate)),
    };
    OnChangeCustomizers = {
        start_date: (e, state) => handleEventDates(state, moment(e.target.value), moment(state.end_date)),
        end_date: (e, state) => handleEventDates(state, moment(state.start_date), moment(e.target.value)),
    };

    defaultValues = () => {
        return {
            title: {
                pl: '',
                en: '',
            },
            description: {
                pl: '',
                en: '',
            },
            reminder_enabled: false,
            reminder_option: '',
            marker_id: '',
            category_id: '',
            show_location_link: true,
            start_date: null,
            end_date: null,
        };
    };
    statePreparer = data => {
        const reminderOption = ReminderOptions.find(option => option.id === String(data.reminder_offset))?.id || null;
        data.reminder_enabled = !!data.reminder_offset;
        data.reminder_option = data.reminder_offset ? (reminderOption || 'custom') : '';
        data.custom_reminder = data.reminder_offset && !reminderOption
            ? moment(data.start_date).subtract(data.reminder_offset, 'seconds').toISOString()
            : null;
        return data;
    };
    savePreparer = state => {
        // prepare dates if is full day event
        if (state.is_full_day) {
            const start_date = moment(state.start_date);
            const end_date = start_date.clone();

            start_date.hours(0);
            start_date.minutes(0);
            start_date.seconds(0);
            start_date.milliseconds(0);

            end_date.hours(23);
            end_date.minutes(59);
            end_date.seconds(59);
            end_date.milliseconds(999);

            state.start_date = start_date.toISOString();
            state.end_date = end_date.toISOString();
        }

        // prepare reminder offset
        if (!state.reminder_enabled) {
            state.reminder_offset = null;
        } else {
            if (!isNaN(state.reminder_option)) {
                // one of select options
                state.reminder_offset = state.reminder_option;
            } else {
                // custom reminder
                state.reminder_offset = moment(state.start_date).diff(moment(state.custom_reminder), 'seconds');
            }
        }

        return state;
    };
}
