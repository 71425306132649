import React, {useContext} from 'react';
import {Card, Container, makeStyles, TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import API from "../../apis/API";
import {useHistory} from "react-router-dom";
import Routes from "../../router/Routes";
import {useToasts} from "react-toast-notifications";
import SiteInfoContext from "../../utils/SiteInfoContext";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        width: '80%',
        marginBottom: theme.spacing(2)
    },
}));

export const LogIn = () => {
    const classes = useStyles();
    const history = useHistory();
    const siteInfo = useContext(SiteInfoContext);
    const {addToast, removeAllToasts} = useToasts();
    const [userData, setUserData] = React.useState({
        email: '',
        password: '',
    });

    const logIn = () => {
        if (!userData.email.length && !userData.password.length) {
            addToast(<label>Proszę wypełnić wszystkie wymagane pola.</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false });
            return;
        }

        API.auth.login(userData).then(res => {
            if (res.data.code === 401) {
                addToast(<label>Wprowadzono nieprawidłowe dane .</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false });
                return
            }

            removeAllToasts();
            siteInfo.login(res.data);
            history.push(Routes.Students.List);
        }).catch(err => {
            addToast(<label>Wprowadzono nieprawidłowe dane .</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false });
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Card className={classes.paper}>
                <img src="/images/logos/logo umk.png" className={classes.logo}/>
                <Typography component="h1" variant="h5">
                    Zaloguj się do panelu administracyjnego
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => setUserData({
                            ...userData,
                            email: e.target.value
                        })}
                        value={userData.email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={userData.password}
                        onChange={e => setUserData({
                            ...userData,
                            password: e.target.value
                        })}
                        autoComplete="current-password"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => logIn()}
                        className={classes.submit}
                    >
                        Zaloguj się
                    </Button>
                </form>
            </Card>
        </Container>
    )
}
