import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { TableCell, TableRow } from '@material-ui/core';
import API from "../../apis/API";
import Routes from "../../router/Routes";
import FillButton from "../../components/Buttons/FillButton";
import PaginatedTable from "../../components/Table/PaginatedTable";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%'
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0,)
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em'
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagenation_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    }
}));

const Badges = props => {
    const history = useHistory();
    const classes = useStyles();
    const { addToast } = useToasts();

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa odznaki',
            name: 'name_pl'
        },
        {
            title: 'Typ',
            name: 'badge_type'
        },
        {
            title: 'Punkty w rankingu',
            name: 'points'
        },
        {
            title: 'Liczba studentów',
            name: 'amount',
        },
        {
            title: 'Czy promowana',
            name: 'promoted',
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nową odznakę',
            onClick: () => history.push(Routes.Badges.Create)
        },
        {
            component: FillButton,
            title: 'Odśwież odznaki',
            onClick: () => handleClick()
        }
    ];

    const handleClick = () => {
        API.badges.scoring().then(response => {
            addToast(<label>{response}</label>, { appearance: 'success', autoDismissTimeout: 1000, autoDismiss: true });
        });
    }

    const handleEditItem = id => history.push(Routes.Badges.Edit(id));

    const renderRow = (item, index) => (
        <TableRow key={index} className={classes.root} onClick={() => handleEditItem(item.id)}>
            <TableCell>{item.id}</TableCell>
            <TableCell className={classes.highlight}>{item?.name?.pl}</TableCell>
            <TableCell>{item.badge_type}</TableCell>
            <TableCell>{item.points}</TableCell>
            <TableCell>{item.amount}</TableCell>
            <TableCell>{item.promoted === 0 ? 'TAK' : 'NIE'}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            endpoint={API.badges.byOption}
            renderRow={renderRow}
            columns={columns}
        />
    );
};

export default Badges;
