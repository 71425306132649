import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";

import API from "../../apis/API";
import Routes from "../../router/Routes";
import SiteInfoContext from "../../utils/SiteInfoContext";
import { formatDateTime } from "../../utils/formatters";
import FillButton from "../../components/Buttons/FillButton";
import PaginatedTable from "../../components/Table/PaginatedTable";

const useStyles = makeStyles((theme) => ({
    categoryColor: {
        height: '30px',
        width: '30px',
        borderRadius: '30px',
    }
}));

export default function MarkerCategoriesListScreen() {
    const classes = useStyles();
    const history = useHistory();
    const siteInfo = useContext(SiteInfoContext);

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa kategorii',
            name: 'title',
        },
        {
            title: 'Kolor',
            name: 'color',
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nową kategorię',
            onClick: () => history.push(Routes.MarkerCategories.Create),
        },
    ];

    useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

    const renderRow = (item, index) => (
        <TableRow key={index} onClick={() => history.push(Routes.MarkerCategories.Edit(item.id))}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.title.pl}</TableCell>
            <TableCell><div className={classes.categoryColor} style={{ backgroundColor: item.color }} /></TableCell>
            <TableCell>{formatDateTime(item.updated_at)}</TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            endpoint={API.MarkerCategories.index}
            columns={columns}
            renderRow={renderRow}
        />
    );
};
