import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.8750em'
    },
    text_button: {
        color: theme.palette.green_yellow,
        cursor: 'pointer'
    },
    searchField: {
        width: '300px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px',
            border: theme.palette.card_border,
            backgroundColor: theme.palette.black_white
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px'
        }
    }
}));

const SearchInput = ({ handleSearch }) => {
    const classes = useStyles();
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!value) handleSearch(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleKeyDown = e => {
        if (e.keyCode === 13) handleSearch(value);
    }

    return (
        <div className={classes.rowsBlock}>
            <div>Szukaj:</div>
            <TextField
                className={classes.searchField}
                type="search"
                variant="outlined"
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <div className={classes.text_button} onClick={() => handleSearch(value)}>Szukaj</div>
        </div>
    );
};

SearchInput.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object
};

export default SearchInput;
