export const fieldsOfStudy = [
    { faculty: 1800000000, name: 'pielęgniarstwo', study: '18510000-PRZ' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18510000-PRZ' },
    { faculty: 2400000019, name: 'międzyobszarowe indywidualne studia humanistyczno-społeczne', study: '24510200-KRK' },
    { faculty: 1800000000, name: 'dietetyka', study: '18510000-PRZ' },
    { faculty: 1600000000, name: 'lekarski', study: '16510000-PRZ' },
    { faculty: 1700000000, name: 'farmacja', study: '17510000-PRZ' },
    { faculty: 1400000000, name: 'sztuka mediów i edukacja wizualna', study: '14510201-KRK' },
    { faculty: 2400000019, name: 'medioznawstwo', study: '24510201-KRK' },
    { faculty: 2700000019, name: 'bezpieczeństwo narodowe', study: '27510201-KRK' },
    { faculty: 1100000000, name: 'studia menedżersko-finansowe', study: '11520201-KRK' },
    { faculty: 2500000019, name: 'filologia klasyczna i studia śródziemnomorskie', study: '25510201-KRK' },
    { faculty: 2800000019, name: 'gospodarka przestrzenna i geozarządzanie', study: '28510301-KRK' },
    { faculty: 1000000000, name: 'matematyka stosowana', study: '10510201-KRK' },
    { faculty: 2600000019, name: 'biologia', study: '26510301-KRK' },
    { faculty: 2800000019, name: 'gospodarka przestrzenna i geozarządzanie', study: '28510301-3S-KRK' },
    { faculty: 2600000019, name: 'biologia', study: '26510201-KRK' },
    { faculty: 2800000019, name: 'gospodarka przestrzenna', study: '28510201-KRK' },
    { faculty: 1300000000, name: 'doradztwo podatkowe', study: '13510301-KRK' },
    { faculty: 1800000000, name: 'terapia zajęciowa', study: '18510201-KRK' },
    { faculty: 1300000000, name: 'doradztwo podatkowe', study: '13520301-KRK' },
    { faculty: 1400000000, name: 'sztuka mediów i edukacja wizualna', study: '14510101-KRK' },
    { faculty: 1600000000, name: 'optyka okularowa z elementami optometrii', study: '16510201-KRK' },
    { faculty: 1400000000, name: 'architektura wnętrz', study: '14510202-KRK' },
    { faculty: 1100000000, name: 'komunikacja i psychologia w biznesie', study: '11510202-OG-KRK' },
    { faculty: 1600000000, name: 'optometria', study: '16510302-KRK' },
    { faculty: 1300000000, name: 'prawo ochrony środowiska', study: '13510302-KRK' },
    { faculty: 2500000019, name: 'lingwistyka praktyczna i copywriting', study: '25510202-KRK' },
    { faculty: 1100000000, name: 'komunikacja i psychologia w biznesie', study: '11520202-PR-KRK' },
    { faculty: 1100000000, name: 'komunikacja i psychologia w biznesie', study: '11520202-OG-KRK' },
    { faculty: 1100000000, name: 'komunikacja i psychologia w biznesie', study: '11510202-PR-KRK' },
    { faculty: 1000000000, name: 'analiza danych', study: '10510302-3S-KRK' },
    { faculty: 2600000019, name: 'weterynaria', study: '26510102-KRK' },
    { faculty: 1400000000, name: 'architektura wnętrz', study: '14510102-KRK' },
    { faculty: 2800000019, name: 'geografia', study: '28510202-KRK' },
    { faculty: 2800000019, name: 'geografia', study: '28510302-KRK' },
    { faculty: 2400000019, name: 'psychologia', study: '24510102-KRK' },
    { faculty:  600000000, name: 'chemia i technologia żywności', study: '06510202-KRK' },
    { faculty: 1600000000, name: 'inżynieria biomedyczna', study: '16510202-KRK' },
    { faculty: 1100000000, name: 'logistyka', study: '11510203-KRK' },
    { faculty: 1600000000, name: 'inżynieria biomedyczna (studia wspólne)', study: '16510203-KRK' },
    { faculty: 2600000019, name: 'ochrona środowiska', study: '26510303-KRK' },
    { faculty: 2600000019, name: 'ochrona środowiska', study: '26510203-KRK' },
    { faculty: 1100000000, name: 'logistyka', study: '11520203-KRK' },
    { faculty: 1600000000, name: 'biotechnologia medyczna', study: '16510304-KRK' },
    { faculty: 1600000000, name: 'biotechnologia medyczna', study: '16510204-KRK' },
    { faculty: 2600000019, name: 'biotechnologia', study: '26510304-KRK' },
    { faculty: 2400000019, name: 'pedagogika przedszkolna i wczesnoszkolna', study: '24510104-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana: język rosyjski z językiem czeskim', study: '25510204-KRK' },
    { faculty: 2600000019, name: 'biotechnologia', study: '26510204-KRK' },
    { faculty: 2500000019, name: 'filologia polska jako obca', study: '25510205-KRK' },
    { faculty:  600000000, name: 'chemia', study: '06510305-3S-KRK' },
    { faculty:  600000000, name: 'chemia', study: '06510305-KRK' },
    { faculty: 2400000019, name: 'architektura informacji', study: '24510305-KRK' },
    { faculty:  600000000, name: 'chemia', study: '06550305-KRK' },
    { faculty:  600000000, name: 'chemia', study: '06510205-KRK' },
    { faculty: 2400000019, name: 'architektura informacji', study: '24510205-KRK' },
    { faculty:  600000000, name: 'chemia', study: '06550205-KRK' },
    { faculty: 2500000019, name: 'filologia polska', study: '25510206-KRK' },
    { faculty:  600000000, name: 'chemia medyczna', study: '06510206-KRK' },
    { faculty: 2500000019, name: 'filologia polska', study: '25510306-KRK' },
    { faculty: 2400000019, name: 'pedagogika medialna', study: '24510206-KRK' },
    { faculty:  600000000, name: 'chemia medyczna', study: '06510306-KRK' },
    { faculty: 2600000019, name: 'biologia sądowa', study: '26510206-KRK' },
    { faculty:  600000000, name: 'chemia kryminalistyczna', study: '06510307-KRK' },
    { faculty: 2500000019, name: 'filologia angielska', study: '25510207-KRK' },
    { faculty: 2500000019, name: 'filologia angielska', study: '25520207-KRK' },
    { faculty: 2600000019, name: 'sport i wellness', study: '26510207-KRK' },
    { faculty: 2400000019, name: 'wychowanie fizyczne i sport', study: '24510308-KRK' },
    { faculty:  800000000, name: 'astronomia', study: '08510208-KRK' },
    { faculty: 2600000019, name: 'diagnostyka molekularna', study: '26510308-KRK' },
    { faculty: 2500000019, name: 'logopedia', study: '25510208-KRK' },
    { faculty:  800000000, name: 'astronomia', study: '08510308-KRK' },
    { faculty:  800000000, name: 'fizyka', study: '08510209-KRK' },
    { faculty:  800000000, name: 'fizyka', study: '08510309-KRK' },
    { faculty: 2500000019, name: 'filologia angielska', study: '25510210-KRK' },
    { faculty:  800000000, name: 'fizyka techniczna', study: '08510210-KRK' },
    { faculty: 2500000019, name: 'filologia angielska', study: '25510310-KRK' },
    { faculty: 2500000019, name: 'filologia angielska', study: '25520210-KRK' },
    { faculty: 2500000019, name: 'filologia angielska', study: '25520310-KRK' },
    { faculty:  800000000, name: 'fizyka techniczna', study: '08510310-3S-KRK' },
    { faculty:  800000000, name: 'fizyka techniczna', study: '08510310-KRK' },
    { faculty: 2400000019, name: 'filozofia', study: '24510311-KRK' },
    { faculty: 2400000019, name: 'filozofia', study: '24510211-KRK' },
    { faculty: 2500000019, name: 'filologia bałkańska', study: '25510311-KRK' },
    { faculty: 2500000019, name: 'filologia bałkańska', study: '25510211-KRK' },
    { faculty: 2500000019, name: 'filologia germańska', study: '25510312-KRK' },
    { faculty: 2400000019, name: 'pedagogika', study: '24510212-KRK' },
    { faculty: 2500000019, name: 'filologia germańska', study: '25510212-KRK' },
    { faculty: 2400000019, name: 'pedagogika', study: '24520312-KRK' },
    { faculty: 2400000019, name: 'pedagogika', study: '24510312-KRK' },
    { faculty: 2400000019, name: 'pedagogika', study: '24520212-KRK' },
    { faculty: 2500000019, name: 'filologia romańska', study: '25510213-KRK' },
    { faculty: 2700000019, name: 'politologia', study: '27510313-KRK' },
    { faculty: 2500000019, name: 'filologia romańska', study: '25510313-KRK' },
    { faculty: 2700000019, name: 'politologia', study: '27510213-KRK' },
    { faculty: 2500000019, name: 'filologia rosyjska', study: '25510214-KRK' },
    { faculty: 2400000019, name: 'socjologia', study: '24510314-KRK' },
    { faculty: 2500000019, name: 'filologia rosyjska', study: '25510314-KRK' },
    { faculty: 2400000019, name: 'socjologia', study: '24510214-KRK' },
    { faculty: 2500000019, name: 'filologia włoska', study: '25510215-KRK' },
    { faculty: 1000000000, name: 'matematyka', study: '10510215-KRK' },
    { faculty: 1000000000, name: 'matematyka', study: '10510315-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10520316-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10510216-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10510316-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10520216-KRK' },
    { faculty: 2500000019, name: 'japonistyka', study: '25510316-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10510216-INZ-KRK' },
    { faculty: 2500000019, name: 'japonistyka', study: '25510216-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10520216-INZ-KRK' },
    { faculty: 1000000000, name: 'informatyka', study: '10510316-3S-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana', study: '25510217-F-ACH-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana', study: '25510217-R-ACH-KRK' },
    { faculty: 1100000000, name: 'ekonomia', study: '11510217-KRK' },
    { faculty: 1100000000, name: 'ekonomia', study: '11510317-KRK' },
    { faculty: 1100000000, name: 'ekonomia', study: '11550317-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana', study: '25510217-W-ACH-KRK' },
    { faculty: 1100000000, name: 'finanse i rachunkowość', study: '11510318-KRK' },
    { faculty: 1100000000, name: 'finanse i rachunkowość', study: '11520318-KRK' },
    { faculty: 1100000000, name: 'finanse i rachunkowość', study: '11510218-KRK' },
    { faculty: 1100000000, name: 'finanse i rachunkowość', study: '11520218-KRK' },
    { faculty: 1100000000, name: 'zarządzanie', study: '11550319-KRK' },
    { faculty: 1100000000, name: 'zarządzanie', study: '11510319-KRK' },
    { faculty: 1100000000, name: 'zarządzanie', study: '11510219-KRK' },
    { faculty: 1100000000, name: 'zarządzanie', study: '11520319-KRK' },
    { faculty: 1100000000, name: 'zarządzanie', study: '11550219-KRK' },
    { faculty: 1100000000, name: 'zarządzanie', study: '11520219-KRK' },
    { faculty: 1200000000, name: 'archeologia', study: '12510220-KRK' },
    { faculty: 1200000000, name: 'archeologia', study: '12510320-KRK' },
    { faculty: 1200000000, name: 'historia', study: '12510221-KRK' },
    { faculty: 1200000000, name: 'historia', study: '12510321-KRK' },
    { faculty: 1400000000, name: 'historia sztuki', study: '14510222-KRK' },
    { faculty: 1400000000, name: 'historia sztuki', study: '14510322-KRK' },
    { faculty: 2700000019, name: 'stosunki międzynarodowe', study: '27510323-KRK' },
    { faculty: 2700000019, name: 'stosunki międzynarodowe', study: '27510223-KRK' },
    { faculty: 2400000019, name: 'zarządzanie informacją i bibliologia', study: '24510324-KRK' },
    { faculty: 2500000019, name: 'etnologia - antropologia kulturowa', study: '25510225-KRK' },
    { faculty: 2500000019, name: 'etnologia - antropologia kulturowa', study: '25510325-KRK' },
    { faculty: 1300000000, name: 'prawo', study: '13510126-KRK' },
    { faculty: 1300000000, name: 'prawo', study: '13520126-KRK' },
    { faculty: 1300000000, name: 'administracja', study: '13510227-KRK' },
    { faculty: 1300000000, name: 'administracja', study: '13520327-KRK' },
    { faculty: 1300000000, name: 'administracja', study: '13510327-KRK' },
    { faculty: 1300000000, name: 'administracja', study: '13520227-KRK' },
    { faculty: 1400000000, name: 'malarstwo', study: '14520229' },
    { faculty: 1400000000, name: 'malarstwo', study: '14510129-KRK' },
    { faculty: 1400000000, name: 'grafika', study: '14510130-KRK' },
    { faculty: 1400000000, name: 'rzeźba', study: '14510131-KRK' },
    { faculty: 1400000000, name: 'edukacja artystyczna w zakresie sztuk plastycznych', study: '14510332-KRK' },
    { faculty: 1400000000, name: 'konserwacja i restauracja dzieł sztuki', study: '14510133-KRK' },
    { faculty: 1400000000, name: 'ochrona dóbr kultury', study: '14510334-KRK' },
    { faculty: 1400000000, name: 'ochrona dóbr kultury', study: '14520334-KRK' },
    { faculty: 1400000000, name: 'ochrona dóbr kultury', study: '14510234-KRK' },
    { faculty: 1500000000, name: 'teologia', study: '15510135-KRK' },
    { faculty: 1500000000, name: 'teologia', study: '15510135-KA-KRK' },
    { faculty: 2500000019, name: 'filologia germańska', study: '25510236-KRK' },
    { faculty: 2500000019, name: 'filologia romańska', study: '25510238-KRK' },
    { faculty: 2500000019, name: 'filologia rosyjska', study: '25510239-KRK' },
    { faculty: 2500000019, name: 'filologia bałkańska', study: '25510240-KRK' },
    { faculty: 2500000019, name: 'filologia włoska', study: '25510242-KRK' },
    { faculty: 1600000000, name: 'lekarski', study: '16550147-PR-KRK' },
    { faculty: 1600000000, name: 'lekarski', study: '16550147-KRK' },
    { faculty: 1600000000, name: 'lekarski', study: '16540147-PR-KRK' },
    { faculty: 1600000000, name: 'lekarski', study: '16510147-KRK' },
    { faculty: 1600000000, name: 'lekarski', study: '16510147-PR-KRK' },
    { faculty: 1600000000, name: 'lekarski', study: '16540147-KRK' },
    { faculty: 1600000000, name: 'biotechnologia', study: '16510248-KRK' },
    { faculty: 1600000000, name: 'biotechnologia', study: '16510348-KRK' },
    { faculty: 1700000000, name: 'analityka medyczna', study: '17510149-OG-KRK' },
    { faculty: 1700000000, name: 'analityka medyczna', study: '17510149-PR-KRK' },
    { faculty: 1700000000, name: 'farmacja', study: '17540150-KRK' },
    { faculty: 1700000000, name: 'farmacja', study: '17510150-KRK' },
    { faculty: 1700000000, name: 'farmacja', study: '17550150-KRK' },
    { faculty: 1800000000, name: 'pielęgniarstwo', study: '18520351-KRK' },
    { faculty: 1800000000, name: 'pielęgniarstwo', study: '18550251-KRK' },
    { faculty: 1800000000, name: 'pielęgniarstwo', study: '18510351-KRK' },
    { faculty: 1800000000, name: 'pielęgniarstwo', study: '18510251-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18520252-OG-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18540252-OG-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18540352-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18510152-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18540152-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18520152-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18510352-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18520352-KRK' },
    { faculty: 1800000000, name: 'fizjoterapia', study: '18550152-KRK' },
    { faculty: 1800000000, name: 'zdrowie publiczne', study: '18510353-KRK' },
    { faculty:  800000000, name: 'automatyka i robotyka', study: '08510354-3S-KRK' },
    { faculty:  800000000, name: 'automatyka i robotyka', study: '08510254-KRK' },
    { faculty: 2500000019, name: 'kulturoznawstwo', study: '25510355-KRK' },
    { faculty: 2500000019, name: 'kulturoznawstwo', study: '25510255-KRK' },
    { faculty: 1700000000, name: 'kosmetologia', study: '17520256-KRK' },
    { faculty: 1700000000, name: 'kosmetologia', study: '17510356-OG-KRK' },
    { faculty: 1700000000, name: 'kosmetologia', study: '17510256-KRK' },
    { faculty: 1700000000, name: 'kosmetologia', study: '17540256-KRK' },
    { faculty: 1200000000, name: 'archiwistyka i zarządzanie dokumentacją', study: '12510357-KRK' },
    { faculty: 1200000000, name: 'archiwistyka i zarządzanie dokumentacją', study: '12510257-KRK' },
    { faculty: 2800000019, name: 'turystyka i rekreacja', study: '28510258-KRK' },
    { faculty: 2800000019, name: 'turystyka i rekreacja', study: '28510358-KRK' },
    { faculty: 2400000019, name: 'pedagogika specjalna', study: '24510159-KRK' },
    { faculty: 2400000019, name: 'pedagogika specjalna', study: '24510359-KRK' },
    { faculty: 2400000019, name: 'pedagogika specjalna', study: '24510259-KRK' },
    { faculty: 1800000000, name: 'dietetyka', study: '18520261-KRK' },
    { faculty: 1800000000, name: 'dietetyka', study: '18510261-KRK' },
    { faculty: 1800000000, name: 'dietetyka', study: '18510361-KRK' },
    { faculty: 1800000000, name: 'ratownictwo medyczne', study: '18510262-KRK' },
    { faculty: 1800000000, name: 'ratownictwo medyczne', study: '18520262-KRK' },
    { faculty: 1800000000, name: 'położnictwo', study: '18520364-KRK' },
    { faculty: 1800000000, name: 'położnictwo', study: '18510264-KRK' },
    { faculty: 1800000000, name: 'położnictwo', study: '18510364-KRK' },
    { faculty: 2500000019, name: 'japonistyka', study: '25510266-KRK' },
    { faculty: 2400000019, name: 'dziennikarstwo i komunikacja społeczna', study: '24510267-KRK' },
    { faculty: 2400000019, name: 'dziennikarstwo i komunikacja społeczna', study: '24510367-KRK' },
    { faculty:  800000000, name: 'informatyka stosowana', study: '08510269-KRK' },
    { faculty:  800000000, name: 'informatyka stosowana', study: '08510369-3S-KRK' },
    { faculty: 1000000000, name: 'matematyka i ekonomia', study: '10510270-KRK' },
    { faculty: 2400000019, name: 'kognitywistyka', study: '24510371-KRK' },
    { faculty: 2400000019, name: 'kognitywistyka', study: '24510271-KRK' },
    { faculty: 2400000019, name: 'praca socjalna', study: '24510373-KRK' },
    { faculty: 2400000019, name: 'praca socjalna', study: '24510273-KRK' },
    { faculty: 1500000000, name: 'nauki o rodzinie', study: '15510276-KRK' },
    { faculty: 1500000000, name: 'nauki o rodzinie', study: '15510376-KRK' },
    { faculty: 2700000019, name: 'bezpieczeństwo wewnętrzne', study: '27520377-KRK' },
    { faculty: 2700000019, name: 'bezpieczeństwo wewnętrzne', study: '27510277-KRK' },
    { faculty: 2700000019, name: 'bezpieczeństwo wewnętrzne', study: '27520277-KRK' },
    { faculty: 2700000019, name: 'bezpieczeństwo wewnętrzne', study: '27510377-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana: język francuski z językiem arabskim', study: '25510278-KRK' },
    { faculty: 1200000000, name: 'wojskoznawstwo', study: '12510282-KRK' },
    { faculty: 1200000000, name: 'wojskoznawstwo', study: '12510382-KRK' },
    { faculty: 1400000000, name: 'krytyka artystyczna', study: '14510284-KRK' },
    { faculty:  600000000, name: 'chemia kosmetyczna', study: '06510285-KRK' },
    { faculty:  600000000, name: 'chemia kosmetyczna', study: '06510385-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana: język włoski z językiem hiszpańskim', study: '25510287-KRK' },
    { faculty: 1800000000, name: 'elektroradiologia', study: '18520289-OG-KRK' },
    { faculty: 1800000000, name: 'elektroradiologia', study: '18510289-OG-KRK' },
    { faculty: 1800000000, name: 'audiofonologia', study: '18520290-KRK' },
    { faculty: 1800000000, name: 'audiofonologia', study: '18510290-KRK' },
    { faculty: 2500000019, name: 'komparatystyka literacko-kulturowa', study: '25510291-KRK' },
    { faculty: 2800000019, name: 'geoinformacja środowiskowa', study: '28510392-KRK' },
    { faculty: 2500000019, name: 'lingwistyka stosowana: język francuski z językiem hiszpańskim', study: '25510294-KRK' },
    { faculty: 1200000000, name: 'studia skandynawsko-bałtyckie', study: '12510295-KRK' },
    { faculty: 1200000000, name: 'studia skandynawsko-bałtyckie', study: '12510395-KRK' },
]

export const faculties = [
    { value:  600000000, label: 'Chemii' },
    { value:  800000000, label: 'Fizyki, Astronomii i Informatyki Stosowanej' },
    { value: 1000000000, label: 'Matematyki i Informatyki' },
    { value: 1100000000, label: 'Nauk Ekonomicznych i Zarządzania' },
    { value: 1200000000, label: 'Nauk Historycznych' },
    { value: 1300000000, label: 'Prawa i Administracji' },
    { value: 1400000000, label: 'Sztuk Pięknych' },
    { value: 1500000000, label: 'Teologiczny' },
    { value: 1600000000, label: 'Lekarski (Collegium Medicum w Bydgoszczy)' },
    { value: 1700000000, label: 'Farmaceutyczny (Collegium Medicum w Bydgoszczy)' },
    { value: 1800000000, label: 'Nauk o Zdrowiu (Collegium Medicum w Bydgoszczy)' },
    // { value: 2100000000, label: 'Biologii i Ochrony Środowiska (2012-2019)' }, // Removed in UMK-155
    { value: 2400000019, label: 'Filozofii i Nauk Społecznych' },
    { value: 2500000019, label: 'Humanistyczny' },
    { value: 2600000019, label: 'Nauk Biologicznych i Weterynaryjnych' },
    { value: 2700000019, label: 'Nauk o Polityce i Bezpieczeństwie' },
    { value: 2800000019, label: 'Nauk o Ziemi i Gospodarki Przestrzennej' },
];

export const studyLevels = {
	first_cycle:  "Studia pierwszego stopnia",
	second_cycle: "Studia uzupełniające magisterskie",
	third_cycle:  "Studia doktoranckie",
	phd_school:   "Studia doktoranckie",
	long_cycle:   "Studia jednolite magisterskie",
};

export const studyTypes = {
	first_cycle:                "Studia pierwszego stopnia",
	second_cycle:               "Studia uzupełniające magisterskie",
	third_cycle_and_phd_school: "Studia doktoranckie",
	long_cycle:                 "Studia jednolite magisterskie",
	first_cycle_mish:           "MISH - Studia licencjackie",
};
