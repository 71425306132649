import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import LocaleButtons from "../Buttons/LocaleButtons";
import Input from "./PlainInputs/Input";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    input_box: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '10px 20px',
        width: '100%',
        fontSize: '0.8750em',
    },
    error: {
        border: '0.5px solid red',
    },
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
        fontFamily: 'roboto',
    },
}));

export default function FormInput({title, error, translatable, ...rest}) {
    const classes = useStyles();
    const [locale, setLocale] = React.useState('pl');
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4} className={classes.title}>
                    <label>{title}</label>
                    {translatable && <LocaleButtons iconsOnly value={locale} onChange={setLocale} errors={error}/>}
                </Grid>
                <Grid item xs={8}>
                    <Input
                        locale={translatable ? locale : undefined}
                        error={error}
                        {...rest}
                    />
                </Grid>
            </Grid>
        </>
    );
};
