import React from 'react';
import { Link } from "react-router-dom";

import API from 'apis/API';
import Routes from 'router/Routes';

import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PaginatedTable from 'components/Table/PaginatedTable';

const useStyles = makeStyles(theme => ({
    text_button: {
        color: theme.palette.green_yellow,
        cursor: 'pointer',
        textDecoration: 'underline'
    },
}));

const Studios = props => {
    const { history, title } = props;
    const classes = useStyles();

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa mapy',
            name: 'name',
            isOrderable: false,
        },
        {
            title: 'Akcje',
            name: 'action',
            isOrderable: false,
        },
    ];

    const renderRow = (item, index) => {
        return <TableRow key={item.id} onClick={() => history.push(Routes.Studies.Edit(item.type, item.mode))}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>
                <Link to={`/`} className={classes.text_button}>Zarządzanie terminami</Link>
            </TableCell>
        </TableRow>
    };

    return (
        <>
            <PaginatedTable
                title={title}
                endpoint={API.studiesMaps.byOption}
                columns={columns}
                renderRow={renderRow}
            />
        </>
    );
};

export default Studios;
