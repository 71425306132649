import React, { useState, useEffect } from 'react';
import {
	Grid, Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import API from "../../apis/API";
import Title from "../../components/Text/Title";
import LocaleButtons from 'components/Buttons/LocaleButtons';
import ValidatorInput from "../../components/Form/ValidatorInput";
import ThumbnailPicker from "../../components/Form/ThumbnailPicker";
import Conditions from "./Conditions";
import { makeStyles } from "@material-ui/styles";
import { faculties, fieldsOfStudy, studyLevels } from '../../apis/Dictionaries';

const useStyles = makeStyles((theme) => ({
	filled_button: {
		'& .MuiButton-label': {
			textTransform: 'none',
			fontSize: '0.8750em',
		},
		'&:hover': {
			backgroundColor: theme.palette.green_yellow
		},
		padding: theme.spacing(0.5),
		borderRadius: '0px',
		fontWeight: '300',
		backgroundColor: theme.palette.green_yellow,
		color: theme.palette.black_white,
		minWidth: 0
	},
	upload_card: {
		marginTop: theme.spacing(2),
	},
	badge_type: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '20px'
	},
	flag: {
		marginBottom: '0 !important',
		marginLeft: '10px'
	},
	flag_title: {
		marginBottom: 0
	},
	notes: {
		marginBlock: '10px'
	},
	title: {
		fontSize: '0.875em',
		fontWeight: '500',
	}
}));


const AddBadgeType = props => {
	const { handleChange, handleDelete, badge } = props;
	const classes = useStyles();
	const [data, setData] = useState({
		name_en: '',
		name_pl: '',
		description_en: '',
		description_pl: '',
		points: '',
		image: null,
		selected_conditions: [],
	});
	const [flag_name, setFlagName] = useState('pl');
	const [flag_notes, setFlagNotes] = useState('pl');
	const [error_name_en, setErrorNameEn] = useState(false);
	const [error_name_pl, setErrorNamePl] = useState(false);
	const [error_description_en, setErrorDescriptionEn] = useState(false);
	const [error_description_pl, setErrorDescriptionPl] = useState(false);
	const [error_points, setErrorPoints] = useState(false);
	const [conditions, setConditions] = useState([]);

	// Conditions list
	useEffect(() => {
		setConditions([
			{ id: 0, name: 'Wybierz warunek przyznania odznaki' },
			{ id: 1, name: 'Wydział', label: 'wydział', children: formatFaculties() },
			{ id: 2, name: 'Kierunek', label: 'kierunek', children: formatFieldsOfStudy() },
			{ id: 3, name: 'Typ studiów', label: 'Typ', children: formatStudyLevels() },
			{ id: 4, name: 'Średnia ocen z przedmiotów z grupy', label: 'grupa', children: [] },
			{ id: 5, name: 'liczba zaliczonych przedmiotów z grupy', label: 'grupa', children: [] },
			{ id: 6, name: 'przedmiot', label: 'kod przedmiotu', children: {} },
			{ id: 7, name: 'Ocena', label: 'kod przedmiotu', children: {} },
		]);

		API.groups.getAll().then(group => {
			setConditions(prev => {
				prev[4].children = group;
				prev[5].children = group;
				return prev;
			});
		});
	}, []);

	// Update data from parent
	useEffect(() => {
		setData(badge);
	}, [badge]);

	useEffect(() => {
		handleValidate();
		// if (checkValidate()) {
		// 	return;
		// }

		handleChange(data);
	}, [data]);

	const handleInputChange = (order, value, error) => {
		switch (order) {
			case 1:
				setErrorNameEn(error);
				setData(prev => { return { ...prev, name_en: value }; });
				break;
			case 2:
				setErrorDescriptionEn(error);
				setData(prev => { return { ...prev, description_en: value }; });
				break;
			case 3:
				setErrorNamePl(error);
				setData(prev => { return { ...prev, name_pl: value }; });
				break;
			case 4:
				setErrorDescriptionPl(error);
				setData(prev => { return { ...prev, description_pl: value }; });
				break;
			case 5:
				setErrorPoints(error);
				setData(prev => { return { ...prev, points: value }; });
				break;
			case 6:
				setData(prev => { return { ...prev, selected_conditions: value }; });
				break;
			case 7:
				setData(prev => { return { ...prev, image: value.target.value }; });
				break;
			default:
				return;
		}
	};

	const checkValidate = () => {
		return data.name_en.length === 0
			|| data.description_en.length === 0
			|| data.name_pl.length === 0
			|| data.description_pl.length === 0
			|| data.points.length === 0;
	};

	const handleValidate = () => {
		setErrorNameEn(data.name_en.length === 0);
		setErrorDescriptionEn(data.description_en.length === 0);
		setErrorNamePl(data.name_pl.length === 0);
		setErrorDescriptionPl(data.description_pl.length === 0);
		setErrorPoints(data.points.length === 0);
	};

	const formatFaculties = () => {
		return faculties.map(f => ({ id: f.value, name: f.label }));
	};

	const formatFieldsOfStudy = () => {
		return fieldsOfStudy.map(f => ({ ...f, id: f.study }));
	};

	const formatStudyLevels = () => {
		return Object.entries(studyLevels).map(s => ({ id: s[0], name: s[1] }));
	};

	return (
		<Grid container spacing={3}>
			<Grid item container xs={12}>
				<Grid item xs={7}>
				{flag_name === 'en' ? (
					<ValidatorInput
						type="text"
						title="Nazwa typu"
						value={data.name_en}
						handleChange={(value, error) => handleInputChange(1, value, error)}
						hasError={error_name_en}
						error_message="Proszę wypełnić to pole."
					/>
				) : (
					<ValidatorInput
						type="text"
						title="Nazwa typu"
						value={data.name_pl}
						handleChange={(value, error) => handleInputChange(3, value, error)}
						hasError={error_name_pl}
						error_message="Proszę wypełnić to pole."
					/>
				)}
				</Grid>
				<Grid item xs={4}>
					<LocaleButtons className={classes.flag} value={flag_name} onChange={setFlagName} />
				</Grid>
				<Grid item xs={1}>
					<Button variant="contained" color="secondary" className={classes.filled_button}
						onClick={handleDelete}>
						<DeleteIcon />
					</Button>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Conditions title="" value={data.selected_conditions} conditions={conditions} handleChange={value => handleInputChange(6, value)} />

				<Grid container alignItems="center" className={classes.notes}>
					<Title className={`${classes.flag_title} ${classes.title}`} value="Opis typu odznaki" />
					<LocaleButtons className={classes.flag} value={flag_notes} onChange={setFlagNotes} />
				</Grid>
				{flag_notes === 'en' ? (
					<ValidatorInput
						type="autosizetxt"
						value={data.description_en}
						handleChange={(value, error) => handleInputChange(2, value, error)}
						hasError={error_description_en}
						error_message="Proszę wypełnić to pole."
					/>
				) : (
					<ValidatorInput
						type="autosizetxt"
						value={data.description_pl}
						handleChange={(value, error) => handleInputChange(4, value, error)}
						hasError={error_description_pl}
						error_message="Proszę wypełnić to pole."
					/>
				)}
			</Grid>

			<Grid item container spacing={3}>
				<Grid item xs={6}>
					<Title className={classes.title} value="Obrazek typu odznaki" />
					<ThumbnailPicker value={data.image} onChange={(value) => handleInputChange(7, value)} editLabel="Edytuj obrazek" deleteLabel="Usuń obrazek" />
				</Grid>
				<Grid item xs={6}>
					<Title className={classes.title} value="Punkty w rankingu za typ odznaki" />
					<ValidatorInput
						title=""
						type="number"
						value={data.points}
						handleChange={(value, error) => handleInputChange(5, value, error)}
						hasError={error_points}
						error_message="Proszę wypełnić to pole."
					/>
				</Grid>
			</Grid>
		</Grid >
	);
};

export default AddBadgeType;
