import React from 'react';
import { Grid, Card, CircularProgress } from '@material-ui/core';
import { useHistory, useParams } from "react-router-dom";
import API from "../../apis/API";
import { makeStyles } from "@material-ui/styles";
import useApiResource from "../../hooks/useApiResource";
import Routes from "../../router/Routes";
import FormDropdown from "../../components/Form/FormDropdown";
import FormInput from "../../components/Form/FormInput";
import SaveButton from "../../components/Buttons/SaveButton";
import DeleteButton from "../../components/Buttons/DeleteButton";
import Html from "../../components/Form/Html";
import ThumbnailPicker from "../../components/Form/ThumbnailPicker";
import BackToMain from "../../components/Buttons/BackToMain";
import Title from "../../components/Text/Title";
import useEditData from "../../hooks/useEditData";
import MarkersRepository from "../../repositories/MarkersRepository";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(20)
    },
    upload_card: {
        marginTop: theme.spacing(2),
    },
    progressContainer: {
        position: 'absolute',
        top: '50%',
        width: 'calc(100% - 600px)',
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        color: theme.palette.pink,
    },
}));

export default function MarkersEditScreen() {
    const classes = useStyles();
    const [categories] = useApiResource(API.MarkerCategories.all);
    const history = useHistory();
    const { id } = useParams();
    const { data, errors, saving, onChange, save } = useEditData(MarkersRepository);

    if (!data || !categories) {
        return (
            <>
                <BackToMain title="Powrót do listy kategorie miejsc" to={Routes.Markers.List} />
                {data === null && <CircularProgress />}
            </>
        );
    }

    const handleDelete = () => {
        API.Markers.delete(id)
            .then(() => history.push(Routes.Markers.List));
    };

    return (
        <>
            <BackToMain title="Powrót do listy markerów" to={Routes.Markers.List} />
            <Title value={'Marker ' + data?.title?.pl} />
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card className={classes.card}>
                        <FormInput
                            title={'Nazwa punktu'}
                            name={'title'}
                            value={data.title}
                            onChange={onChange}
                            error={errors?.title}
                            translatable
                        />
                    </Card>
                    <Title value="Opis" />
                    <Card className={classes.card}>
                        <Html
                            name={'description'}
                            value={data.description}
                            onChange={onChange}
                            translatable
                        />
                    </Card>
                    <Title value="Zawartość" />
                    <Card className={classes.card}>
                        <Html
                            name={'content'}
                            value={data.content}
                            onChange={onChange}
                            translatable
                        />
                    </Card>
                    <Title value="Podstawowe informacje" />
                    <Card className={classes.card}>
                        <FormDropdown
                            title={'Kategoria'}
                            name={'category_id'}
                            value={data.category_id}
                            error={errors?.category_id}
                            onChange={onChange}
                            options={categories}
                        />
                        <FormInput
                            title={'Adres'}
                            name={'address'}
                            value={data.address}
                            error={errors?.address}
                            onChange={onChange}
                        />
                        <FormInput
                            title={'Szerokość geograficzna'}
                            name={'latitude'}
                            value={data.latitude}
                            error={errors?.latitude}
                            onChange={onChange}
                        />
                        <FormInput
                            title={'Długość geograficzna'}
                            name={'longitude'}
                            value={data.longitude}
                            error={errors?.longitude}
                            onChange={onChange}
                        />
                        <FormInput
                            title={'Numer telefonu'}
                            name={'phone'}
                            value={data.phone}
                            error={errors?.phone}
                            onChange={onChange}
                        />
                        <FormInput
                            title={'Drugi numer telefonu'}
                            name={'second_phone'}
                            value={data.second_phone}
                            error={errors?.second_phone}
                            onChange={onChange}
                        />
                        <FormInput
                            title={'Adres e-mail'}
                            type={'email'}
                            name={'email'}
                            value={data.email}
                            error={errors?.email}
                            onChange={onChange}
                        />
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DeleteButton
                                    title="Czy na pewno chcesz usunąć ten markera?"
                                    handleDelete={handleDelete} />
                            </Grid>
                            <Grid item xs={6}>
                                <SaveButton onClick={save} saving={saving} />
                            </Grid>
                        </Grid>
                    </Card>
                    <Card className={classes.upload_card}>
                        <ThumbnailPicker name={'image'} value={data.image} onChange={onChange} />
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
