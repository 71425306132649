import React from "react";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/styles";
import {extractError} from "../../utils/extractError";

const useStyles = makeStyles(theme => ({
    // todo - date picker styles
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
        fontFamily: 'roboto',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
}));


export default function DateTimeInput({name, value, onChange, translatable, error, className}) {
    const classes = useStyles();
    const err = extractError(error, translatable, 'pl');

    const change = momentObject => {
        if (!momentObject)
            return;

        onChange?.({
            target: {
                name,
                value: momentObject.isValid() ? momentObject.toISOString() : momentObject._i,
            },
        });
    };

    return (
        <div className={classes.column}>
            <KeyboardDateTimePicker
                invalidDateMessage={null}
                ampm={false}
                value={value}
                onChange={change}
                variant={'inline'}
                format={'DD/MM/YYYY HH:mm'}
                error={!!error}
                className={className}
            />
            <div className={classes.error_label} style={{display: err ? 'block' : 'none'}}>
                {err}
            </div>
        </div>
    );
};
