import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { extractError } from "../../utils/extractError";

const useStyles = makeStyles((theme) => ({
    // todo - date picker styles
    error_label: {
        color: "red",
        fontSize: "0.75em",
        marginTop: "5px",
        fontFamily: "roboto",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
}));

export default function DateInput({
    name,
    value,
    onChange,
    translatable,
    error,
    className,
}) {
    const classes = useStyles();
    const err = extractError(error, translatable, "pl");

    const change = (momentObject) => {
        if (!momentObject) return;

        // do not override previously set hours, minutes and seconds
        const valueMoment = moment(value);
        if (valueMoment.isValid()) {
            momentObject.hours(valueMoment.hours());
            momentObject.minutes(valueMoment.minutes());
            momentObject.seconds(valueMoment.seconds());
        }

        onChange?.({
            target: {
                name,
                // value: momentObject.isValid() ? momentObject.toISOString() : momentObject._i,
                value: momentObject.isValid()
                    ? momentObject.format("YYYY-MM-DD")
                    : momentObject._i,
            },
        });
    };

    return (
        <div className={classes.column}>
            <KeyboardDatePicker
                value={value === "0000-00-00" ? null : value}
                invalidDateMessage={null}
                onChange={change}
                variant={"inline"}
                format={"DD/MM/YYYY"}
                error={!!error}
                className={className}
            />
            <div
                className={classes.error_label}
                style={{ display: err ? "block" : "none" }}
            >
                {err}
            </div>
        </div>
    );
}
