import React, {useState} from "react";
import Storage from "./Storage";

const SiteInfoContext = React.createContext(null);

export default SiteInfoContext;

export function SiteInfoContextProvider({children}) {
    const [isContrast, setIsContrast] = useState(false);
    const [user, setUser] = React.useState(null);

    const value = React.useMemo(() => ({
        isContrast,
        user,
        toggleContrast: () => setIsContrast(!isContrast),
        login: data => {
            data.token && Storage.set('token', data.token);
            setUser(data);
        },
        logout: () => {
            Storage.remove('token');
            setUser(null);
        },
    }), [isContrast, user]);

    return (
        <SiteInfoContext.Provider value={value}>
            {children(value)}
        </SiteInfoContext.Provider>
    );
}
