import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import BackToMain from 'components/Buttons/BackToMain';
import TextItem from 'components/Text/TextItem';
import {
    Card
} from '@material-ui/core';
import API from "apis/API";
import Routes from "router/Routes";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from "@material-ui/styles";
import { studyTypes } from 'apis/Dictionaries';
import Title from "components/Text/Title";
import SaveButton from "components/Buttons/SaveButton";
import DeleteButton from "components/Buttons/DeleteButton";
import ProfileBadges from './ProfileBadges';
import ProfileGraduationDates from './ProfileGraduationDates';

const useStyles = makeStyles((theme) => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%'
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0,)
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em'
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    table: {
        margin: theme.spacing(2.5, 0),
        padding: theme.spacing(4, 2.5),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagenation_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    },
    title: {
        fontFamily: 'roboto',
        marginBottom: theme.spacing(2),
        color: theme.palette.color,
        fontSize: '0.9em',
        alignItems: 'center',
        fontWeight: '700',
    },
    facultyfiled: {
        margin: theme.spacing(1, 0),
    }
}));

const StudentProfile = props => {
    const { id } = useParams();
    const { history } = props;
    const classes = useStyles();
    const [profile, setProfile] = useState(null);

    const updateProfile = () => {
        API.Students.show(id).then(response => setProfile(response.data)).catch(() => setProfile(null));
    }

    useEffect(() => updateProfile(), [id]);


    if (!profile) {
        return (
            <>
                <BackToMain title="Powrót do listy studentów" to={Routes.Students.List} />
                {profile === null && <CircularProgress />}
            </>
        );
    }

    const studentStudies = profile.studies || [];

    const completeStatuses = ['graduated_end_of_study', 'graduated_diploma'];

    const termsToYears = [
        'I rok',
        'II rok',
        'III rok',
        'IV rok',
        'V rok',
    ];

    const handleSave = () => {

    }

    const handleDelete = () => {
        API.Students.delete(id).then(response => setTimeout(function () { history.push(Routes.Students.List); }, 500));
    }

    return (
        <>
            <Grid>
                <BackToMain title="Powrót do listy studentów" to={Routes.Students.List} />
                <Grid container spacing={3}>
                    <Grid item md={9}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Card>
                                    <TextItem subtitle="Nick studenta" md={4} value={profile?.nick_name} />
                                    <TextItem subtitle="Numer indeksu" md={4} value={profile?.index} />
                                    {/* <TextItem subtitle="Ilość odznak" value={profile.badge_amount} /> */}
                                    {/* <TextItem subtitle="Liczba punktow rankingu" value={profile.points} /> */}
                                    <Grid className={classes.facultyfiled}>
                                        {studentStudies.map(({ status, study, graduation_dates }, index) => (
                                            <React.Fragment key={index}>
                                                <Grid className={classes.title} >{`${study.faculty.name}, Kierunek ${study.name.replace(/\s*\(.*?\)/g, '')}`}</Grid>
                                                <Grid container spacing={3} justify="flex-end">
                                                    <Grid item xs={11}>
                                                        <TextItem subtitle="Typ studiow" md={3} valmd={7} value={`${studyTypes[study.type]} (${study.mode})`} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} justify="flex-end">
                                                    <Grid item xs={11}>
                                                        <TextItem subtitle="Rok" md={3} valmd={7} value={completeStatuses.includes(status) ? 'absolwent' : termsToYears[Math.floor((graduation_dates || []).length / 2)] + ' ' + study.name.replace(/^.*?(\(s.*?\)).*$/g, '$1')} />
                                                    </Grid>
                                                </Grid>
                                                {graduation_dates && graduation_dates.length > 0 ? <Grid container spacing={3} justify="flex-end">
                                                    <Grid item xs={11}>
                                                        <TextItem subtitle="Planowana data ukończenia studiów" value={moment(graduation_dates[0].planowana_data_ukonczenia, 'YYYY-MM-DD').format('DD.MM.YYYY')} />
                                                    </Grid>
                                                </Grid> : null}
                                                {/* <Grid container spacing={3} justify="flex-end">
											<Grid item xs={11}>
												<TextItem subtitle="Miejsce w rankingu wydzialowym" value={study.faculty_place_rank} />
											</Grid>
										</Grid>
										<Grid container spacing={3} justify="flex-end">
											<Grid item xs={11}>
												<TextItem subtitle="Miejsce w rankingu rocznikowym" value={study.faculty_year_rank} />
											</Grid>
										</Grid> */}
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Title value="Historia statusów" />
                                <ProfileGraduationDates datesHistory={profile?.graduation_dates_history} updateProfile={updateProfile}/>
                            </Grid>
                            <Grid item>
                                <Title value="Lista Odznak" />
                                <ProfileBadges />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DeleteButton
                                        title="Czy na pewno chcesz usunąć profil studenta z aplikacji?"
                                        handleDelete={handleDelete} />
                                </Grid>
                                <Grid item xs={6}>
                                    <SaveButton title="Zapisz zmiany" onClick={handleSave} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StudentProfile;
