import React from 'react';
import { Grid } from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontFamily: 'roboto',
        marginBottom: theme.spacing(2),
        color: theme.palette.color,
        fontSize: '0.85em',
        alignItems: 'center',
        fontWeight: '700',
    },
    content: {
        fontFamily: 'roboto',
        fontSize: '0.8em',
    }
}));

const TextItem = (props) => {
	const { subtitle, value, xs, md, valxs, valmd } = props;
	const classes = useStyles();

	return (
        <Grid container spacing={3}>
            <Grid item xs={xs || 5} md={md || 5} className={classes.subtitle}>
                {subtitle}
            </Grid>
            <Grid item xs={valxs || 5} md={valmd || 5} className={classes.content}>
                {value}
            </Grid>
        </Grid>
	);
};

export default TextItem;
