import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModal from "../Modals&Toasts/DeleteModal";

const useStyles = makeStyles(theme => ({
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.green_yellow
        },
        padding: theme.spacing(0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.green_yellow,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
}));

export default function DeleteButton({handleDelete, title, buttonTitle}) {
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();

    const handleClose = () => setOpenModal(false);

    return (
        <>
            <Button variant="contained" color="secondary" className={classes.filled_button} onClick={() => setOpenModal(true)}>
                {buttonTitle 
                    ? buttonTitle
                    : <DeleteIcon/>
                }
            </Button>
            <DeleteModal
                title={title}
                openModal={openModal}
                handleClose={handleClose}
                handleDelete={handleDelete}
            />
        </>
    );
};
