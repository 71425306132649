import React, {useState, useEffect} from 'react';
import {TableCell, TableRow, Grid, Card} from '@material-ui/core';
import API from "../../apis/API";
import Routes from "../../router/Routes";
import {useHistory} from "react-router-dom";
import PaginatedTable from "../../components/Table/PaginatedTable";
import Title from "../../components/Text/Title";
import FillButton from "../../components/Buttons/FillButton";
import {makeStyles} from "@material-ui/styles";
import {formatDateTime} from "../../utils/formatters";
import BackToMain from "../../components/Buttons/BackToMain";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";
import Checkbox from "../../components/Form/Checkbox";
import makeFormData from "../../utils/makeFormData";
import DeleteModal from "../../components/Modals&Toasts/DeleteModal";

const useStyles = makeStyles((theme) => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%'
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0,)
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em'
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagination_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    }
}));

const CalendarEventsSuggestedListScreen = ({children, title}) => {
    const classes = useStyles();
    const history = useHistory();

    const columns = [
        {
            title: 'Opcja',
            name: 'option',
        },
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa wydarzenia',
            name: 'title',
        },
        {
            title: 'Status',
            name: 'is_confirmed',
        },
        {
            title: 'Autor',
            name: 'author_id',
        },
        {
            title: 'Kategorie',
            name: 'category_id',
        },
        {
            title: 'Data rozpoczęcia',
            name: 'start_date',
        },
        {
            title: 'Data zakończenia',
            name: 'end_date',
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];

    const facultyType = [
        {value: '600000000', label: 'Chemii'},
        {value: '800000000', label: 'Fizyki, Astronomii i Informatyki Stosowanej'},
        {value: '1000000000', label: 'Matematyki i Informatyki'},
        {value: '1100000000', label: 'Nauk Ekonomicznych i Zarządzania'},
        {value: '1200000000', label: 'Nauk Historycznych'},
        {value: '1300000000', label: 'Prawa i Administracji'},
        {value: '1400000000', label: 'Sztuk Pięknych'},
        {value: '1500000000', label: 'Teologiczny'},
        {value: '1600000000', label: 'Lekarski'},
        {value: '1700000000', label: 'Farmaceutyczny'},
        {value: '1800000000', label: 'Nauk o Zdrowiu'},
        {value: '2100000000', label: 'Biologii i Ochrony Środowiska (2012-2019)'},
        {value: '2400000019', label: 'Filozofii i Nauk Społecznych'},
        {value: '2500000019', label: 'Humanistyczny'},
        {value: '2600000019', label: 'Nauk Biologicznych i Weterynaryjnych'},
        {value: '2700000019', label: 'Nauk o Polityce i Bezpieczeństwie'},
        {value: '2800000019', label: 'Nauk o Ziemi i Gospodarki Przestrzennej'},
    ];

    const studyTypeOptions = [
        {
            label: 'Studia licencjackie',
            value: 'first_cycle',
        },
        {
            label: 'Studia uzupełniające magisterskie',
            value: 'second_cycle',
        },
        {
            label: 'Studia doktoranckie',
            value: 'third_cycle',
        },
        {
            label: 'Studia doktoranckie',
            value: 'phd_school',
        },
        {
            label: 'Studia jednolite magisterskie',
            value: 'long_cycle',
        },
    ];

    const studyPathOptions = [
        { label: 'rzeźba', value: '14510131-KRK' },
        { label: 'lekarski', value: '16510147-PR-KRK' },
        { label: 'lekarski', value: '16550147-PR-KRK' },
        { label: 'lekarski', value: '16540147-PR-KRK' },
        { label: 'sztuka mediów i edukacja wizualna', value: '14510101-KRK' },
        { label: 'architektura wnętrz', value: '14510102-KRK' },
        { label: 'inżynieria biomedyczna (studia wspólne)', value: '16510203-KRK' },
        { label: 'analityka medyczna', value: '17510149-PR-KRK' },
        { label: 'pielęgniarstwo', value: '18510000-PRZ' },
        { label: 'fizjoterapia', value: '18510000-PRZ' },
        { label: 'międzyobszarowe indywidualne studia humanistyczno-społeczne', value: '24510200-KRK' },
        { label: 'dietetyka', value: '18510000-PRZ' },
        { label: 'lekarski', value: '16510000-PRZ' },
        { label: 'farmacja', value: '17510000-PRZ' },
        { label: 'bezpieczeństwo narodowe', value: '27510201-KRK' },
        { label: 'Szkoła doktorska', value: '74510401' },
        { label: 'matematyka stosowana', value: '10510201-KRK' },
        { label: 'sztuka mediów i edukacja wizualna', value: '14510201-KRK' },
        { label: 'filologia klasyczna i studia śródziemnomorskie', value: '25510201-KRK' },
        { label: 'medioznawstwo', value: '24510201-KRK' },
        { label: 'biologia', value: '26510201-KRK' },
        { label: 'biologia', value: '26510301-KRK' },
        { label: 'studia menedżersko-finansowe', value: '11520201-KRK' },
        { label: 'doradztwo podatkowe', value: '13510301-KRK' },
        { label: 'terapia zajęciowa', value: '18510201-KRK' },
        { label: 'gospodarka przestrzenna i geozarządzanie', value: '28510301-KRK' },
        { label: 'matematyka stosowana', value: '10510201-KRK' },
        { label: 'gospodarka przestrzenna i geozarządzanie', value: '28510301-3S-KRK' },
        { label: 'gospodarka przestrzenna', value: '28510201-KRK' },
        { label: 'optyka okularowa z elementami optometrii', value: '16510201-KRK' },
        { label: 'doradztwo podatkowe', value: '13510301-KRK' },
        { label: 'terapia zajęciowa', value: '18510201-KRK' },
        { label: 'doradztwo podatkowe', value: '13520301-KRK' },
        { label: 'architektura wnętrz', value: '14510202-KRK' },
        { label: 'weterynaria', value: '26510102-KRK' },
        { label: 'komunikacja i psychologia w biznesie', value: '11510202-OG-KRK' },
        { label: 'lingwistyka praktyczna i copywriting', value: '25510202-KRK' },
        { label: 'optometria', value: '16510302-KRK' },
        { label: 'prawo ochrony środowiska', value: '13510302-KRK' },
        { label: 'chemia i technologia żywności', value: '06510202-KRK' },
        { label: 'geografia', value: '28510202-KRK' },
        { label: 'geografia', value: '28510302-KRK' },
        { label: 'komunikacja i psychologia w biznesie', value: '11520202-OG-KRK' },
        { label: 'psychologia', value: '24510102-KRK' },
        { label: 'Szkoła doktorska', value: '74510402' },
        { label: 'analiza danych', value: '10510302-3S-KRK' },
        { label: 'logistyka', value: '11520203-KRK' },
        { label: 'logistyka', value: '11510203-KRK' },
        { label: 'ochrona środowiska', value: '26510303-KRK' },
        { label: 'ochrona środowiska', value: '26510203-KRK' },
        { label: 'Szkoła doktorska', value: '74510403' },
        { label: 'Szkoła doktorska', value: '74510404' },
        { label: 'biotechnologia medyczna', value: '16510304-KRK' },
        { label: 'biotechnologia', value: '26510304-KRK' },
        { label: 'biotechnologia medyczna', value: '16510204-KRK' },
        { label: 'pedagogika przedszkolna i wczesnoszkolna', value: '24510104-KRK' },
        { label: 'biotechnologia', value: '26510204-KRK' },
        { label: 'chemia', value: '06510305-3S-KRK' },
        { label: 'architektura informacji', value: '24510205-KRK' },
        { label: 'filologia polska jako obca', value: '25510205-KRK' },
        { label: 'chemia', value: '06510305-KRK' },
        { label: 'chemia', value: '06510305-3S-KRK' },
        { label: 'architektura informacji', value: '24510305-KRK' },
        { label: 'chemia', value: '06550305-KRK' },
        { label: 'chemia', value: '06510205-KRK' },
        { label: 'chemia', value: '06550205-KRK' },
        { label: 'Szkoła doktorska', value: '74510405' },
        { label: 'chemia medyczna', value: '06510206-KRK' },
        { label: 'filologia polska', value: '25510206-KRK' },
        { label: 'filologia polska', value: '25510306-KRK' },
        { label: 'biologia sądowa', value: '26510206-KRK' },
        { label: 'pedagogika medialna', value: '24510206-KRK' },
        { label: 'chemia medyczna', value: '06510306-KRK' },
        { label: 'biologia sądowa', value: '26510206-KRK' },
        { label: 'chemia kryminalistyczna', value: '06510307-KRK' },
        { label: 'sport i wellness', value: '26510207-KRK' },
        { label: 'astronomia', value: '08510208-KRK' },
        { label: 'astronomia', value: '08510308-KRK' },
        { label: 'wychowanie fizyczne i sport', value: '24510308-KRK' },
        { label: 'diagnostyka molekularna', value: '26510308-KRK' },
        { label: 'logopedia', value: '25510208-KRK' },
        { label: 'fizyka', value: '08510209-KRK' },
        { label: 'fizyka', value: '08510309-KRK' },
        { label: 'filologia angielska', value: '25510210-KRK' },
        { label: 'fizyka techniczna', value: '08510210-KRK' },
        { label: 'fizyka techniczna', value: '08510310-3S-KRK' },
        { label: 'fizyka techniczna', value: '08510310-KRK' },
        { label: 'filologia angielska', value: '25510310-KRK' },
        { label: 'filologia angielska', value: '25520210-KRK' },
        { label: 'filologia angielska', value: '25520310-KRK' },
        { label: 'filozofia', value: '24510311-KRK' },
        { label: 'filozofia', value: '24510211-KRK' },
        { label: 'filologia bałkańska', value: '25510311-KRK' },
        { label: 'filologia bałkańska', value: '25510211-KRK' },
        { label: 'filologia germańska', value: '25510312-KRK' },
        { label: 'pedagogika', value: '24520312-KRK' },
        { label: 'pedagogika', value: '24510212-KRK' },
        { label: 'pedagogika', value: '24520212-KRK' },
        { label: 'filologia germańska', value: '25510212-KRK' },
        { label: 'pedagogika', value: '24510312-KRK' },
        { label: 'pedagogika', value: '24520312-KRK' },
        { label: 'politologia', value: '27510313-KRK' },
        { label: 'filologia romańska', value: '25510213-KRK' },
        { label: 'filologia romańska', value: '25510313-KRK' },
        { label: 'politologia', value: '27510213-KRK' },
        { label: 'socjologia', value: '24510314-KRK' },
        { label: 'filologia rosyjska', value: '25510214-KRK' },
        { label: 'filologia rosyjska', value: '25510314-KRK' },
        { label: 'socjologia', value: '24510214-KRK' },
        { label: 'filologia włoska', value: '25510215-KRK' },
        { label: 'matematyka', value: '10510215-KRK' },
        { label: 'matematyka', value: '10510315-KRK' },
        { label: 'informatyka', value: '10510216-INZ-KRK' },
        { label: 'informatyka', value: '10510216-KRK' },
        { label: 'informatyka', value: '10520316-KRK' },
        { label: 'informatyka', value: '10520216-INZ-KRK' },
        { label: 'informatyka', value: '10510316-3S-KRK' },
        { label: 'informatyka', value: '10510316-KRK' },
        { label: 'informatyka', value: '10520216-KRK' },
        { label: 'japonistyka', value: '25510316-KRK' },
        { label: 'japonistyka', value: '25510216-KRK' },
        { label: 'ekonomia', value: '11550317-KRK' },
        { label: 'ekonomia', value: '11510317-KRK' },
        { label: 'lingwistyka stosowana', value: '25510217-F-ACH-KRK' },
        { label: 'ekonomia', value: '11510217-KRK' },
        { label: 'lingwistyka stosowana', value: '25510217-R-ACH-KRK' },
        { label: 'ekonomia', value: '11510317-KRK' },
        { label: 'ekonomia', value: '11550317-KRK' },
        { label: 'lingwistyka stosowana', value: '25510217-W-ACH-KRK' },
        { label: 'finanse i rachunkowość', value: '11510318-KRK' },
        { label: 'finanse i rachunkowość', value: '11520318-KRK' },
        { label: 'finanse i rachunkowość', value: '11510218-KRK' },
        { label: 'finanse i rachunkowość', value: '11520218-KRK' },
        { label: 'zarządzanie', value: '11550319-KRK' },
        { label: 'zarządzanie', value: '11520219-KRK' },
        { label: 'zarządzanie', value: '11510319-KRK' },
        { label: 'zarządzanie', value: '11510219-KRK' },
        { label: 'zarządzanie', value: '11520319-KRK' },
        { label: 'zarządzanie', value: '11550219-KRK' },
        { label: 'archeologia', value: '12510220-KRK' },
        { label: 'archeologia', value: '12510320-KRK' },
        { label: 'historia', value: '12510321-KRK' },
        { label: 'historia', value: '12510221-KRK' },
        { label: 'historia sztuki', value: '14510222-KRK' },
        { label: 'historia sztuki', value: '14510322-KRK' },
        { label: 'stosunki międzynarodowe', value: '27510323-KRK' },
        { label: 'stosunki międzynarodowe', value: '27510223-KRK' },
        { label: 'zarządzanie informacją i bibliologia', value: '24510324-KRK' },
        { label: 'etnologia - antropologia kulturowa', value: '25510225-KRK' },
        { label: 'etnologia - antropologia kulturowa', value: '25510325-KRK' },
        { label: 'prawo', value: '13510126-KRK' },
        { label: 'prawo', value: '13520126-KRK' },
        { label: 'administracja', value: '13510227-KRK' },
        { label: 'administracja', value: '13520327-KRK' },
        { label: 'administracja', value: '13510327-KRK' },
        { label: 'administracja', value: '13520227-KRK' },
        { label: 'administracja', value: '13520327-KRK' },
        { label: 'administracja', value: '13510227-KRK' },
        { label: 'malarstwo', value: '14510129-KRK' },
        { label: 'malarstwo', value: '14520229' },
        { label: 'grafika', value: '14510130-KRK' },
        { label: 'edukacja artystyczna w zakresie sztuk plastycznych', value: '14510332-KRK' },
        { label: 'konserwacja i restauracja dzieł sztuki', value: '14510133-KRK' },
        { label: 'ochrona dóbr kultury', value: '14510234-KRK' },
        { label: 'ochrona dóbr kultury', value: '14510334-KRK' },
        { label: 'ochrona dóbr kultury', value: '14520334-KRK' },
        { label: 'teologia', value: '15510135-KRK' },
        { label: 'teologia', value: '15510135-KA-KRK' },
        { label: 'lekarski', value: '16510147-KRK' },
        { label: 'lekarski', value: '16550147-KRK' },
        { label: 'lekarski', value: '16540147-KRK' },
        { label: 'biotechnologia', value: '16510348-KRK' },
        { label: 'analityka medyczna', value: '17510149-OG-KRK' },
        { label: 'farmacja', value: '17510150-KRK' },
        { label: 'farmacja', value: '17540150-KRK' },
        { label: 'farmacja', value: '17550150-KRK' },
        { label: 'pielęgniarstwo', value: '18550251-KRK' },
        { label: 'pielęgniarstwo', value: '18510351-KRK' },
        { label: 'pielęgniarstwo', value: '18520351-KRK' },
        { label: 'pielęgniarstwo', value: '18510251-KRK' },
        { label: 'fizjoterapia', value: '18520252-OG-KRK' },
        { label: 'fizjoterapia', value: '18510152-KRK' },
        { label: 'fizjoterapia', value: '18540252-OG-KRK' },
        { label: 'fizjoterapia', value: '18520152-KRK' },
        { label: 'fizjoterapia', value: '18540352-KRK' },
        { label: 'fizjoterapia', value: '18540152-KRK' },
        { label: 'fizjoterapia', value: '18550152-KRK' },
        { label: 'fizjoterapia', value: '18520352-KRK' },
        { label: 'fizjoterapia', value: '18510352-KRK' },
        { label: 'zdrowie publiczne', value: '18510353-KRK' },
        { label: 'automatyka i robotyka', value: '08510254-KRK' },
        { label: 'automatyka i robotyka', value: '08510354-3S-KRK' },
        { label: 'kulturoznawstwo', value: '25510255-KRK' },
        { label: 'kulturoznawstwo', value: '25510355-KRK' },
        { label: 'kosmetologia', value: '17520256-KRK' },
        { label: 'kosmetologia', value: '17510356-OG-KRK' },
        { label: 'kosmetologia', value: '17510256-KRK' },
        { label: 'kosmetologia', value: '17540256-KRK' },
        { label: 'archiwistyka i zarządzanie dokumentacją', value: '12510357-KRK' },
        { label: 'archiwistyka i zarządzanie dokumentacją', value: '12510257-KRK' },
        { label: 'turystyka i rekreacja', value: '28510358-KRK' },
        { label: 'turystyka i rekreacja', value: '28510258-KRK' },
        { label: 'pedagogika specjalna', value: '24510159-KRK' },
        { label: 'pedagogika specjalna', value: '24510359-KRK' },
        { label: 'dietetyka', value: '18520261-KRK' },
        { label: 'dietetyka', value: '18510361-KRK' },
        { label: 'dietetyka', value: '18510261-KRK' },
        { label: 'dietetyka', value: '18510361-KRK' },
        { label: 'ratownictwo medyczne', value: '18510262-KRK' },
        { label: 'ratownictwo medyczne', value: '18520262-KRK' },
        { label: 'położnictwo', value: '18520364-KRK' },
        { label: 'położnictwo', value: '18510364-KRK' },
        { label: 'położnictwo', value: '18510264-KRK' },
        { label: 'dziennikarstwo i komunikacja społeczna', value: '24510267-KRK' },
        { label: 'dziennikarstwo i komunikacja społeczna', value: '24510367-KRK' },
        { label: 'informatyka stosowana', value: '08510269-KRK' },
        { label: 'informatyka stosowana', value: '08510369-3S-KRK' },
        { label: 'matematyka i ekonomia', value: '10510270-KRK' },
        { label: 'kognitywistyka', value: '24510371-KRK' },
        { label: 'kognitywistyka', value: '24510271-KRK' },
        { label: 'praca socjalna', value: '24510373-KRK' },
        { label: 'praca socjalna', value: '24510273-KRK' },
        { label: 'nauki o rodzinie', value: '15510376-KRK' },
        { label: 'nauki o rodzinie', value: '15510276-KRK' },
        { label: 'bezpieczeństwo wewnętrzne', value: '27520377-KRK' },
        { label: 'bezpieczeństwo wewnętrzne', value: '27520277-KRK' },
        { label: 'bezpieczeństwo wewnętrzne', value: '27510277-KRK' },
        { label: 'bezpieczeństwo wewnętrzne', value: '27510377-KRK' },
        { label: 'wojskoznawstwo', value: '12510282-KRK' },
        { label: 'wojskoznawstwo', value: '12510382-KRK' },
        { label: 'krytyka artystyczna', value: '14510284-KRK' },
        { label: 'chemia kosmetyczna', value: '06510385-KRK' },
        { label: 'chemia kosmetyczna', value: '06510285-KRK' },
        { label: 'elektroradiologia', value: '18520289-OG-KRK' },
        { label: 'elektroradiologia', value: '18510289-OG-KRK' },
        { label: 'audiofonologia', value: '18520290-KRK' },
        { label: 'studia skandynawsko-bałtyckie', value: '12510395-KRK' },
    ];

    const studyYear = [
        { value: 'I rok(s1)', label: '1st year (s1)' },
        { value: 'II rok(s1)', label: '2nd year (s1)' },
        { value: 'III rok(s1)', label: '3rd year (s1)' },
        { value: 'I rok(s2)', label: '1st year (s2)' },
        { value: 'II rok(s2)', label: '2nd year (s2)' },
        { value: 'I rok(sj)', label: '1st year (sj)' },
        { value: 'II rok(sj)', label: '2nd year (sj)' },
        { value: 'III rok(sj)', label: '3rd year (sj)' },
        { value: 'IV rok(sj)', label: '4th year (sj)' },
        { value: 'V rok(sj)', label: '5th year (sj)' },
    ]

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nowe wydarzenie',
            onClick: () => history.push(Routes.CalendarSuggestedEvents.Create)
        }
    ];

    const handleEditItem = item => history.push(Routes.CalendarSuggestedEvents.Edit(item.id));

    useEffect(() => {
        (API.CalendarSuggestedEvents.byCategories)({})
            .then((response) => {
                let byCat = []
                response.data.categories.map(val => {
                    let total
                    response.data.by_category.map(val2 => {
                        if (!total && val2.category_id == val.id)
                        {
                            total = val2.total
                        }
                    })
                    if (total > 0)
                    {
                        byCat.push(
                            {
                                id: val.id,
                                title: val.title.pl,
                                total: total
                            }
                        )                    
                    }
                })
                setCategories(byCat)
            });
    }, []);

    const [options, setOptions] = useState([])

    const onOptionChange = (id) => {
        const newOptions = [...options]
        newOptions[id] = !newOptions[id]
        setOptions(newOptions)
    }

    const renderRow = (item, index) => {
        let author = false
        let student = false
        if (item?.author?.name || item?.author?.email)
        {
            author = item?.author?.name || item?.author?.email
        }
        if (item?.student?.nick_name)
        {
            student = item?.student?.nick_name + ' - student'
        }
        return <TableRow key={index} className={classes.root}>
            <TableCell><Checkbox
                name={'option'}
                title={''}
                value={options[item.id]}
                onChange={() => onOptionChange(item.id)}
            /></TableCell>
            <TableCell onClick={() => handleEditItem(item)} >{item.id}</TableCell>
            <TableCell onClick={() => handleEditItem(item)} className={classes.highlight}>{item.title['pl']}</TableCell>
            <TableCell onClick={() => handleEditItem(item)}>{item?.confirm_date ? 'Opublikowane' : 'Do zatwierdzenia'}</TableCell>
            <TableCell onClick={() => handleEditItem(item)}>{
                !author && !student 
                    ? 'N/A'
                    : false}
                {!!author && author}
                {!!student && !!author && <br/>}
                {!!student && student}
            </TableCell>
            <TableCell onClick={() => handleEditItem(item)}>{item?.category?.title['pl'] || 'N/A'}</TableCell>
            <TableCell onClick={() => handleEditItem(item)}>{formatDateTime(item?.start_date)}</TableCell>
            <TableCell onClick={() => handleEditItem(item)}>{formatDateTime(item?.end_date)}</TableCell>
            <TableCell onClick={() => handleEditItem(item)}>{formatDateTime(item.updated_at)}</TableCell>
        </TableRow>
    };

    const [categories, setCategories] = useState([])

    const CategoryOptions = [
        {
            value: false,
            label: 'Wszystkie kategorie'
        }
    ]

    const OptionsOptions = [
        {
            value: false,
            label: 'Masowe działania'
        },
        {
            value: 'confirm',
            label: 'Zatwierdz'
        },
        {
            value: 'unpublish',
            label: 'Cofnij'
        },
        {
            value: 'delete',
            label: 'Usun'
        }
    ]

    categories.map(val => {
        CategoryOptions.push(
            {
                value: val.id,
                label: val.title + ' (' + val.total + ')'            
            }
        )
    })

    const [category, setCategory] = useState(false)

    const onCategoryChange = e => {
        setCategory(e.target.value)
    }

    const customParams = {}
    if (category)
    {
        customParams['category_id'] = category
    }

    const [filters, setFilters] = useState([
        {
            options: [ { value: '', label: 'Any' } , ...facultyType ],
            name: 'study_faculty',
            label: 'Pokaż wszystkie wydziały',
        },
        {
            options: [ { value: '', label: 'Any' } , ...studyPathOptions ],
            name: 'study_path',
            label: 'Pokaż wszystkie kierunki',
        },
        {
            options: [ { value: '', label: 'Any' } , ...studyTypeOptions ],
            name: 'study_type',
            label: 'Pokaż wszystkie typy studiów',
        },
        {
            options: [ { value: '', label: 'Any' } , ...studyYear ],
            name: 'study_year',
            label: 'Pokaż wszystkie roki',
        },
    ]);

    const onOptionSelect = e => {
        let selectedItems = []
        options.map((val, key) => {
            if (val)
            {
                selectedItems.push(key)
            }
        });
        if (selectedItems.length > 0)
        {
            let api
            switch(e.target.value)
            {
                case 'unpublish': api = API.CalendarSuggestedEvents.massUnpublish; break;
                case 'confirm': api = API.CalendarSuggestedEvents.massConfirm; break;
                case 'delete': setOpenModal(true); break;
            }
            if (api)
            {
                (api)({ids: selectedItems})
                .then((response) => {
                    if (response.data.processed > 0)
                    {
                        setOptions([])
                    }
                });
            }
        }
    }

    const [openModal, setOpenModal] = useState(false);
    const handleDeleteClose = () => setOpenModal(false);
    const handleDeleteConfirm = () => {
        setOpenModal(false);
        let selectedItems = []
        options.map((val, key) => {
            if (val)
            {
                selectedItems.push(key)
            }
        });
        (API.CalendarSuggestedEvents.massDelete)({ids: selectedItems})
        .then((response) => {
            if (response.data.processed > 0)
            {
                setOptions([])
            }
        });
    }

    return (
        <>
            <DeleteModal
                title={'Czy na pewno chcesz usunąć wybrane wydarzenia?'}
                openModal={openModal}
                handleClose={handleDeleteClose}
                handleDelete={handleDeleteConfirm}
            />

            <BackToMain title="Powrót do listy" to={Routes.CalendarEvents.List}/>

            <Title value={'Sugerowane wydarzenia'}/>

            <Card className={classes.card}>
            <Grid container spacing={3} className={classes.title}>
                <Grid item xs={12} md={2}>
                    <Dropdown
                        value={category}
                        options={OptionsOptions}
                        titleField={'label'}
                        valueField={'value'}
                        onChange={onOptionSelect}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Dropdown
                        name={'category'}
                        value={category}
                        options={CategoryOptions}
                        titleField={'label'}
                        valueField={'value'}
                        onChange={onCategoryChange}
                    />
                </Grid>                
            </Grid>
            </Card>

            <PaginatedTable
                buttons={buttons}
                endpoint={API.CalendarSuggestedEvents.byOption}
                customParams={customParams}
                renderRow={renderRow}
                columns={columns}
                filters={filters}
            />
        </>
    );
};

export default CalendarEventsSuggestedListScreen;
