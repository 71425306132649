import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import {ToastProvider} from 'react-toast-notifications';
import AppContainer from './AppContainer';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {Interceptors} from './apis/Interceptors';
import RoutesRouter from './router/RoutesRouter';
import moment from "moment";
import "moment/locale/pl";
import {SiteInfoContextProvider} from "./utils/SiteInfoContext";

Interceptors.register();
moment.locale('pl');

export default function App() {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
            <ToastProvider>
                <SiteInfoContextProvider>
                    {({isContrast}) => (
                        <ThemeProvider theme={theme(isContrast)}>
                            <BrowserRouter>
                                <AppContainer>
                                    <RoutesRouter/>
                                </AppContainer>
                            </BrowserRouter>
                        </ThemeProvider>
                    )}
                </SiteInfoContextProvider>
            </ToastProvider>
        </MuiPickersUtilsProvider>
    );
}
