import React from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Dropdown from "./PlainInputs/Dropdown";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function FormDropdown({title, ...rest}) {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={classes.title}>
                <label>{title}</label>
            </Grid>
            <Grid item xs={8}>
                <Dropdown {...rest}/>
            </Grid>
        </Grid>
    );
};
