import React from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
    },
    input_box: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
        '& svg': {
            fill: theme.palette.text.secondary,
        },
        color: theme.palette.text.primary,
        '& select': {
            border: `1px solid ${theme.palette.gray}`,
            borderRadius: '0px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 20px',
        },
    },
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
        fontFamily: 'roboto',
    },
}));

export default function Dropdown({name, value, multiple, options, valueField = 'id', titleField = 'title', error, disabled, ...rest}) {
    const classes = useStyles();
    const val = multiple && !Array.isArray(value) ? (value ? [value] : []) : value;
    return (
        <>
            <FormControl variant="outlined" className={classes.formControl} error={!!error} disabled={disabled}>
                <Select inputProps={{name}} className={classes.input_box} value={val} multiple={multiple} {...rest}>
                    {!!options?.length && options.map((option, index) => (
                        <MenuItem key={index} value={option[valueField]}>
                            {typeof option[titleField] === 'string' ? option[titleField] : option[titleField].pl}
                        </MenuItem>
                    ))}
                </Select>
                <div className={classes.error_label} style={{display: error ? 'block' : 'none'}}>
                    {error}
                </div>
            </FormControl>
        </>
    );
};
