import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
	Grid, Card
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import API from "../../apis/API";
import Routes from "../../router/Routes";
import useApiResource from "../../hooks/useApiResource";
import makeFormData from "../../utils/makeFormData";
import Progress from "../../components/Main/Progress";
import BackToMain from "../../components/Buttons/BackToMain";
import Title from "../../components/Text/Title";
import ValidatorInput from "../../components/Form/ValidatorInput";
import SaveButton from "../../components/Buttons/SaveButton";
import DeleteButton from "../../components/Buttons/DeleteButton";
import { makeStyles } from "@material-ui/styles";

import Prefixes from './Prefixes';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 500,
		fontFamily: 'roboto',
		fontSize: '0.875em',
		display: 'flex',
		alignItems: 'center'
	},
	card: {
		marginBottom: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
}));

const ProgramsForm = props => {
	const { history, title } = props;
	const { id } = useParams();
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts();
	const [progressStatus, setProgressStatus] = useState(false);
	const [saving, setSaving] = useState(false);
	const [faculties] = useApiResource(API.programs.faculties);
	const [data, setData] = useState({
		faculty: 0,
		field_of_study: '',
		usos_id: '',
		prefixes: []
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (id) {
			API.programs.single(id).then(program => {
				setData({
					id,
					faculty: parseInt(program.faculty_id),
					field_of_study: program.field_of_study,
					usos_id: program.usos_id,
					prefixes: program.prefixes,
				});
			});
		}
	}, []);

	const checkValidate = () => {
		return data.field_of_study.length === 0
			|| data.usos_id.length === 0;
	};

	const handleValidate = () => {
		setErrors(prev => {
			prev['field_of_study'] = data.field_of_study.length === 0 ? true : false;
			prev['usos_id'] = data.usos_id.length === 0 ? true : false;
			return prev;
		});
	};

	const handleChange = (value, type) => {
		setData(prev => ({ ...prev, [type]: value }));
	};

	const handleSave = () => {
		let _data = {...data};
		let prefixes = _data.prefixes.filter(item => !!item);
		_data.prefixes = prefixes;

		let formData = makeFormData(_data);

		removeAllToasts();
		if (checkValidate()) {
			handleValidate();
			addToast(<label>Proszę wypełnić wszystkie wymagane pola.</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false });
		} else {
			setProgressStatus(true);
			setSaving(true);
			API.programs.save(formData)
				.then(response => {
					goToList(response);
				});
		}
	};

	const handleDelete = () => {
		removeAllToasts();
		setProgressStatus(true);
		API.programs.delete(id)
			.then(response => {
				goToList(response);
			});
	};

	const goToList = (response) => {
		if (response.code === 401) {
			history.push('/login');
		} else {
			let appearance = 'error';
			let autoDismissTimeout = 3000;
			let autoDismiss = false;

			if (response.code === 200) {
				appearance = 'success';
				autoDismissTimeout = 1000;
				autoDismiss = true;
			}
			addToast(<label>{response.message}</label>, { appearance, autoDismissTimeout, autoDismiss });

			if (response.code === 200) {
				setTimeout(function () { history.push(Routes.Programs.List); }, 1000);
			}
			setProgressStatus(false);
			setSaving(false);
		}
	};

	return (
		<>
			<BackToMain title="Powrót do listy programy studiów" to={Routes.Programs.List} />
			<Title value={title ? title : 'Informacje'} />

			<Grid container spacing={3}>
				<Grid container item xs={9} direction="column" spacing={3}>
					<Grid item>
						<Card className={classes.card}>
							<ValidatorInput
								title="Wydział"
								type="single"
								value={data.faculty}
								list={faculties ?? []}
								handleChange={value => handleChange(parseInt(value), 'faculty')}
							/>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<Title className={classes.title} value="Kierunek" />
								</Grid>
								<Grid item xs={6}>
									<ValidatorInput
										type="text"
										value={data.field_of_study}
										handleChange={value => handleChange(value, 'field_of_study')}
										hasError={errors?.field_of_study}
										error_message="Proszę wypełnić to pole."
									/>
								</Grid>

								<Grid item xs={3}>
									<ValidatorInput
										title="ID"
										type="text"
										value={data.usos_id}
										handleChange={value => handleChange(value, 'usos_id',)}
										hasError={errors?.usos_id}
										error_message="Proszę wypełnić to pole."
									/>
								</Grid>
							</Grid>

							<Prefixes
								title="Prefiksy"
								value={data.prefixes}
								handleChange={value => handleChange(value, 'prefixes')}
							/>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<Grid container spacing={2}>
							{id &&
								<Grid item xs={6}>
									<DeleteButton
										title="Usun"
										handleDelete={handleDelete} />
								</Grid>
							}
							<Grid item xs={id ? 6 : 12}>
								<SaveButton title="Dodaj program" onClick={handleSave} saving={saving} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default ProgramsForm;
