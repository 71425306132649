import React from "react";

export default function useApiResource(method, args) {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        method(...(args || []))
            .then(response => setData(response.data || response))
            .catch(setError)
            .finally(() => setLoading(false));
    }, [method, args]);

    return [data, loading, error];
};
