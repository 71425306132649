import Repository from "./Repository";
import Routes from "../router/Routes";
import Validator, {Custom, Email, Required} from "../utils/Validator";

export default new class UsersRepository extends Repository {
    Path = 'users';
    Routes = Routes.Users;
    Validators = {
        email: new Validator(Required, Email),
        password: new Validator(Custom(value => window.location.pathname.endsWith('create') ? Required(value) : '')),
    };
    defaultValues = () => ({role_id: 1})
}
