import React from 'react';
import PaginatedTable from "../../components/Table/PaginatedTable";
import API from "../../apis/API";
import {TableCell, TableRow} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Routes from "../../router/Routes";
import FillButton from "../../components/Buttons/FillButton";
import {formatDateTime} from "../../utils/formatters";

export default function MarkersListScreen() {
    const history = useHistory();
    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa miejsca (punktu)',
            name: 'title',
        },
        {
            title: 'Kategoria',
            name: 'category_id',
        },
        {
            title: 'Adres',
            name: 'address',
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];
    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nowy znacznik',
            onClick: () => history.push(Routes.Markers.Create),
        },
    ];

    const renderRow = (item, index) => (
        <TableRow key={index} onClick={() => history.push(Routes.Markers.Edit(item.id))}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.title.pl}</TableCell>
            <TableCell>{item.category.title.pl}</TableCell>
            <TableCell>{item.address}</TableCell>
            <TableCell>{formatDateTime(item.updated_at)}</TableCell>
        </TableRow>
    );

	return (
	    <PaginatedTable
            buttons={buttons}
            endpoint={API.Markers.index}
            columns={columns}
            renderRow={renderRow}
        />
	);
};
