import React, {useContext} from "react";
import API from "./apis/API";
import {useHistory} from "react-router-dom";
import SiteInfoContext from "./utils/SiteInfoContext";

export default function AppContainer({children}) {
    const history = useHistory();
    const siteInfo = useContext(SiteInfoContext);

    React.useEffect(() => {
        API.auth.validateToken()
            .then(response => siteInfo.login(response.data))
            .catch(error => {
                if (error?.response?.status === 401) {
                    siteInfo.logout();
                    history.push('/login');
                    return;
                }

                throw error;
            });
    }, [history]);

    return children;
}
