import React, { useState, useEffect } from 'react';
import {
    Card,
    Table,
    TableHead,
    TableBody,
    TableSortLabel,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core';

import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import API from 'apis/API';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    highlight: {
        color: theme.palette.green_yellow,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    pagination_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color,
        },
    },
    old: {
        '& .MuiTableCell-body': {
            color: theme.palette.light_gray,
        },
    },
    text_button: {
        color: theme.palette.green_yellow,
        cursor: 'pointer',
        textDecoration: 'underline'
    },
}));

const GraduationDates = props => {
    const { datesHistory, updateProfile } = props;
    const classes = useStyles();

    const columns = [
        {
            title: 'ID',
        },
        {
            title: 'Data zmiany',
        },
        {
            title: 'Programme',
        },
        {
            title: 'Kolejność etapu',
        },
        {
            title: 'Kod etapu',
        },
        {
            title: 'Cykl realizacji',
        },
        {
            title: 'Status',
        },
        {
            title: 'Akcje',
        },
    ];

    const dates = [];
    for(let datesByKey of Object.values(datesHistory)) {
        for(let dateVersions of Object.values(datesByKey)) {
            for(let [key, dateVersion] of dateVersions.entries()) {
                dateVersion.old = key > 0;
                dates.push(dateVersion);
            }
        }
    }

    const deleteDate = async id => {
        await API.studentGraduationDates.delete(id);
        updateProfile();
    }

    const renderRow = (item, index) => {
        return (
            <TableRow key={index} className={item.old ? classes.old : null}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{item.study?.study?.usos_id}</TableCell>
                <TableCell>{item.kolejnosc_etapu_osoby}</TableCell>
                <TableCell>{item.kod_etapu_osoby}</TableCell>
                <TableCell>{item.cykl_realizacji_etapu_osoby}</TableCell>
                <TableCell>{item.status_zaliczenia_etapu_osoby}</TableCell>
                <TableCell>
                    <Link to={`/`} className={classes.text_button} onClick={() => deleteDate(item.id)}>Usuń</Link>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Card className={classes.table}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((info, index) => (
                            <TableCell key={index}>
                                <label htmlFor={info.name}>
                                    {info.title}
                                </label>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { !!dates?.length && dates.map(renderRow) }
                </TableBody>
            </Table>
        </Card>
    );
};

export default GraduationDates;
