import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import MaskedInput from 'react-text-mask';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from "@material-ui/styles";
import SingleSelect from "./SingleSelect";
import MultiSelect from "./MultiSelect";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center'
    },
    description: {
        border: `1px solid ${theme.palette.text.primary}`,
        fontSize: '0.8750em',
        fontFamily: 'roboto',
        padding: '10px 20px',
        width: '100%',
    },
    input_box: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '10px 20px',
        width: '100%',
        fontSize: '0.8750em'
    },
    input_number: {
        type: 'number',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '10px 20px',
        width: '100%',
        fontSize: '0.8750em'
    },
    error: {
        border: '0.5px solid red',
    },
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
        fontFamily: 'roboto'
    },
}));

const ValidatorInput = (props) => {
    const classes = useStyles();
    const { title, type, value, list, handleChange, hasError, error_message, className } = props;

    const handleValidator = (value) => {
        let error = false;
        if (type === "email") {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            error = !pattern.test(value);
        } else if (type === "select") {
            error = (parseInt(value) === 0);
        } else if (type === "text") {
            error = (value.length === 0);
        }

        handleChange(value, error);
    };

    return (
        <Grid container spacing={3} className={className}>
            {title &&
                <Grid item xs={3} className={classes.title}>
                    <label>{title}</label>
                </Grid>
            }
            <Grid item xs={title ? 9 : 12}>
                {
                    type === "email" || type === "text"
                        ?
                        <input
                            className={clsx({ [classes.input_box]: true, [classes.error]: hasError })}
                            value={value}
                            onChange={(e) => handleValidator(e.target.value)} />
                        :
                        type === "number"
                            ?
                            <input
                                className={clsx({ [classes.input_box]: true, [classes.error]: hasError })}
                                value={value}
                                type="number"
                                onChange={(e) => handleValidator(e.target.value)} />
                            :
                            type === "autosizetxt"
                                ?
                                <TextareaAutosize
                                    className={clsx({ [classes.description]: true, [classes.error]: hasError })}
                                    rowsMin={8}
                                    rowsMax={8}
                                    aria-label="description"
                                    value={value}
                                    onChange={(e) => handleValidator(e.target.value)}
                                />
                                :
                                type === "phone"
                                    ?
                                    <MaskedInput
                                        className={clsx({ [classes.input_box]: true, [classes.error]: hasError })}
                                        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                        value={value}
                                        onChange={(e) => handleValidator(e.target.value)}
                                        id="home_phone"
                                    />
                                    :
                                    type === "single"
                                        ?
                                        <SingleSelect
                                            value={value}
                                            handleChange={(value) => handleValidator(value)}
                                            list={list}
                                            error={hasError} />
                                        :
                                        type === "multi"
                                            ?
                                            <MultiSelect
                                                value={value}
                                                handleChange={(value) => handleValidator(value)}
                                                list={list}
                                                error={hasError} />
                                            :
                                            <></>
                }
                <div
                    className={classes.error_label}
                    style={{ display: hasError ? 'block' : 'none' }}
                >
                    {error_message}
                </div>
            </Grid>
        </Grid>
    );
};

export default withRouter(ValidatorInput);
