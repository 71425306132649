import React, { useContext, useEffect } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";

import API from "../../apis/API";
import Routes from "../../router/Routes";
import SiteInfoContext from "../../utils/SiteInfoContext";
import FillButton from "../../components/Buttons/FillButton";
import PaginatedTable from "../../components/Table/PaginatedTable";

const useStyles = makeStyles((theme) => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%'
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0,)
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em'
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagenation_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    },
    categoryColor: {
        height: '30px',
        width: '30px',
        borderRadius: '30px',
    }
}));

const Categories = props => {
    const classes = useStyles();
    const history = useHistory();
    const siteInfo = useContext(SiteInfoContext);

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa kategorii',
            name: 'title',
        },
        {
            title: 'Kolor',
            name: 'color',
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj kategorie wydarzeń',
            onClick: () => history.push(Routes.CalendarEventCategories.Create)
        }
    ];

    useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

    const handleEditItem = item => history.push(Routes.CalendarEventCategories.Edit(item.id));

    const renderRow = (item, index) => (
        <TableRow key={index} className={classes.root} onClick={() => handleEditItem(item)}>
            <TableCell>{item.id}</TableCell>
            <TableCell className={classes.highlight}>{item.title.pl}</TableCell>
            <TableCell>
                <div className={classes.categoryColor} style={{ backgroundColor: item.color }} />
            </TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            endpoint={API.CalendarEventsCategories.filter}
            renderRow={renderRow}
            columns={columns}
        />
    );
};

export default Categories;
