import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';

import API from "apis/API";
import Routes from "router/Routes";

import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';

import Progress from "components/Main/Progress";
import BackToMain from "components/Buttons/BackToMain";
import Title from "components/Text/Title";
import ValidatorInput from "components/Form/ValidatorInput";
import SaveButton from "components/Buttons/SaveButton";
import DateInput from "components/Form/DateInput";
import DateRangeInput from "components/Form/DateRangeInput";

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	label: {
		fontWeight: 500,
		color: 'rgb(38, 50, 56)',
		fontFamily: 'roboto',
		fontSize: '0.875em',
		display: 'flex',
		alignItems: 'center',
	}
}));

const StudiosForm = props => {
	const { history } = props;
	const { studyType, studyMode } = useParams();
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts();
	const [progressStatus, setProgressStatus] = useState(false);
	const [saving, setSaving] = useState(false);

	const termFieldIdsWithRangeDate = [2, 3, 6, 7, 8, 9];
	const [title, setTitle] = useState('');
	const [data, setData] = useState(null);
	const [editingData, setEditingData] = useState(null);
	const [terms, setTerms] = useState([]);
	const [selectedTerm, setSelectedTerm] = useState(0);
	const [errors, setErrors] = useState(null);
	const [termFields, setTermFields] = useState(null);

	useEffect(() => {
		if (studyType && studyMode) {
			API.studiesMaps.single(studyType, studyMode).then(res => {
				const data = res.data.data;
				setTitle(data.map?.name);
				const rows = data.rows;
				setData(rows);
				data.terms.sort((a, b) => { return b.order_key - a.order_key });
				setTerms(data.terms);
				const currentYearTerm = data.terms.find(term =>
					// moment.month 8 is September. If now is after 1 Oct, compare with year of start date. if not, compare with year of end date.
					moment(term[moment().month() > 8 ? 'start_date' : 'end_date']).year() === moment().year()
				);
				setSelectedTerm(currentYearTerm?.id);
				setEditingData(rows[currentYearTerm?.id]);
				setTermFields(prev => {
					prev = [];
					rows[currentYearTerm?.id]?.forEach(row => prev.push(row.term_field));
					return [...prev];
				});
			});
		}
	}, [studyType, studyMode]);

	const handleChange = (value) => {
		setData(prev => {
			prev[selectedTerm] = editingData;
			return { ...prev };
		});

		let _editingData = data[value] ? data[value] : getSampleEditingData(value);
		setEditingData(_editingData);
		setSelectedTerm(value);
	};

	const getSampleEditingData = termId => {
		let term = terms.find(term => term.id === parseInt(termId));
		let sampleData = [];
		for (let i = 1; i < 11; i++) {
			let _data = {
				id: 0,
				study_type: studyType,
				study_mode: studyMode,
				term_id: term.id,
				term_field_id: i,
				start_date: term.start_date,
				end_date: null,
				term_field: termFields.find(termField => termField.id === i)
			}
			if (termFieldIdsWithRangeDate.includes(i)) _data.end_date = term.start_date;
			if (studyType === 'first_cycle_mish' || (studyType !== 'first_cycle_mish' && i !== 4)) sampleData.push(_data);
		}

		return sampleData;
	}

	const handleDateChange = ({ target }) => {
		let value = target.value;
		setEditingData(prev => {
			if (typeof value === 'string') {
				prev[target?.name].start_date = value;
			} else {
				prev[target?.name] = {
					...prev[target?.name],
					start_date: value?.start_date,
					end_date: value?.end_date
				}
			}
			return [...prev];
		});
	};

	const handleSave = () => {
		let _data = { ...data };
		_data[selectedTerm] = editingData;
		removeAllToasts();
		// if (!handleValidate()) {
		// 	return;
		// }

		setProgressStatus(true);
		setSaving(true);

		API.studiesMaps.update({ data: _data, studyType, studyMode })
			.then(response => {
				let appearance = 'success';
				let autoDismissTimeout = 1000;
				let autoDismiss = true;
				addToast(<label>{response.data.message}</label>, { appearance, autoDismissTimeout, autoDismiss });

				setTimeout(function () { history.push(Routes.Studies.List); }, 1000);

				setProgressStatus(false);
				setSaving(false);
			});
	}

	const handleValidate = () => {
		let _errors = { ...errors };
		let term = terms.find(term => term.id === parseInt(selectedTerm));
		let errorMsg = `The date must be between ${term.start_date} and ${term.end_date}`;
		let passed = [];

		data.map(year => year.map(item => {
			let validStartDate = item.start_date >= term.start_date && item.start_date <= term.end_date;
			let validEndDate = item.end_date >= term.start_date && item.end_date <= term.end_date;

			if (parseInt(item.term_field_id) === 6 || parseInt(item.term_field_id) === 7) {
				let error = {}
				if (!validStartDate) {
					error['start_date'] = errorMsg
					passed.push(0);
				}

				if (!validEndDate) {
					error['end_date'] = errorMsg
					passed.push(0);
				}

				_errors[item.term_field_id] = error;
			} else {
				if (!validStartDate) {
					_errors[item.term_field_id] = errorMsg
					passed.push(0);
				} else {
					_errors[item.term_field_id] = false;
				}
			}
		}));

		setErrors(_errors);
		return passed.length < 1;
	};

	return (
		<>
			<BackToMain title="Powrót do listy map" to={Routes.Studies.List} />
			<Title value={title} />

			<Grid container spacing={3}>
				<Grid container item xs={9} direction="column" spacing={3}>
					<Grid item>
						<Card className={classes.card}>
							<Title className={classes.label} value={`Zarządzaj terminami na mapie`} />

							<ValidatorInput
								title="Wybierz rok akademicki"
								type="single"
								value={selectedTerm}
								list={terms}
								handleChange={(value, error) => handleChange(parseInt(value))}
							/>
							{editingData && editingData.map((item, index) => (
								<Grid item container spacing={4} alignItems="flex-start" key={index}>
									<Grid item xs={3}>
										<Title className={classes.label} value={item?.term_field?.name?.pl} />
									</Grid>
									<Grid item xs={9}>
										{termFieldIdsWithRangeDate.includes(item.term_field?.id)
											? <DateRangeInput
												name={index}
												onChange={handleDateChange}
												value={{ start_date: item.start_date, end_date: item.end_date }}
												errors={errors?.[item.term_field_id]}
												label={item.term_field?.id === 2 || item.term_field?.id === 3 ? 'do' : 'data zakończenia'}
											/>
											: <DateInput
												name={index}
												value={item.start_date}
												onChange={handleDateChange}
												error={errors?.[item.term_field_id]}
											/>
										}
									</Grid>
								</Grid>
							))}
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton title="Zapisz zmiany" onClick={handleSave} saving={saving} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default StudiosForm;
