import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.green_yellow
        },
        padding: theme.spacing(0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.green_yellow,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
}));

const CreateButton = props => {
    const {title, onClick} = props;

    const classes = useStyles();

    return (
        <Button variant="contained" color="secondary" className={classes.filled_button} onClick={onClick}>
            {title}
        </Button>
    );
};

export default CreateButton;
