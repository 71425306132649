import React, { useEffect, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import moment from "moment";

import API from "../../apis/API";
import Routes from "../../router/Routes";
import SiteInfoContext from "../../utils/SiteInfoContext";
import FillButton from "../../components/Buttons/FillButton";
import PaginatedTable from "../../components/Table/PaginatedTable";

const useStyles = makeStyles((theme) => ({
    public: {
        fontFamily: 'roboto'
    },
    controlBlock: {
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between'
        },
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagenation_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    }
}));

const Users = props => {
    const classes = useStyles();
    const history = useHistory();
    const siteInfo = useContext(SiteInfoContext);

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa użytkownika (email)',
            name: 'email',
        },
        {
            title: 'Rola',
            name: 'role',
        },
        {
            title: 'Wydział',
            name: 'faculty',
        },
        {
            title: 'Aktywny',
            name: 'activate_status',
        },
        {
            title: 'Data ostatniej aktywności',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nowego użytkownika',
            onClick: () => history.push(Routes.Users.Create)
        }
    ];

    useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

    const handleEditItem = item => history.push(Routes.Users.Edit(item.id));

    const renderRow = (item, index) => (
        <TableRow key={index} className={classes.root} onClick={() => handleEditItem(item)}>
            <TableCell>{item.id}</TableCell>
            <TableCell className={classes.highlight}>{item.email}</TableCell>
            <TableCell>{item.role?.name}</TableCell>
            <TableCell>{item.faculty}</TableCell>
            <TableCell>{item.activate_status === 1 ? 'TAK' : 'NIE'}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            endpoint={API.users.all}
            renderRow={renderRow}
            columns={columns}
        />
    );
};

export default Users;
