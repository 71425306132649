export default {
	Auth: {
		login: '/login',
	},
	Students: {
		List: '/students',
		Show: (id = ':id') => `/students/profile/${id}`,
	},
	Studies: {
		List: '/studios',
		Edit: (studyType = ':studyType', studyMode = ':studyMode') => `/studios/${studyType}/${studyMode}/edit`,
	},
	Badges: {
		List: '/badges',
		Edit: (id = ':id') => `/badges/${id}/edit`,
		Create: '/badges/create',
	},
	Zdarzenia: {
		List: '/zdarzenia',
		Edit: (id = ':id') => `/zdarzenia/${id}/edit`,
		Create: '/zdarzenia/create',
	},
	Groups: {
		List: '/groups',
		Edit: (id = ':id') => `/groups/${id}/edit`,
		Create: '/groups/create'
	},
	Events: {
		List: '/events',
		Edit: (id = ':id') => `/events/${id}/edit`,
		Create: '/events/create'
	},
    CalendarEvents: {
        List: '/calendar/events',
        Edit: (id = ':id') => `/calendar/events/${id}/edit`,
        Create: '/calendar/events/create',
        SemesterOrganization: '/calendar/organization'
    },    
    CalendarSuggestedEvents: {
        List: '/calendar/events/suggested',
        Edit: (id = ':id') => `/calendar/events/suggested/${id}/edit`,
        Create: '/calendar/events/suggested/create',        
    },  
	CalendarEventCategories: {
		List: '/categories',
		Edit: (id = ':id') => `/categories/${id}/edit`,
		Create: '/categories/create'
	},
	AcademicYears: {
        List: '/academic_years',
        Edit: (id = ':id') => `/academic_years/${id}/edit`,
        Create: '/academic_years/create'
    },
    Markers: {
        List: '/markers',
        Edit: (id = ':id') => `/markers/${id}/edit`,
        Create: '/markers/create',
    },
    MarkerCategories: {
        List: '/marker_categories',
        Edit: (id = ':id') => `/marker_categories/${id}/edit`,
        Create: '/marker_categories/create',
    },
	Points: {
		List: '/points',
		Edit: (id = ':id') => `/points/${id}/edit`,
		Create: '/points/create',
	},
	Users: {
		List: '/users',
		Edit: (id = ':id') => `/users/${id}/edit`,
		Create: '/users/create',
	},
	Programs: {
		List: '/programs',
		Create: '/programs/create',
		Edit: (id = ':id') => `/programs/${id}/edit`,
	},
	Other: {
		Programs: '/programs',
		NotFound: '/not-found',
	}
};
