import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useToasts } from 'react-toast-notifications'
import { useHistory, useParams } from "react-router-dom";

import API from "../../apis/API";
import Routes from "../../router/Routes";
import Messages from "../../utils/Messages";
import makeFormData from "../../utils/makeFormData";
import SiteInfoContext from "../../utils/SiteInfoContext";
import Validator, { HexColor, Required, validate } from "../../utils/Validator";
import Title from "../../components/Text/Title";
import FormInput from "../../components/Form/FormInput";
import SaveButton from "../../components/Buttons/SaveButton";
import BackToMain from "../../components/Buttons/BackToMain";
import DeleteButton from "../../components/Buttons/DeleteButton";
import ColorPicker from "../../components/Form/ColorPicker";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(20)
    },
    upload_card: {
        marginTop: theme.spacing(2),
    },
    progressContainer: {
        position: 'absolute',
        top: '50%',
        width: 'calc(100% - 600px)',
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        color: theme.palette.pink,
    },
}));

const DefaultValues = {
    title: { en: '', pl: '' },
    color: '#000000',
};

const Validators = {
    title: new Validator(Required),
    color: new Validator(Required, HexColor),
};

export default function MarkerCategoriesEditScreen() {
    const history = useHistory();
    const { id } = useParams();
    const classes = useStyles();
    const siteInfo = useContext(SiteInfoContext);
    const { addToast, removeAllToasts } = useToasts();
    const [data, setData] = useState(null);
    const [errors, setErrors] = React.useState(null);
    const [saving, setSaving] = React.useState(false);

    useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

    useEffect(() => {
        if (!id) {
            setData({ ...DefaultValues });
            return;
        }

        // todo - global 404 handler
        API.MarkerCategories.show(id).then(response => setData(response.data)).catch(() => setData(false));
    }, [id]);

    const handleSave = () => {
        removeAllToasts();
        setSaving(true);

        // validate
        setErrors(null);
        const errors = validate(data, Validators);
        if (errors) {
            addToast(Messages.SomeFieldsAreInvalid, {
                appearance: 'error',
                autoDismissTimeout: 3000,
                autoDismiss: true,
            });
            setErrors(errors);
            setSaving(false);
            return;
        }

        // submit
        (id ? API.MarkerCategories.edit : API.MarkerCategories.create)(makeFormData(data))
            .then(() => history.push(Routes.MarkerCategories.List))
            .catch(() => setSaving(false));
    };

    const handleDelete = () => {
        API.MarkerCategories.delete(id)
            .then(res => {
                let data = res.data;
                let toastOption = {
                    appearance: 'error',
                    autoDismissTimeout: 3000,
                    autoDismiss: true,
                }

                if (data.code === 400) {
                    addToast(data.message, toastOption);
                    return;
                }
                addToast(data.message, { ...toastOption, appearance: 'success' });
                history.push(Routes.MarkerCategories.List)
            });
    };

    const onChange = e => {
        const locale = e.target.dataset?.locale;
        const name = e.target.name;
        const value = e.target.value;

        setData({
            ...data,
            [name]: locale
                ? { ...data[name], [locale]: value }
                : value,
        });

        // validation
        const validator = Validators[name];
        if (!validator)
            return;

        const error = validator(value?.trim ? value.trim() : value);
        setErrors({
            ...(errors || {}),
            [name]: locale
                ? { ...(errors?.[name] || {}), [locale]: error }
                : error,
        });
    };

    if (!data) {
        return (
            <>
                <BackToMain title="Powrót do listy kategorie markerów" to={Routes.MarkerCategories.List} />
                {data === null && <CircularProgress />}
            </>
        );
    }

    return (
        <>
            <BackToMain title="Powrót do listy kategorie markerów" to={Routes.MarkerCategories.List} />
            <Title value={'Kategoria markera ' + (data?.title?.pl)} />
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card className={classes.card}>
                        <FormInput
                            title={'Nazwa punktu'}
                            name={'title'}
                            value={data.title}
                            onChange={onChange}
                            error={errors?.title}
                            translatable
                        />
                        <ColorPicker
                            title={'Kolor'}
                            name={'color'}
                            onChange={onChange}
                            value={data.color}
                            error={errors?.color}
                        />
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DeleteButton
                                    title="Czy na pewno chcesz usunąć ten kategorii markera?"
                                    handleDelete={handleDelete} />
                            </Grid>
                            <Grid item xs={6}>
                                <SaveButton onClick={handleSave} saving={saving} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
