import API from "../apis/API";

const extractData = response => response.data;

/**
 * Callback that customizes onChange logic and returns new state
 * @callback Repository~onChangeCustomizer
 *
 * @param {Object} e Event object (not a NativeEvent)
 * @param {string} e.target.name
 * @param {?string} e.target.dataset.locale
 * @param {*} e.target.value
 *
 * @param {Object} state Current form state
 *
 * @returns {Object} New form state
 */

/**
 * Abstract class that describes entity, its properties and CRUD methods
 * @class Repository
 * @property {string} Path URI, unique to each entity, e.g. for calendar events - 'calendar_events'
 * @property {Object} Routes One of Routes properties, e.g. {List: '', Edit: () => {}, Create: ''}
 * @property {Object} Validators Object with validation rules, see Validator.js
 * @property {Object<string, onChangeCustomizer>} OnChangeCustomizers Object with callbacks that customize onChange logic and return new state
 * @property {?Function} defaultValues Function that generates object with default values to be used as initial state on create screen
 * @property {?Function} statePreparer Function that prepares data from API to be used as state on edit screen
 * @property {?Function} savePreparer Function that prepares data for submission to API, called after successful validation
 */
export default class Repository {
    Path = '';
    Routes = {};
    Validators = {};
    OnChangeCustomizers = {};

    defaultValues;
    statePreparer;
    savePreparer;

    all = () => API.get(`/${this.Path}/all`).then(extractData);
    filter = params => API.get(`/${this.Path}`, {params}).then(extractData);
    single = id => API.get(`/${this.Path}/${id}`).then(extractData);
    create = data => API.post(`/${this.Path}`, data).then(extractData);
    edit = data => {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
            return API.post(`/${this.Path}/${data.get('id')}`, data).then(extractData);
        }

        return API.put(`/${this.Path}/${data.id}`, data).then(extractData);
    };
    delete = id => API.delete(`/${this.Path}/${id}`).then(extractData);
}
