import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'roboto',
        marginBottom: theme.spacing(2),
        color: theme.palette.color
    },
}));

const Title = (props) => {
  const classes = useStyles();
  const { value, className } = props;
  
  return (
    <div className={`${classes.title} ${className}`}>{value}</div>
  );
};

export default withRouter(Title);
