import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

import Routes from "../../router/Routes";
import SiteInfoContext from "../../utils/SiteInfoContext";
import SidebarNav from "./SidebarNav";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 250,
        borderRight: theme.palette.sidebar_border,
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    nav: {
        // marginBottom: theme.spacing(2),
    },
    logoBlock: {
        textAlign: 'center',
        // margin: theme.spacing(2),
    },
    main_logo: {
        width: '100%',
    },
    icon: {
        color: '#8b919a',
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        '&:hover': {
            color: '#EBECF0',
        },
    },
    logout: {
        padding: '20px 16px 20px 56px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: 400,
        fontSize: '1em',
        color: '#8b919a',
        lineHeight: '1em',
        '&:hover': {
            backgroundColor: '#1e202c',
            color: '#EBECF0',
        },
        position: 'absolute',
        bottom: '0px',
    },
}));

const Sidebar = ({ open, variant, onClose, className, staticContext, ...rest }) => {
    const classes = useStyles();
    const siteInfo = useContext(SiteInfoContext);

    const initialItems =
        [
            {
                title: 'Lista studentów',
                href: Routes.Students.List,
            },
            {
                title: 'Mapa studiów',
                href: Routes.Studies.List,
            },
            {
                title: 'Odznaki',
                href: Routes.Badges.List,
            },
            {
                title: 'Grupy przedmiotów',
                href: Routes.Groups.List,
            },
            {
                title: 'Zdarzenia',
                href: Routes.Zdarzenia.List,
            },
            {
                title: 'Kalendarz akademicki',
                href: Routes.CalendarEvents.List,
            },
            {
                title: 'Kategorie wydarzeń',
                href: Routes.CalendarEventCategories.List,
            },
            {
                title: 'Markery',
                href: Routes.Markers.List,
            },
            {
                title: 'Kategorie markerów',
                href: Routes.MarkerCategories.List,
            },
            {
                title: 'Użytkownicy systemu',
                href: Routes.Users.List,
            },
            {
                title: 'Programy studiów',
                href: Routes.Other.Programs,
            },
        ];

    const getFilteredItems = () => {
        if (siteInfo.user?.role?.id === 1) return initialItems;
        const restrictModules = [
            Routes.Users.List, 
            Routes.MarkerCategories.List,
            Routes.CalendarEventCategories.List,
            Routes.Zdarzenia.List
        ];

        return initialItems.filter(item => !restrictModules.includes(item.href))
    }

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div {...rest} className={clsx(classes.root, className)}>
                <div className={classes.logoBlock}>
                    <img src="/images/logos/logo%20umk.png" className={classes.main_logo} alt="Main Logo" />
                </div>
                <SidebarNav className={classes.nav} pages={getFilteredItems()} />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
