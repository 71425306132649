import React, {useRef} from "react";
import {Card, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import prettyBytes from "pretty-bytes";
import RedButton from "../Buttons/RedButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const useStyles = makeStyles(() => ({
    actionItem: {
        marginBottom: '16px',
    },
    actionLabel: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Roboto',
    },
    button: {
        marginLeft: '10px',
    },
    expand: {
        width: '99%',
    },
    shrink: {
        whiteSpace: 'nowrap',
    },
}));

export default function FilesPicker({value, name, multiple, onChange, ...rest}) {
    const classes = useStyles();
    const input = useRef();
    const files = Array.isArray(value) ? value : (value ? [value] : []);

    const openPicker = () => input.current.click();

    const deleteFile = index => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        onChange?.({
            target: {
                name,
                value: newFiles,
            },
        });
    };

    const change = e => onChange?.({
        target: {
            name,
            value: [...files, ...Array.from(e.target.files)],
        },
    });

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.actionItem}>
                    <Grid container spacing={3}>
                        <Grid item className={classes.actionLabel}>
                            <label>Pliki do pobrania</label>
                            <PrimaryButton className={classes.button} onClick={openPicker} variant={'contained'} color={'primary'}>
                                Dodaj nowy
                            </PrimaryButton>
                            <input
                                key={Math.random()}
                                ref={input}
                                type={'file'}
                                style={{display: 'none'}}
                                onChange={change}
                                multiple={multiple}
                                {...rest}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.expand}>
                                        Nazwa pliku
                                    </TableCell>
                                    <TableCell className={classes.shrink}>
                                        Wielkość
                                    </TableCell>
                                    <TableCell className={classes.shrink}>
                                        Akcje
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file, index) => (
                                    <TableRow key={file.name + index}>
                                        <TableCell className={classes.expand}>
                                            {!!file.url && (
                                                <a target={'_blank'} rel={'noreferrer'} href={file.url} download>
                                                    {file.name}
                                                </a>
                                            )}
                                            {!file.url && file.name}
                                        </TableCell>
                                        <TableCell className={classes.shrink}>
                                            {prettyBytes(parseInt(file.size))}
                                        </TableCell>
                                        <TableCell className={classes.shrink}>
                                            <RedButton onClick={() => deleteFile(index)}>
                                                Usuń
                                            </RedButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};
