import React from "react";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import ErrorLabel from "../ErrorLabel";

const useStyles = makeStyles(theme => ({
    input_box: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.black_white,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '10px 20px',
        width: '100%',
        fontSize: '0.8750em',
    },
    error: {
        border: '1px solid red',
    },
}));

export default function Input({error, value, locale, errorOnNextLine, ...rest}) {    
    const classes = useStyles();
    const err = locale ? error?.[locale] : error;
    return (
        <>
            {errorOnNextLine && err && <div>
                <input
                    className={clsx({[classes.input_box]: true, [classes.error]: !!err})}
                    value={(locale ? value?.[locale] : value) || ''}
                    data-locale={locale}
                    {...rest}
                />
                <ErrorLabel error={err}/>
            </div>}
            {(!errorOnNextLine || !err) && [<input
                    className={clsx({[classes.input_box]: true, [classes.error]: !!err})}
                    value={(locale ? value?.[locale] : value) || ''}
                    data-locale={locale}
                    {...rest}
                />,
                <ErrorLabel error={err}/>
            ]}
        </>
    );
};
