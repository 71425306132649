import React from 'react';
import {Grid, Card, CircularProgress} from '@material-ui/core';
import API from "../../apis/API";
import {useHistory, useParams} from "react-router-dom";
import Routes from "../../router/Routes";
import BackToMain from "../../components/Buttons/BackToMain";
import Title from "../../components/Text/Title";
import {makeStyles} from "@material-ui/styles";
import useApiResource from "../../hooks/useApiResource";
import useEditData from "../../hooks/useEditData";
import CalendarSuggestedEventsRepository from "../../repositories/CalendarSuggestedEventsRepository";
import FormInput from "../../components/Form/FormInput";
import Html from "../../components/Form/Html";
import DateInput from "../../components/Form/DateInput";
import TimeInput from "../../components/Form/TimeInput";
import Checkbox from "../../components/Form/Checkbox";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";
import Input from "../../components/Form/PlainInputs/Input";
import MarkersRepository from "../../repositories/MarkersRepository";
import DateTimeInput from "../../components/Form/DateTimeInput";
import ThumbnailPicker from "../../components/Form/ThumbnailPicker";
import FilesPicker from "../../components/Form/FilesPicker";
import SaveButton from "../../components/Buttons/SaveButton";
import DeleteButton from "../../components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
    },
    card: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(20),
    },
    input: {
        display: 'none',
    },
    formControl: {
        display: 'flex',
        alignItems: 'center',
    },
    calendarEventDateInput: {
        marginLeft: 10,
        marginRight: 10,
    },
}));

const LocationOptions = [
    {
        id: true,
        title: 'Online',
    },
    {
        id: false,
        title: 'Miejsce',
    },
];

const CustomReminderOptionValue = 'custom';
export const ReminderOptions = [
    {
        id: '600',
        title: '10 minut przed',
    },
    {
        id: '3600',
        title: '1 godzinę przed',
    },
    {
        id: '21600',
        title: '6 godzin przed',
    },
    {
        id: '86400',
        title: '1 dzień przed',
    },
    {
        id: '172800',
        title: '2 dni przed',
    },
    {
        id: '604800',
        title: 'Tydzień przed',
    },
    {
        id: CustomReminderOptionValue,
        title: 'Inne (data i godzina)',
    },
];

export const facultyType = [
    {id: '600000000', label: 'Chemii'},
    {id: '800000000', label: 'Fizyki, Astronomii i Informatyki Stosowanej'},
    {id: '1000000000', label: 'Matematyki i Informatyki'},
    {id: '1100000000', label: 'Nauk Ekonomicznych i Zarządzania'},
    {id: '1200000000', label: 'Nauk Historycznych'},
    {id: '1300000000', label: 'Prawa i Administracji'},
    {id: '1400000000', label: 'Sztuk Pięknych'},
    {id: '1500000000', label: 'Teologiczny'},
    {id: '1600000000', label: 'Lekarski'},
    {id: '1700000000', label: 'Farmaceutyczny'},
    {id: '1800000000', label: 'Nauk o Zdrowiu'},
    {id: '2100000000', label: 'Biologii i Ochrony Środowiska (2012-2019)'},
    {id: '2400000019', label: 'Filozofii i Nauk Społecznych'},
    {id: '2500000019', label: 'Humanistyczny'},
    {id: '2600000019', label: 'Nauk Biologicznych i Weterynaryjnych'},
    {id: '2700000019', label: 'Nauk o Polityce i Bezpieczeństwie'},
    {id: '2800000019', label: 'Nauk o Ziemi i Gospodarki Przestrzennej'},
];

export const studyTypeOptions = [
    {
        label: 'Studia licencjackie',
        id: 'first_cycle',
    },
    {
        label: 'Studia uzupełniające magisterskie',
        id: 'second_cycle',
    },
    {
        label: 'Studia doktoranckie',
        id: 'third_cycle',
    },
    {
        label: 'Studia doktoranckie',
        id: 'phd_school',
    },
    {
        label: 'Studia jednolite magisterskie',
        id: 'long_cycle',
    },
];

/*export const studyPathOptions = [
    { label: 'rzeźba', id: '14510131-KRK' },
    { label: 'lekarski', id: '16510147-PR-KRK' },
    { label: 'lekarski', id: '16550147-PR-KRK' },
    { label: 'lekarski', id: '16540147-PR-KRK' },
    { label: 'sztuka mediów i edukacja wizualna', id: '14510101-KRK' },
    { label: 'architektura wnętrz', id: '14510102-KRK' },
    { label: 'inżynieria biomedyczna (studia wspólne)', id: '16510203-KRK' },
    { label: 'analityka medyczna', id: '17510149-PR-KRK' },
    { label: 'pielęgniarstwo', id: '18510000-PRZ' },
    { label: 'fizjoterapia', id: '18510000-PRZ' },
    { label: 'międzyobszarowe indywidualne studia humanistyczno-społeczne', id: '24510200-KRK' },
    { label: 'dietetyka', id: '18510000-PRZ' },
    { label: 'lekarski', id: '16510000-PRZ' },
    { label: 'farmacja', id: '17510000-PRZ' },
    { label: 'bezpieczeństwo narodowe', id: '27510201-KRK' },
    { label: 'Szkoła doktorska', id: '74510401' },
    { label: 'matematyka stosowana', id: '10510201-KRK' },
    { label: 'sztuka mediów i edukacja wizualna', id: '14510201-KRK' },
    { label: 'filologia klasyczna i studia śródziemnomorskie', id: '25510201-KRK' },
    { label: 'medioznawstwo', id: '24510201-KRK' },
    { label: 'biologia', id: '26510201-KRK' },
    { label: 'biologia', id: '26510301-KRK' },
    { label: 'studia menedżersko-finansowe', id: '11520201-KRK' },
    { label: 'doradztwo podatkowe', id: '13510301-KRK' },
    { label: 'terapia zajęciowa', id: '18510201-KRK' },
    { label: 'gospodarka przestrzenna i geozarządzanie', id: '28510301-KRK' },
    { label: 'matematyka stosowana', id: '10510201-KRK' },
    { label: 'gospodarka przestrzenna i geozarządzanie', id: '28510301-3S-KRK' },
    { label: 'gospodarka przestrzenna', id: '28510201-KRK' },
    { label: 'optyka okularowa z elementami optometrii', id: '16510201-KRK' },
    { label: 'doradztwo podatkowe', id: '13510301-KRK' },
    { label: 'terapia zajęciowa', id: '18510201-KRK' },
    { label: 'doradztwo podatkowe', id: '13520301-KRK' },
    { label: 'architektura wnętrz', id: '14510202-KRK' },
    { label: 'weterynaria', id: '26510102-KRK' },
    { label: 'komunikacja i psychologia w biznesie', id: '11510202-OG-KRK' },
    { label: 'lingwistyka praktyczna i copywriting', id: '25510202-KRK' },
    { label: 'optometria', id: '16510302-KRK' },
    { label: 'prawo ochrony środowiska', id: '13510302-KRK' },
    { label: 'chemia i technologia żywności', id: '06510202-KRK' },
    { label: 'geografia', id: '28510202-KRK' },
    { label: 'geografia', id: '28510302-KRK' },
    { label: 'komunikacja i psychologia w biznesie', id: '11520202-OG-KRK' },
    { label: 'psychologia', id: '24510102-KRK' },
    { label: 'Szkoła doktorska', id: '74510402' },
    { label: 'analiza danych', id: '10510302-3S-KRK' },
    { label: 'logistyka', id: '11520203-KRK' },
    { label: 'logistyka', id: '11510203-KRK' },
    { label: 'ochrona środowiska', id: '26510303-KRK' },
    { label: 'ochrona środowiska', id: '26510203-KRK' },
    { label: 'Szkoła doktorska', id: '74510403' },
    { label: 'Szkoła doktorska', id: '74510404' },
    { label: 'biotechnologia medyczna', id: '16510304-KRK' },
    { label: 'biotechnologia', id: '26510304-KRK' },
    { label: 'biotechnologia medyczna', id: '16510204-KRK' },
    { label: 'pedagogika przedszkolna i wczesnoszkolna', id: '24510104-KRK' },
    { label: 'biotechnologia', id: '26510204-KRK' },
    { label: 'chemia', id: '06510305-3S-KRK' },
    { label: 'architektura informacji', id: '24510205-KRK' },
    { label: 'filologia polska jako obca', id: '25510205-KRK' },
    { label: 'chemia', id: '06510305-KRK' },
    { label: 'chemia', id: '06510305-3S-KRK' },
    { label: 'architektura informacji', id: '24510305-KRK' },
    { label: 'chemia', id: '06550305-KRK' },
    { label: 'chemia', id: '06510205-KRK' },
    { label: 'chemia', id: '06550205-KRK' },
    { label: 'Szkoła doktorska', id: '74510405' },
    { label: 'chemia medyczna', id: '06510206-KRK' },
    { label: 'filologia polska', id: '25510206-KRK' },
    { label: 'filologia polska', id: '25510306-KRK' },
    { label: 'biologia sądowa', id: '26510206-KRK' },
    { label: 'pedagogika medialna', id: '24510206-KRK' },
    { label: 'chemia medyczna', id: '06510306-KRK' },
    { label: 'biologia sądowa', id: '26510206-KRK' },
    { label: 'chemia kryminalistyczna', id: '06510307-KRK' },
    { label: 'sport i wellness', id: '26510207-KRK' },
    { label: 'astronomia', id: '08510208-KRK' },
    { label: 'astronomia', id: '08510308-KRK' },
    { label: 'wychowanie fizyczne i sport', id: '24510308-KRK' },
    { label: 'diagnostyka molekularna', id: '26510308-KRK' },
    { label: 'logopedia', id: '25510208-KRK' },
    { label: 'fizyka', id: '08510209-KRK' },
    { label: 'fizyka', id: '08510309-KRK' },
    { label: 'filologia angielska', id: '25510210-KRK' },
    { label: 'fizyka techniczna', id: '08510210-KRK' },
    { label: 'fizyka techniczna', id: '08510310-3S-KRK' },
    { label: 'fizyka techniczna', id: '08510310-KRK' },
    { label: 'filologia angielska', id: '25510310-KRK' },
    { label: 'filologia angielska', id: '25520210-KRK' },
    { label: 'filologia angielska', id: '25520310-KRK' },
    { label: 'filozofia', id: '24510311-KRK' },
    { label: 'filozofia', id: '24510211-KRK' },
    { label: 'filologia bałkańska', id: '25510311-KRK' },
    { label: 'filologia bałkańska', id: '25510211-KRK' },
    { label: 'filologia germańska', id: '25510312-KRK' },
    { label: 'pedagogika', id: '24520312-KRK' },
    { label: 'pedagogika', id: '24510212-KRK' },
    { label: 'pedagogika', id: '24520212-KRK' },
    { label: 'filologia germańska', id: '25510212-KRK' },
    { label: 'pedagogika', id: '24510312-KRK' },
    { label: 'pedagogika', id: '24520312-KRK' },
    { label: 'politologia', id: '27510313-KRK' },
    { label: 'filologia romańska', id: '25510213-KRK' },
    { label: 'filologia romańska', id: '25510313-KRK' },
    { label: 'politologia', id: '27510213-KRK' },
    { label: 'socjologia', id: '24510314-KRK' },
    { label: 'filologia rosyjska', id: '25510214-KRK' },
    { label: 'filologia rosyjska', id: '25510314-KRK' },
    { label: 'socjologia', id: '24510214-KRK' },
    { label: 'filologia włoska', id: '25510215-KRK' },
    { label: 'matematyka', id: '10510215-KRK' },
    { label: 'matematyka', id: '10510315-KRK' },
    { label: 'informatyka', id: '10510216-INZ-KRK' },
    { label: 'informatyka', id: '10510216-KRK' },
    { label: 'informatyka', id: '10520316-KRK' },
    { label: 'informatyka', id: '10520216-INZ-KRK' },
    { label: 'informatyka', id: '10510316-3S-KRK' },
    { label: 'informatyka', id: '10510316-KRK' },
    { label: 'informatyka', id: '10520216-KRK' },
    { label: 'japonistyka', id: '25510316-KRK' },
    { label: 'japonistyka', id: '25510216-KRK' },
    { label: 'ekonomia', id: '11550317-KRK' },
    { label: 'ekonomia', id: '11510317-KRK' },
    { label: 'lingwistyka stosowana', id: '25510217-F-ACH-KRK' },
    { label: 'ekonomia', id: '11510217-KRK' },
    { label: 'lingwistyka stosowana', id: '25510217-R-ACH-KRK' },
    { label: 'ekonomia', id: '11510317-KRK' },
    { label: 'ekonomia', id: '11550317-KRK' },
    { label: 'lingwistyka stosowana', id: '25510217-W-ACH-KRK' },
    { label: 'finanse i rachunkowość', id: '11510318-KRK' },
    { label: 'finanse i rachunkowość', id: '11520318-KRK' },
    { label: 'finanse i rachunkowość', id: '11510218-KRK' },
    { label: 'finanse i rachunkowość', id: '11520218-KRK' },
    { label: 'zarządzanie', id: '11550319-KRK' },
    { label: 'zarządzanie', id: '11520219-KRK' },
    { label: 'zarządzanie', id: '11510319-KRK' },
    { label: 'zarządzanie', id: '11510219-KRK' },
    { label: 'zarządzanie', id: '11520319-KRK' },
    { label: 'zarządzanie', id: '11550219-KRK' },
    { label: 'archeologia', id: '12510220-KRK' },
    { label: 'archeologia', id: '12510320-KRK' },
    { label: 'historia', id: '12510321-KRK' },
    { label: 'historia', id: '12510221-KRK' },
    { label: 'historia sztuki', id: '14510222-KRK' },
    { label: 'historia sztuki', id: '14510322-KRK' },
    { label: 'stosunki międzynarodowe', id: '27510323-KRK' },
    { label: 'stosunki międzynarodowe', id: '27510223-KRK' },
    { label: 'zarządzanie informacją i bibliologia', id: '24510324-KRK' },
    { label: 'etnologia - antropologia kulturowa', id: '25510225-KRK' },
    { label: 'etnologia - antropologia kulturowa', id: '25510325-KRK' },
    { label: 'prawo', id: '13510126-KRK' },
    { label: 'prawo', id: '13520126-KRK' },
    { label: 'administracja', id: '13510227-KRK' },
    { label: 'administracja', id: '13520327-KRK' },
    { label: 'administracja', id: '13510327-KRK' },
    { label: 'administracja', id: '13520227-KRK' },
    { label: 'administracja', id: '13520327-KRK' },
    { label: 'administracja', id: '13510227-KRK' },
    { label: 'malarstwo', id: '14510129-KRK' },
    { label: 'malarstwo', id: '14520229' },
    { label: 'grafika', id: '14510130-KRK' },
    { label: 'edukacja artystyczna w zakresie sztuk plastycznych', id: '14510332-KRK' },
    { label: 'konserwacja i restauracja dzieł sztuki', id: '14510133-KRK' },
    { label: 'ochrona dóbr kultury', id: '14510234-KRK' },
    { label: 'ochrona dóbr kultury', id: '14510334-KRK' },
    { label: 'ochrona dóbr kultury', id: '14520334-KRK' },
    { label: 'teologia', id: '15510135-KRK' },
    { label: 'teologia', id: '15510135-KA-KRK' },
    { label: 'lekarski', id: '16510147-KRK' },
    { label: 'lekarski', id: '16550147-KRK' },
    { label: 'lekarski', id: '16540147-KRK' },
    { label: 'biotechnologia', id: '16510348-KRK' },
    { label: 'analityka medyczna', id: '17510149-OG-KRK' },
    { label: 'farmacja', id: '17510150-KRK' },
    { label: 'farmacja', id: '17540150-KRK' },
    { label: 'farmacja', id: '17550150-KRK' },
    { label: 'pielęgniarstwo', id: '18550251-KRK' },
    { label: 'pielęgniarstwo', id: '18510351-KRK' },
    { label: 'pielęgniarstwo', id: '18520351-KRK' },
    { label: 'pielęgniarstwo', id: '18510251-KRK' },
    { label: 'fizjoterapia', id: '18520252-OG-KRK' },
    { label: 'fizjoterapia', id: '18510152-KRK' },
    { label: 'fizjoterapia', id: '18540252-OG-KRK' },
    { label: 'fizjoterapia', id: '18520152-KRK' },
    { label: 'fizjoterapia', id: '18540352-KRK' },
    { label: 'fizjoterapia', id: '18540152-KRK' },
    { label: 'fizjoterapia', id: '18550152-KRK' },
    { label: 'fizjoterapia', id: '18520352-KRK' },
    { label: 'fizjoterapia', id: '18510352-KRK' },
    { label: 'zdrowie publiczne', id: '18510353-KRK' },
    { label: 'automatyka i robotyka', id: '08510254-KRK' },
    { label: 'automatyka i robotyka', id: '08510354-3S-KRK' },
    { label: 'kulturoznawstwo', id: '25510255-KRK' },
    { label: 'kulturoznawstwo', id: '25510355-KRK' },
    { label: 'kosmetologia', id: '17520256-KRK' },
    { label: 'kosmetologia', id: '17510356-OG-KRK' },
    { label: 'kosmetologia', id: '17510256-KRK' },
    { label: 'kosmetologia', id: '17540256-KRK' },
    { label: 'archiwistyka i zarządzanie dokumentacją', id: '12510357-KRK' },
    { label: 'archiwistyka i zarządzanie dokumentacją', id: '12510257-KRK' },
    { label: 'turystyka i rekreacja', id: '28510358-KRK' },
    { label: 'turystyka i rekreacja', id: '28510258-KRK' },
    { label: 'pedagogika specjalna', id: '24510159-KRK' },
    { label: 'pedagogika specjalna', id: '24510359-KRK' },
    { label: 'dietetyka', id: '18520261-KRK' },
    { label: 'dietetyka', id: '18510361-KRK' },
    { label: 'dietetyka', id: '18510261-KRK' },
    { label: 'dietetyka', id: '18510361-KRK' },
    { label: 'ratownictwo medyczne', id: '18510262-KRK' },
    { label: 'ratownictwo medyczne', id: '18520262-KRK' },
    { label: 'położnictwo', id: '18520364-KRK' },
    { label: 'położnictwo', id: '18510364-KRK' },
    { label: 'położnictwo', id: '18510264-KRK' },
    { label: 'dziennikarstwo i komunikacja społeczna', id: '24510267-KRK' },
    { label: 'dziennikarstwo i komunikacja społeczna', id: '24510367-KRK' },
    { label: 'informatyka stosowana', id: '08510269-KRK' },
    { label: 'informatyka stosowana', id: '08510369-3S-KRK' },
    { label: 'matematyka i ekonomia', id: '10510270-KRK' },
    { label: 'kognitywistyka', id: '24510371-KRK' },
    { label: 'kognitywistyka', id: '24510271-KRK' },
    { label: 'praca socjalna', id: '24510373-KRK' },
    { label: 'praca socjalna', id: '24510273-KRK' },
    { label: 'nauki o rodzinie', id: '15510376-KRK' },
    { label: 'nauki o rodzinie', id: '15510276-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', id: '27520377-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', id: '27520277-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', id: '27510277-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', id: '27510377-KRK' },
    { label: 'wojskoznawstwo', id: '12510282-KRK' },
    { label: 'wojskoznawstwo', id: '12510382-KRK' },
    { label: 'krytyka artystyczna', id: '14510284-KRK' },
    { label: 'chemia kosmetyczna', id: '06510385-KRK' },
    { label: 'chemia kosmetyczna', id: '06510285-KRK' },
    { label: 'elektroradiologia', id: '18520289-OG-KRK' },
    { label: 'elektroradiologia', id: '18510289-OG-KRK' },
    { label: 'audiofonologia', id: '18520290-KRK' },
    { label: 'studia skandynawsko-bałtyckie', id: '12510395-KRK' },
];*/

export const studyPathOptions = [
    { label: 'administracja', label_en: 'administration', id: '13510327-KRK' },
    { label: 'administracja', label_en: 'administration', id: '13520227-KRK' },
    { label: 'administracja', label_en: 'administration', id: '13520327-KRK' },
    { label: 'administracja', label_en: 'administration', id: '13510227-KRK' },
    { label: 'analityka medyczna', label_en: 'medical analytics', id: '17510149-PR-KRK' },
    { label: 'analityka medyczna', label_en: 'medical analytics', id: '17510149-OG-KRK' },
    { label: 'analiza danych', label_en: 'data analysis', id: '10510302-3S-KRK' },
    { label: 'archeologia', label_en: 'archeology', id: '12510320-KRK' },
    { label: 'archeologia', label_en: 'archeology', id: '12510220-KRK' },
    { label: 'architektura informacji', label_en: 'information architecture', id: '24510205-KRK' },
    { label: 'architektura informacji', label_en: 'information architecture', id: '24510305-KRK' },
    { label: 'architektura wnętrz', label_en: 'interior design', id: '14510102-KRK' },
    { label: 'architektura wnętrz', label_en: 'interior design', id: '14510202-KRK' },
    { label: 'archiwistyka i zarządzanie dokumentacją', label_en: 'archiving and documentation management', id: '12510357-KRK' },
    { label: 'archiwistyka i zarządzanie dokumentacją', label_en: 'archiving and documentation management', id: '12510257-KRK' },
    { label: 'astronomia', label_en: 'astronomy', id: '08510308-KRK' },
    { label: 'astronomia', label_en: 'astronomy', id: '08510208-KRK' },
    { label: 'audiofonologia', label_en: 'audiophonology', id: '18520290-KRK' },
    { label: 'automatyka i robotyka', label_en: 'Automation and Robotics', id: '08510354-3S-KRK' },
    { label: 'automatyka i robotyka', label_en: 'Automation and Robotics', id: '08510254-KRK' },
    { label: 'bezpieczeństwo narodowe', label_en: 'National security', id: '27510201-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', label_en: 'internal security', id: '27520277-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', label_en: 'internal security', id: '27510277-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', label_en: 'internal security', id: '27510377-KRK' },
    { label: 'bezpieczeństwo wewnętrzne', label_en: 'internal security', id: '27520377-KRK' },
    { label: 'biologia', label_en: 'biology', id: '26510201-KRK' },
    { label: 'biologia', label_en: 'biology', id: '26510301-KRK' },
    { label: 'biologia sądowa', label_en: 'forensic biology', id: '26510206-KRK' },
    { label: 'biotechnologia', label_en: 'biotechnology', id: '26510204-KRK' },
    { label: 'biotechnologia', label_en: 'biotechnology', id: '16510348-KRK' },
    { label: 'biotechnologia', label_en: 'biotechnology', id: '26510304-KRK' },
    { label: 'biotechnologia medyczna', label_en: 'medical biotechnology', id: '16510304-KRK' },
    { label: 'biotechnologia medyczna', label_en: 'medical biotechnology', id: '16510204-KRK' },
    { label: 'chemia', label_en: 'chemistry', id: '06510305-KRK' },
    { label: 'chemia', label_en: 'chemistry', id: '06550305-KRK' },
    { label: 'chemia', label_en: 'chemistry', id: '06510205-KRK' },
    { label: 'chemia', label_en: 'chemistry', id: '06550205-KRK' },
    { label: 'chemia', label_en: 'chemistry', id: '06510305-3S-KRK' },
    { label: 'chemia i technologia żywności', label_en: 'food chemistry and technology', id: '06510202-KRK' },
    { label: 'chemia kosmetyczna', label_en: 'cosmetic chemistry', id: '06510285-KRK' },
    { label: 'chemia kosmetyczna', label_en: 'cosmetic chemistry', id: '06510385-KRK' },
    { label: 'chemia kryminalistyczna', label_en: 'forensic chemistry', id: '06510307-KRK' },
    { label: 'chemia medyczna', label_en: 'medical chemistry', id: '06510306-KRK' },
    { label: 'chemia medyczna', label_en: 'medical chemistry', id: '06510206-KRK' },
    { label: 'diagnostyka molekularna', label_en: 'molecular diagnostics', id: '26510308-KRK' },
    { label: 'dietetyka', label_en: 'dietetics', id: '18510361-KRK' },
    { label: 'dietetyka', label_en: 'dietetics', id: '18510000-PRZ' },
    { label: 'dietetyka', label_en: 'dietetics', id: '18520261-KRK' },
    { label: 'dietetyka', label_en: 'dietetics', id: '18510261-KRK' },
    { label: 'doradztwo podatkowe', label_en: 'tax consultancy', id: '13520301-KRK' },
    { label: 'doradztwo podatkowe', label_en: 'tax consultancy', id: '13510301-KRK' },
    { label: 'dziennikarstwo i komunikacja społeczna', label_en: 'Journalism and Social Communication', id: '24510367-KRK' },
    { label: 'dziennikarstwo i komunikacja społeczna', label_en: 'Journalism and Social Communication', id: '24510267-KRK' },
    { label: 'edukacja artystyczna w zakresie sztuk plastycznych', label_en: 'artistic education in the field of visual arts', id: '14510332-KRK' },
    { label: 'ekonomia', label_en: 'economy', id: '11510317-KRK' },
    { label: 'ekonomia', label_en: 'economy', id: '11550317-KRK' },
    { label: 'ekonomia', label_en: 'economy', id: '11510217-KRK' },
    { label: 'elektroradiologia', label_en: 'electroradiology', id: '18510289-OG-KRK' },
    { label: 'elektroradiologia', label_en: 'electroradiology', id: '18520289-OG-KRK' },
    { label: 'etnologia - antropologia kulturowa', label_en: 'ethnology - cultural anthropology', id: '25510325-KRK' },
    { label: 'etnologia - antropologia kulturowa', label_en: 'ethnology - cultural anthropology', id: '25510225-KRK' },
    { label: 'farmacja', label_en: 'pharmacy', id: '17540150-KRK' },
    { label: 'farmacja', label_en: 'pharmacy', id: '17550150-KRK' },
    { label: 'farmacja', label_en: 'pharmacy', id: '17510000-PRZ' },
    { label: 'farmacja', label_en: 'pharmacy', id: '17510150-KRK' },
    { label: 'filologia angielska', label_en: 'English philology', id: '25520310-KRK' },
    { label: 'filologia angielska', label_en: 'English philology', id: '25510210-KRK' },
    { label: 'filologia angielska', label_en: 'English philology', id: '25510310-KRK' },
    { label: 'filologia angielska', label_en: 'English philology', id: '25520210-KRK' },
    { label: 'filologia bałkańska', label_en: 'Balkan philology', id: '25510211-KRK' },
    { label: 'filologia bałkańska', label_en: 'Balkan philology', id: '25510311-KRK' },
    { label: 'filologia germańska', label_en: 'Germanic philology', id: '25510212-KRK' },
    { label: 'filologia germańska', label_en: 'Germanic philology', id: '25510312-KRK' },
    { label: 'filologia klasyczna i studia śródziemnomorskie', label_en: 'classical philology and Mediterranean studies', id: '25510201-KRK' },
    { label: 'filologia polska', label_en: 'Polish philology', id: '25510306-KRK' },
    { label: 'filologia polska', label_en: 'Polish philology', id: '25510206-KRK' },
    { label: 'filologia polska jako obca', label_en: 'Polish philology as a foreign language', id: '25510205-KRK' },
    { label: 'filologia romańska', label_en: 'Roman philology', id: '25510313-KRK' },
    { label: 'filologia romańska', label_en: 'Roman philology', id: '25510213-KRK' },
    { label: 'filologia rosyjska', label_en: 'Russian philology', id: '25510314-KRK' },
    { label: 'filologia rosyjska', label_en: 'Russian philology', id: '25510214-KRK' },
    { label: 'filologia włoska', label_en: 'Italian philology', id: '25510215-KRK' },
    { label: 'filozofia', label_en: 'philosophy', id: '24510311-KRK' },
    { label: 'filozofia', label_en: 'philosophy', id: '24510211-KRK' },
    { label: 'finanse i rachunkowość', label_en: 'Finance and Accounting', id: '11510318-KRK' },
    { label: 'finanse i rachunkowość', label_en: 'Finance and Accounting', id: '11520318-KRK' },
    { label: 'finanse i rachunkowość', label_en: 'Finance and Accounting', id: '11510218-KRK' },
    { label: 'finanse i rachunkowość', label_en: 'Finance and Accounting', id: '11520218-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18520352-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18510352-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18510000-PRZ' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18520252-OG-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18510152-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18540252-OG-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18520152-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18540352-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18540152-KRK' },
    { label: 'fizjoterapia', label_en: 'physiotherapy', id: '18550152-KRK' },
    { label: 'fizyka', label_en: 'physics', id: '08510309-KRK' },
    { label: 'fizyka', label_en: 'physics', id: '08510209-KRK' },
    { label: 'fizyka techniczna', label_en: 'Technical Physics', id: '08510310-KRK' },
    { label: 'fizyka techniczna', label_en: 'Technical Physics', id: '08510210-KRK' },
    { label: 'fizyka techniczna', label_en: 'Technical Physics', id: '08510310-3S-KRK' },
    { label: 'geografia', label_en: 'geography', id: '28510202-KRK' },
    { label: 'geografia', label_en: 'geography', id: '28510302-KRK' },
    { label: 'gospodarka przestrzenna', label_en: 'spatial management', id: '28510201-KRK' },
    { label: 'gospodarka przestrzenna i geozarządzanie', label_en: 'spatial management and geomanagement', id: '28510301-3S-KRK' },
    { label: 'gospodarka przestrzenna i geozarządzanie', label_en: 'spatial management and geomanagement', id: '28510301-KRK' },
    { label: 'grafika', label_en: 'graphics', id: '14510130-KRK' },
    { label: 'historia', label_en: 'history', id: '12510321-KRK' },
    { label: 'historia', label_en: 'history', id: '12510221-KRK' },
    { label: 'historia sztuki', label_en: 'history of art', id: '14510222-KRK' },
    { label: 'historia sztuki', label_en: 'history of art', id: '14510322-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10510316-3S-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10510316-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10520216-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10510216-INZ-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10510216-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10520316-KRK' },
    { label: 'informatyka', label_en: 'information technology', id: '10520216-INZ-KRK' },
    { label: 'informatyka stosowana', label_en: 'Applied Computer Science', id: '08510369-3S-KRK' },
    { label: 'informatyka stosowana', label_en: 'Applied Computer Science', id: '08510269-KRK' },
    { label: 'inżynieria biomedyczna (studia wspólne)', label_en: 'biomedical engineering (joint studies)', id: '16510203-KRK' },
    { label: 'japonistyka', label_en: 'Japanese studies', id: '25510216-KRK' },
    { label: 'japonistyka', label_en: 'Japanese studies', id: '25510316-KRK' },
    { label: 'kognitywistyka', label_en: 'cognitive science', id: '24510271-KRK' },
    { label: 'kognitywistyka', label_en: 'cognitive science', id: '24510371-KRK' },
    { label: 'komunikacja i psychologia w biznesie', label_en: 'communication and psychology in business', id: '11510202-OG-KRK' },
    { label: 'komunikacja i psychologia w biznesie', label_en: 'communication and psychology in business', id: '11520202-OG-KRK' },
    { label: 'konserwacja i restauracja dzieł sztuki', label_en: 'conservation and restoration of works of art', id: '14510133-KRK' },
    { label: 'kosmetologia', label_en: 'cosmetology', id: '17520256-KRK' },
    { label: 'kosmetologia', label_en: 'cosmetology', id: '17510356-OG-KRK' },
    { label: 'kosmetologia', label_en: 'cosmetology', id: '17510256-KRK' },
    { label: 'kosmetologia', label_en: 'cosmetology', id: '17540256-KRK' },
    { label: 'krytyka artystyczna', label_en: 'art criticism', id: '14510284-KRK' },
    { label: 'kulturoznawstwo', label_en: 'cultural studies', id: '25510355-KRK' },
    { label: 'kulturoznawstwo', label_en: 'cultural studies', id: '25510255-KRK' },
    { label: 'lekarski', label_en: 'medical', id: '16510000-PRZ' },
    { label: 'lekarski', label_en: 'medical', id: '16540147-PR-KRK' },
    { label: 'lekarski', label_en: 'medical', id: '16510147-PR-KRK' },
    { label: 'lekarski', label_en: 'medical', id: '16510147-KRK' },
    { label: 'lekarski', label_en: 'medical', id: '16550147-KRK' },
    { label: 'lekarski', label_en: 'medical', id: '16540147-KRK' },
    { label: 'lekarski', label_en: 'medical', id: '16550147-PR-KRK' },
    { label: 'lingwistyka praktyczna i copywriting', label_en: 'practical linguistics and copywriting', id: '25510202-KRK' },
    { label: 'lingwistyka stosowana', label_en: 'applied linguistics', id: '25510217-W-ACH-KRK' },
    { label: 'lingwistyka stosowana', label_en: 'applied linguistics', id: '25510217-F-ACH-KRK' },
    { label: 'lingwistyka stosowana', label_en: 'applied linguistics', id: '25510217-R-ACH-KRK' },
    { label: 'logistyka', label_en: 'logistics', id: '11520203-KRK' },
    { label: 'logistyka', label_en: 'logistics', id: '11510203-KRK' },
    { label: 'logopedia', label_en: 'speech therapy', id: '25510208-KRK' },
    { label: 'malarstwo', label_en: 'painting', id: '14520229' },
    { label: 'malarstwo', label_en: 'painting', id: '14510129-KRK' },
    { label: 'matematyka', label_en: 'mathematics', id: '10510215-KRK' },
    { label: 'matematyka', label_en: 'mathematics', id: '10510315-KRK' },
    { label: 'matematyka i ekonomia', label_en: 'mathematics and economics', id: '10510270-KRK' },
    { label: 'matematyka stosowana', label_en: 'applied mathematics', id: '10510201-KRK' },
    { label: 'medioznawstwo', label_en: 'media studies', id: '24510201-KRK' },
    { label: 'międzyobszarowe indywidualne studia humanistyczno-społeczne', label_en: 'interdisciplinary individual studies in the humanities and social sciences', id: '24510200-KRK' },
    { label: 'nauki o rodzinie', label_en: 'Family Studies', id: '15510376-KRK' },
    { label: 'nauki o rodzinie', label_en: 'Family Studies', id: '15510276-KRK' },
    { label: 'ochrona dóbr kultury', label_en: 'protection of cultural property', id: '14510334-KRK' },
    { label: 'ochrona dóbr kultury', label_en: 'protection of cultural property', id: '14520334-KRK' },
    { label: 'ochrona dóbr kultury', label_en: 'protection of cultural property', id: '14510234-KRK' },
    { label: 'ochrona środowiska', label_en: 'environmental Protection', id: '26510203-KRK' },
    { label: 'ochrona środowiska', label_en: 'environmental Protection', id: '26510303-KRK' },
    { label: 'optometria', label_en: 'optometry', id: '16510302-KRK' },
    { label: 'optyka okularowa z elementami optometrii', label_en: 'spectacle optics with elements of optometry', id: '16510201-KRK' },
    { label: 'pedagogika', label_en: 'education', id: '24520312-KRK' },
    { label: 'pedagogika', label_en: 'education', id: '24520212-KRK' },
    { label: 'pedagogika', label_en: 'education', id: '24510212-KRK' },
    { label: 'pedagogika', label_en: 'education', id: '24510312-KRK' },
    { label: 'pedagogika medialna', label_en: 'media pedagogy', id: '24510206-KRK' },
    { label: 'pedagogika przedszkolna i wczesnoszkolna', label_en: 'preschool and early school pedagogy', id: '24510104-KRK' },
    { label: 'pedagogika specjalna', label_en: 'special pedagogy', id: '24510159-KRK' },
    { label: 'pedagogika specjalna', label_en: 'special pedagogy', id: '24510359-KRK' },
    { label: 'pielęgniarstwo', label_en: 'nursing', id: '18520351-KRK' },
    { label: 'pielęgniarstwo', label_en: 'nursing', id: '18510251-KRK' },
    { label: 'pielęgniarstwo', label_en: 'nursing', id: '18510000-PRZ' },
    { label: 'pielęgniarstwo', label_en: 'nursing', id: '18550251-KRK' },
    { label: 'pielęgniarstwo', label_en: 'nursing', id: '18510351-KRK' },
    { label: 'politologia', label_en: 'political science', id: '27510213-KRK' },
    { label: 'politologia', label_en: 'political science', id: '27510313-KRK' },
    { label: 'położnictwo', label_en: 'obstetrics', id: '18510364-KRK' },
    { label: 'położnictwo', label_en: 'obstetrics', id: '18510264-KRK' },
    { label: 'położnictwo', label_en: 'obstetrics', id: '18520364-KRK' },
    { label: 'praca socjalna', label_en: 'social work', id: '24510373-KRK' },
    { label: 'praca socjalna', label_en: 'social work', id: '24510273-KRK' },
    { label: 'prawo', label_en: 'law', id: '13510126-KRK' },
    { label: 'prawo', label_en: 'law', id: '13520126-KRK' },
    { label: 'prawo ochrony środowiska', label_en: 'environmental law', id: '13510302-KRK' },
    { label: 'psychologia', label_en: 'psychology', id: '24510102-KRK' },
    { label: 'ratownictwo medyczne', label_en: 'emergency medical Services', id: '18510262-KRK' },
    { label: 'ratownictwo medyczne', label_en: 'emergency medical Services', id: '18520262-KRK' },
    { label: 'rzeźba', label_en: 'sculpture', id: '14510131-KRK' },
    { label: 'socjologia', label_en: 'sociology', id: '24510314-KRK' },
    { label: 'socjologia', label_en: 'sociology', id: '24510214-KRK' },
    { label: 'sport i wellness', label_en: 'sport and wellness', id: '26510207-KRK' },
    { label: 'stosunki międzynarodowe', label_en: 'international relations', id: '27510323-KRK' },
    { label: 'stosunki międzynarodowe', label_en: 'international relations', id: '27510223-KRK' },
    { label: 'studia menedżersko-finansowe', label_en: 'management and financial studies', id: '11520201-KRK' },
    { label: 'studia skandynawsko-bałtyckie', label_en: 'Scandinavian-Baltic studies', id: '12510395-KRK' },    
    { label: 'szkoła doktorska', label_en: 'doctoral school', id: '74510401' },
    { label: 'szkoła doktorska', label_en: 'doctoral school', id: '74510402' },
    { label: 'szkoła doktorska', label_en: 'doctoral school', id: '74510403' },
    { label: 'szkoła doktorska', label_en: 'doctoral school', id: '74510404' },
    { label: 'szkoła doktorska', label_en: 'doctoral school', id: '74510405' },
    { label: 'sztuka mediów i edukacja wizualna', label_en: 'media art and visual education', id: '14510101-KRK' },
    { label: 'sztuka mediów i edukacja wizualna', label_en: 'media art and visual education', id: '14510201-KRK' },
    { label: 'teologia', label_en: 'theology', id: '15510135-KA-KRK' },
    { label: 'teologia', label_en: 'theology', id: '15510135-KRK' },
    { label: 'terapia zajęciowa', label_en: 'occupational therapy', id: '18510201-KRK' },
    { label: 'turystyka i rekreacja', label_en: 'Tourism and Recreation', id: '28510358-KRK' },
    { label: 'turystyka i rekreacja', label_en: 'Tourism and Recreation', id: '28510258-KRK' },
    { label: 'weterynaria', label_en: 'veterinary medicine', id: '26510102-KRK' },
    { label: 'wojskoznawstwo', label_en: 'military science', id: '12510382-KRK' },
    { label: 'wojskoznawstwo', label_en: 'military science', id: '12510282-KRK' },
    { label: 'wychowanie fizyczne i sport', label_en: 'physical education and sport', id: '24510308-KRK' },
    { label: 'zarządzanie', label_en: 'management', id: '11510219-KRK' },
    { label: 'zarządzanie', label_en: 'management', id: '11520319-KRK' },
    { label: 'zarządzanie', label_en: 'management', id: '11550219-KRK' },
    { label: 'zarządzanie', label_en: 'management', id: '11550319-KRK' },
    { label: 'zarządzanie', label_en: 'management', id: '11520219-KRK' },
    { label: 'zarządzanie', label_en: 'management', id: '11510319-KRK' },
    { label: 'zarządzanie informacją i bibliologia', label_en: 'information management and bibliology', id: '24510324-KRK' },
    { label: 'zdrowie publiczne', label_en: 'public health', id: '18510353-KRK' },
];

export const studyPathOptionsWithId = studyPathOptions.map((val, key) => ({
    id: val.id,
    label: val.label + ' (' + val.id + ')'
}));

export const studyYear = [
    { id: 'I rok(s1)', label: '1st year (s1)' },
    { id: 'II rok(s1)', label: '2nd year (s1)' },
    { id: 'III rok(s1)', label: '3rd year (s1)' },
    { id: 'I rok(s2)', label: '1st year (s2)' },
    { id: 'II rok(s2)', label: '2nd year (s2)' },
    { id: 'I rok(sj)', label: '1st year (sj)' },
    { id: 'II rok(sj)', label: '2nd year (sj)' },
    { id: 'III rok(sj)', label: '3rd year (sj)' },
    { id: 'IV rok(sj)', label: '4th year (sj)' },
    { id: 'V rok(sj)', label: '5th year (sj)' },
]

export default function CalendarEventsSuggestedEditScreen() {
    const {id} = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [categories] = useApiResource(API.CalendarEventsCategories.all);
    const [markers] = useApiResource(MarkersRepository.all);
    const {data, errors, saving, confirming, onChange, save, confirm} = useEditData(CalendarSuggestedEventsRepository);

    const handleDelete = () => API.CalendarSuggestedEvents.delete(id).then(() => history.push(Routes.CalendarEvents.List));

    if (!data || !categories || !markers) {
        return (
            <>
                <BackToMain title="Powrót do listy" to={Routes.CalendarSuggestedEvents.List}/>
                {(data === null || categories === null || markers === null) && <CircularProgress/>}
            </>
        );
    }

    return (
        <>
            <BackToMain title="Powrót do listy" to={Routes.CalendarSuggestedEvents.List}/>
            <Title value={'Zdarzenie ' + (data?.title?.pl || '')}/>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <FormInput
                                            title={'Nazwa punktu'}
                                            name={'title'}
                                            value={data.title}
                                            onChange={onChange}
                                            error={errors?.title}
                                            translatable
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Html
                                name={'description'}
                                value={data.description}
                                onChange={onChange}
                                error={errors?.description}
                                translatable
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                        {/* Dates */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}>
                                                <label>Data i czas</label>
                                            </Grid>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <DateInput
                                                    name={'start_date'}
                                                    value={data.start_date}
                                                    onChange={onChange}
                                                    error={errors?.start_date}
                                                />
                                                {!data.is_full_day && (
                                                    <>
                                                        <TimeInput
                                                            name={'start_date'}
                                                            value={data.start_date}
                                                            onChange={onChange}
                                                            error={errors?.start_date}
                                                            className={classes.calendarEventDateInput}
                                                        />
                                                        <p>
                                                            do&nbsp;&nbsp;
                                                        </p>
                                                        <DateInput
                                                            name={'end_date'}
                                                            value={data.end_date}
                                                            onChange={onChange}
                                                            error={errors?.end_date}
                                                        />
                                                        <TimeInput
                                                            name={'end_date'}
                                                            value={data.end_date}
                                                            onChange={onChange}
                                                            error={errors?.end_date}
                                                            className={classes.calendarEventDateInput}
                                                        />
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>

                                        {/* Full day */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}/>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Checkbox
                                                    name={'is_full_day'}
                                                    title={'Wydarzenie calodniowe'}
                                                    value={data.is_full_day}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Location */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}>
                                                <label>Lokalizacja</label>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.formControl}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={3}>
                                                        <Dropdown
                                                            name={'show_location_link'}
                                                            value={!!data.show_location_link}
                                                            options={LocationOptions}
                                                            onChange={onChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {!!data.show_location_link && (
                                                            <Input
                                                                name={'location_link'}
                                                                value={data.location_link}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        {!data.show_location_link && (
                                                            <Dropdown
                                                                name={'marker_id'}
                                                                value={data.marker_id || ''}
                                                                options={markers}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Category */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}>
                                                <label>Kategoria wydarzenia</label>
                                            </Grid>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Dropdown
                                                    name={'category_id'}
                                                    value={data.category_id}
                                                    options={categories}
                                                    onChange={onChange}
                                                    error={errors?.category_id}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Groups */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}>
                                                <label>Grupa studentów</label>
                                            </Grid>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Grid item xs={12} md={4}>
                                                    <label style={{fontSize: 15}}>Wybrany wydział</label>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Dropdown
                                                        multiple
                                                        name={'study_faculty'}
                                                        value={data?.study_faculty || []}
                                                        options={facultyType}
                                                        titleField={'label'}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}/>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Grid item xs={12} md={4}>
                                                    <label style={{fontSize: 15}}>Kierunek</label>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Dropdown
                                                        multiple
                                                        name={'study_path'}
                                                        value={data?.study_path || []}
                                                        options={studyPathOptionsWithId}
                                                        titleField={'label'}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}/>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Grid item xs={12} md={4}>
                                                    <label style={{fontSize: 15}}>Typ studiów</label>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Dropdown
                                                        multiple
                                                        name={'study_type'}
                                                        value={data?.study_type || []}
                                                        options={studyTypeOptions}
                                                        titleField={'label'}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}/>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Grid item xs={12} md={4}>
                                                    <label style={{fontSize: 15}}>Rok</label>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Dropdown
                                                        multiple
                                                        name={'study_year'}
                                                        value={data?.study_year || []}
                                                        options={studyYear}
                                                        titleField={'label'}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Reminders */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} className={classes.title}>
                                                <label>Przypomnienie</label>
                                            </Grid>
                                            <Grid item xs={12} md={9} className={classes.formControl}>
                                                <Checkbox
                                                    name={'reminder_enabled'}
                                                    title={'Przypomnienie'}
                                                    value={data.reminder_enabled}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        {!!data.reminder_enabled && (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={3} className={classes.title}/>
                                                <Grid item xs={12} md={9} className={classes.formControl}>
                                                    <Dropdown
                                                        name={'reminder_option'}
                                                        value={data.reminder_option}
                                                        options={ReminderOptions}
                                                        onChange={onChange}
                                                        error={errors?.reminder_option}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!!data.reminder_enabled && data.reminder_option === CustomReminderOptionValue && (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={3} className={classes.title}/>
                                                <Grid item xs={12} md={9} className={classes.formControl}>
                                                    <DateTimeInput
                                                        name={'custom_reminder'}
                                                        value={data.custom_reminder || null}
                                                        onChange={onChange}
                                                        error={errors?.custom_reminder}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        {/* Files */}
                        <FilesPicker
                            name={'files'}
                            value={data.files}
                            onChange={onChange}
                            multiple
                        />
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {!!id && (
                                    <DeleteButton
                                        title="Czy na pewno chcesz usunąć ten zdarzenia?"
                                        handleDelete={handleDelete}/>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <SaveButton saving={saving} onClick={save}/>
                            </Grid>
                            {!!data['confirm_date'] && <Grid item xs={4}>
                                <DeleteButton
                                        title="Czy na pewno chcesz cofnij publikację ten zdarzenia?"
                                        buttonTitle={'Cofnij'}
                                        handleDelete={() => {
                                            confirm({needDecline: true})
                                        }}/>
                            </Grid>}
                            {!data['confirm_date'] && <Grid item xs={4}>
                                <SaveButton saving={confirming} title={'Zatwierdz'} onClick={confirm}/>
                            </Grid>}
                        </Grid>
                    </Card>
                    <Card>
                        <ThumbnailPicker
                            name={'image'}
                            value={data.image}
                            onChange={onChange}
                        />
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
