import React, { useState, useRef, useMemo } from 'react';
import PaginatedTable from '../../components/Table/PaginatedTable';
import { TableCell, TableRow } from '@material-ui/core';
import API from '../../apis/API';
import moment from 'moment';
import Routes from '../../router/Routes';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { faculties, fieldsOfStudy } from '../../apis/Dictionaries';

const mappedFieldsOfStudy = [];

for (let { faculty, name, study } of fieldsOfStudy) {
    let field = mappedFieldsOfStudy.filter((field) => field.faculty == faculty && field.name == name)[0];
    if (!field) {
        field = {
            faculty,
            name,
            studies: []
        }
        mappedFieldsOfStudy.push(field);
    }
    field.studies.push(study);
};

const Students = props => {
    const history = useHistory();
    const { title } = props;
    const { addToast } = useToasts();
    const fileInput = useRef(null);
    const [filteredFieldsOfStudy, setFilteredFieldsOfStudy] = useState(mappedFieldsOfStudy);
    const fieldsOfStudyOptions = useMemo(() => [
        { value: '', label: '-' },
        ...filteredFieldsOfStudy.map(field => ({ value: field.studies.join(','), label: field.name }))
    ], [filteredFieldsOfStudy]);

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nick studenta',
            name: 'nick_name',
        },
        {
            title: 'Numer indeksu',
            name: 'index',
        },
        {
            title: 'E-mail',
            name: 'email',
        },
        {
            title: 'Data ostatniego logowania',
            name: 'last_login_date',
        },
    ];

    const renderRow = (item, index) => (
        <TableRow key={index} onClick={() => history.push(Routes.Students.Show(item.id))}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.nick_name}</TableCell>
            <TableCell>{item.index}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>{moment(item.last_login_date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

    const buttons = [
        {
            title: 'Dodaj daty ukończenia studiów',
            onClick: () => { fileInput.current.click() }
        },
        {
            title: 'Usuń historię statusów',
            onClick: () => {
                API.studentGraduationDates.deleteAll().then(result => {
                    addToast('Status history deleted!');
                });
            }
        },
        {
            title: 'Export',
            onClick: () => {
                API.Students.export().then(result => {
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'students.xls'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
            }
        },
    ];

    const handleFileUpload = event => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        API.Students.iportGraduationDates(formData).then(result => {
            addToast('File imported!');
        });
    };

    const filters = [
        {
            options: [
                { value: '', label: '-' },
                ...faculties.sort((a, b) => a.label.localeCompare(b.label))
            ],
            name: 'faculty',
            label: 'wydział',
            onChange: value => setFilteredFieldsOfStudy(value !== '' ? mappedFieldsOfStudy.filter(field => field.faculty == value) : mappedFieldsOfStudy),
        },
        {
            options: fieldsOfStudyOptions.sort((a, b) => a.label.localeCompare(b.label)),
            name: 'field_of_study',
            label: 'kierunek',
        },
    ];

    return (
        <>
            <input
                ref={fileInput}
                onChange={handleFileUpload}
                type="file"
                hidden
            />
            <PaginatedTable
                title={title}
                endpoint={API.Students.index}
                columns={columns}
                renderRow={renderRow}
                buttons={buttons}
                filters={filters}
            />
        </>
    );
};

export default Students;
