import React, { useState, useEffect } from 'react';
import {
	Grid, Card, Checkbox, Typography, Divider
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChipInput from 'material-ui-chip-input';
import API from "../../apis/API";
import Routes from "../../router/Routes";
import BackToMain from "../../components/Buttons/BackToMain";
import Title from "../../components/Text/Title";
import ValidatorInput from "../../components/Form/ValidatorInput";
import CreateButton from "../../components/Buttons/CreateButton";
import ThumbnailPicker from "../../components/Form/ThumbnailPicker";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";
import FillButton from '../../components/Buttons/FillButton';
import Progress from "../../components/Main/Progress";
import Conditions from "./Conditions";
import AddBadgeType from "./AddBadgeType";
import { makeStyles } from "@material-ui/styles";
import { faculties, fieldsOfStudy, studyLevels } from '../../apis/Dictionaries';
import LocaleButtons from 'components/Buttons/LocaleButtons';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(2),
		paddingRight: theme.spacing(20)
	},
	progressContainer: {
		position: 'absolute',
		top: '50%',
		width: 'calc(100% - 600px)',
		display: 'flex',
		justifyContent: 'center',
	},
	progress: {
		color: theme.palette.pink
	},
	checkbox: {
		padding: '10px',
	},
	upload_card: {
		marginTop: theme.spacing(2),
	},
	badge_type: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '20px'
	},
	title: {
		fontSize: '0.875em',
		fontWeight: '500',
		marginTop: '8px'
	},
	addType: {
		textAlign: 'right'
	},
	promotionRule: {
		paddingTop: '12px',
		'& > .MuiGrid-root': {
			paddingBottom: '12px',
			paddingLeft: '6px',

			'& select': {
				paddingRight: '25px'
			}
		}
	},
	preview: {
		width: 150,
		height: 150,
		borderRadius: '50%',
		margin: 'auto',
		display: 'flex'
	}
}));

const BadgesAdd = props => {
	const { history, title } = props;
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts();
	const [progressStatus, setProgressStatus] = useState(false);
	const [flag_name_value, setFlagNameValue] = useState('pl');
	const [en_badge_name, setEnBadgeName] = useState('');
	const [pl_badge_name, setPlBadgeName] = useState('');
	const [en_badge_description, setEnBadgeDescription] = useState('');
	const [pl_badge_description, setPlBadgeDescription] = useState('');
	const [error_en_badge_name, setErrorEnBadgeName] = useState(false);
	const [error_pl_badge_name, setErrorPlBadgeName] = useState(false);
	const [error_en_badge_description, setErrorEnBadgeDescription] = useState(false);
	const [error_pl_badge_description, setErrorPlBadgeDescription] = useState(false);
	const [badge_type, setBadgetype] = useState(1);
	const [badge_type_list, setBadgetypeList] = useState([]);
	const [amount, setAmount] = useState('');
	const [error_amount, setErrorAmount] = useState(false);
	const [promoted, setPromoted] = useState(1);
	const [allow_certificate, setAllowCertificate] = useState(false);
	const promoted_list = [
		{ id: 0, name: "TAK" },
		{ id: 1, name: "NIE" },
	];
	const [badge_image, setBadgeImage] = useState(null);
	const [selected_students, setSelectedStudents] = useState(null);
	const [conditions, setConditions] = useState([
		{ id: 0, name: 'Wybierz warunek przyznania odznaki' },
		{ id: 1, name: 'Wydział', label: 'wydział', children: [] },
		{ id: 2, name: 'Kierunek', label: 'kierunek', children: [] },
		{ id: 3, name: 'Typ studiów', label: 'Typ', children: [] },
		{ id: 4, name: 'Średnia ocen z przedmiotów z grupy', label: 'grupa', children: [] },
		{ id: 5, name: 'liczba zaliczonych przedmiotów z grupy', label: 'grupa', children: [] },
		{ id: 6, name: 'zaliczony przedmiot', label: 'kod przedmiotu', children: {} },
		{ id: 7, name: 'Ocena', label: 'kod przedmiotu', children: {} },
	]);
	const [selected_conditions, setSelectedConditions] = useState([]);
	const [addtional_badges, setAddtionalBadges] = useState([]);
	const [promotion_rules, setPromotionRules] = useState({
		faculties: [0],
		fields_of_study: [0],
		study_level: [0],
		year_of_study: [0]
	});
	const [promotion_rule_list, setPromotionRuleList] = useState({
		faculties: [],
		fields_of_study: [],
		study_level: [],
		year_of_study: [
			{ id: 0, name: 'wybrany rok' },
			{ id: 'I rok(s1)', name: 'I rok(s1)' },
			{ id: 'II rok(s1)', name: 'II rok(s1)' },
			{ id: 'III rok(s1)', name: 'III rok(s1)' },
			{ id: 'I rok(s2)', name: 'I rok(s2)' },
			{ id: 'II rok(s2)', name: 'II rok(s2)' },
			{ id: 'I rok(sj)', name: 'I rok(sj)' },
			{ id: 'II rok(sj)', name: 'II rok(sj)' },
			{ id: 'III rok(sj)', name: 'III rok(sj)' },
			{ id: 'IV rok(sj)', name: 'IV rok(sj)' },
			{ id: 'V rok(sj)', name: 'V rok(sj)' },
		]
	});

	const handleChange = (order, value, error) => {
		switch (order) {
			case 1:
				setErrorEnBadgeName(error);
				setEnBadgeName(value);
				break;
			case 2:
				setErrorEnBadgeDescription(error);
				setEnBadgeDescription(value);
				break;
			case 3:
				setErrorPlBadgeName(error);
				setPlBadgeName(value);
				break;
			case 4:
				setErrorPlBadgeDescription(error);
				setPlBadgeDescription(value);
				break;
			case 5:
				setBadgetype(parseInt(value));
				break;
			case 6:
				setErrorAmount(error);
				setAmount(value);
				break;
			case 7:
				setPromoted(parseInt(value));
				break;
			case 8:
				setAllowCertificate(value);
				break;
			case 9:
				let newVal = value.join(',').split(',');
				let unique = [...new Set(newVal)];
				// remove white space and zero
				let array = unique.filter(function (str) {
					return /\S/.test(str) && str !== 0;
				});
				setSelectedStudents(array);
				break;
			case 10:
				setSelectedConditions(value);
				break;
			default:
				return;
		}
	};

	const handlePromotionRulesChange = (e) => {
		const target = e.target;
		let value = target.value;
		const type = target.name;
		setPromotionRules(prev => {
			value = value.filter(v => v !== 0);
			value = value.length < 1 ? [0] : value;
			prev[type] = value;
			return { ...prev };
		});
	};

	const checkValidate = () => {
		return en_badge_name.length === 0
			|| en_badge_description.length === 0
			|| pl_badge_name.length === 0
			|| pl_badge_description.length === 0
			|| amount.length === 0;
	};

	const handleValidate = () => {
		setErrorEnBadgeName(en_badge_name.length === 0);
		setErrorEnBadgeDescription(en_badge_description.length === 0);
		setErrorPlBadgeName(pl_badge_name.length === 0);
		setErrorPlBadgeDescription(pl_badge_description.length === 0);
		setErrorAmount(amount.length === 0);
	};

	const handleSave = () => {
		removeAllToasts();
		if (checkValidate()) {
			handleValidate();
			addToast(<label>Proszę wypełnić wszystkie wymagane pola.</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false });
		} else {
			setProgressStatus(true);

			const newAddtionalBadges = addtional_badges.filter(badge => {
				if (badge.name_en === '') return false;

				let newSelectedConditions = badge.selected_conditions.map(c => {
					delete c.children;
					return c;
				});
				badge.selected_conditions = newSelectedConditions;
				return badge;
			});

			API.badges.create(en_badge_name, pl_badge_name, en_badge_description, pl_badge_description, badge_type, amount, promoted, allow_certificate, badge_image, selected_students, selected_conditions, newAddtionalBadges, promotion_rules)
				.then(response => {
					if (response.code === 401) {
						history.push('/login');
					} else {
						addToast(<label>{response.message}</label>, { appearance: response.code === 200 ? 'success' : 'error', autoDismissTimeout: response.code === 200 ? 1000 : 3000, autoDismiss: response.code === 200 ? true : false });
						if (response.code === 200) {
							setTimeout(function () { history.push(Routes.Badges.List); }, 1000);
						}
						setProgressStatus(false);
					}
				});
		}
	};

	const addNewBadgeType = () => {
		const newBadge = {
			name_en: '',
			name_pl: '',
			description_en: '',
			description_pl: '',
			points: '',
			image: null,
			selected_conditions: [],
			id: new Date().getTime()
		};

		setAddtionalBadges(prev => {
			return [...prev, newBadge];
		});
	};

	const handleBadgeTypeChange = (badge, i) => {
		setAddtionalBadges(prev => {
			prev[i] = badge;
			return prev;
		});
	};

	const handleBadgeTypeDelete = (index) => {
		setAddtionalBadges(prev => {
			prev.splice(index, 1);
			return [...prev];
		});
	};

	const formatFaculties = () => {
		return (faculties.map(f => ({ id: f.value, name: f.label }))).sort((a, b) => a.name.localeCompare(b.name));
	};

	const formatFieldsOfStudy = () => {
		return (fieldsOfStudy.map(f => ({ ...f, id: f.study, name: `${f.name} (${f.study})` }))).sort((a, b) => a.name.localeCompare(b.name));
	};

	const formatStudyLevels = () => {
		return (Object.entries(studyLevels).map(s => ({ id: s[0], name: s[1] }))).sort((a, b) => a.name.localeCompare(b.name));
	};

	// Get badge type list
	useEffect(() => {
		API.badges.info()
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else {
					setBadgetypeList(response.data.badge_type);
				}
			});
	}, [history]);

	useEffect(() => {
		setConditions(prev => {
			prev[1].children = formatFaculties();
			prev[2].children = formatFieldsOfStudy();
			prev[3].children = formatStudyLevels();
			return prev;
		});
		setPromotionRuleList(prev => {
			let formattedFaculties = formatFaculties();
			formattedFaculties.unshift({ id: 0, name: 'Wydział' });
			prev.faculties = formattedFaculties;

			let formattedFieldsOfStudy = formatFieldsOfStudy();
			formattedFieldsOfStudy.unshift({ id: 0, name: 'Kierunek' });
			prev.fields_of_study = formattedFieldsOfStudy;

			let formattedStudyLevels = formatStudyLevels();
			formattedStudyLevels.unshift({ id: 0, name: 'Typ studiów' });
			prev.study_level = formattedStudyLevels;
			return prev;

		});
		API.groups.getAll().then(group => {
			setConditions(prev => {
				prev[4].children = group;
				prev[5].children = group;
				return prev;
			});
		});
	}, []);

	return (
		<>
			<BackToMain title="Powrót do listy odznak" to={Routes.Badges.List} />
			<Title value={title} />
			<LocaleButtons value={flag_name_value} onChange={setFlagNameValue} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Grid container direction="column" spacing={3}>
						<Grid item>
							<Card className={classes.card}>
								{flag_name_value === 'en' ? (
									<>
										<ValidatorInput
											title="Nazwa odznaki"
											type="text"
											value={en_badge_name}
											handleChange={(value, error) => handleChange(1, value, error)}
											hasError={error_en_badge_name}
											error_message="Proszę wypełnić to pole."
										/>
										<ValidatorInput
											title="Opis"
											type="autosizetxt"
											value={en_badge_description}
											handleChange={(value, error) => handleChange(2, value, error)}
											hasError={error_en_badge_description}
											error_message="Proszę wypełnić to pole."
										/>
									</>
								) : (
									<>
										<ValidatorInput
											title="Nazwa odznaki"
											type="text"
											value={pl_badge_name}
											handleChange={(value, error) => handleChange(3, value, error)}
											hasError={error_pl_badge_name}
											error_message="Proszę wypełnić to pole."
										/>
										<ValidatorInput
											title="Opis"
											type="autosizetxt"
											value={pl_badge_description}
											handleChange={(value, error) => handleChange(4, value, error)}
											hasError={error_pl_badge_description}
											error_message="Proszę wypełnić to pole."
										/>
									</>
								)}

							</Card>
						</Grid>
						<Grid item>
							<Title value="Definicja odznaki" />
							<Card className={classes.card}>
								<ValidatorInput
									title="Rodzaj odznaki"
									type="single"
									value={badge_type}
									list={badge_type_list}
									handleChange={(value, error) => handleChange(5, value, error)}
								/>
								{badge_type === 2 &&
									<>
										<Conditions title="Warunki przyznania" value={selected_conditions} conditions={conditions} handleChange={value => handleChange(10, value)} />
										{addtional_badges.map((badge, i) => (
											<Grid key={i} container style={{ marginBlock: '20px' }}>
												<Grid item xs={3}>
													<Title className={classes.title} value={`${i + 1}. Typ odznaki`} />
												</Grid>
												<Grid item xs={9}>
													<AddBadgeType badge={badge} handleChange={data => { handleBadgeTypeChange(data, i); }} handleDelete={() => handleBadgeTypeDelete(i)} />
												</Grid>
											</Grid>
										))}

										<Grid item xs={3} className={classes.addType}>
											<FillButton title="Dodaj kolejny typ" onClick={addNewBadgeType} />
										</Grid>
									</>
								}
								<ValidatorInput
									title="Punkty w rankingu"
									type="number"
									value={amount}
									handleChange={(value, error) => handleChange(6, value, error)}
									hasError={error_amount}
									error_message="Proszę wypełnić to pole."
								/>
								<ValidatorInput
									title="Czy promowana"
									type="single"
									value={promoted}
									list={promoted_list}
									handleChange={(value, error) => handleChange(7, value, error)}
								/>
								{promoted === 0 &&
									<Grid container>
										<Grid item xs={3}>
											<Title className={classes.title} value="Zasady promowania" />
										</Grid>
										<Grid item container xs={9} className={classes.promotionRule}>
											<Grid item xs={6}>
												<Dropdown
													multiple
													name={'faculties'}
													value={promotion_rules.faculties || []}
													options={promotion_rule_list.faculties}
													titleField={'name'}
													onChange={handlePromotionRulesChange}
												/>
											</Grid>
											<Grid item xs={6}>
												<Dropdown
													multiple
													name={'fields_of_study'}
													value={promotion_rules.fields_of_study || []}
													options={promotion_rule_list.fields_of_study}
													titleField={'name'}
													onChange={handlePromotionRulesChange}
												/>
											</Grid>
											<Grid item xs={6}>
												<Dropdown
													multiple
													name={'study_level'}
													value={promotion_rules.study_level || []}
													options={promotion_rule_list.study_level}
													titleField={'name'}
													onChange={handlePromotionRulesChange}
												/>
											</Grid>
											<Grid item xs={6}>
												<Dropdown
													multiple
													name={'year_of_study'}
													value={promotion_rules.year_of_study || []}
													options={promotion_rule_list.year_of_study}
													titleField={'name'}
													onChange={handlePromotionRulesChange}
												/>
											</Grid>
										</Grid>
									</Grid>
								}
								<Grid container spacing={3}>
									<Grid item xs={3}></Grid>
									<FormControlLabel
										control={<Checkbox color="default" checked={allow_certificate} onChange={(event) => handleChange(8, event.target.checked)} name="checkedA" />}
										label="Umożliwiaj generowanie certyfikatu"
										className={classes.checkbox}
									/>
								</Grid>
							</Card>
						</Grid>
						{badge_type === 1 &&
							<Grid item>
								<Title value="Studenci posiadający odznakę" />
								<Card className={classes.card}>
									<ChipInput
										label={<label>Wpisz numer indeksu studenta.</label>}
										fullWidth={true}
										defaultValue={selected_students}
										onChange={(chips) => handleChange(9, chips)}
										placeholder="1,2,3"
									/>
								</Card>
							</Grid>
						}
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<CreateButton title="Dodaj" onClick={handleSave} />
					</Card>
					<Card className={classes.upload_card}>
						<ThumbnailPicker value={badge_image} onChange={(value) => setBadgeImage(value.target.value)} editLabel="Edytuj obrazek" deleteLabel="Usuń obrazek" />
						<Typography style={{ marginTop: 10, paddingLeft: 10 }}>Podgląd odznaki</Typography>
						<Divider style={{ marginBlock: 10 }} />
						{badge_image &&
							<img
								className={classes.preview}
								src={typeof badge_image === "string" ? badge_image : URL.createObjectURL(badge_image)}
								alt="badge"
							/>
						}
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default BadgesAdd;
