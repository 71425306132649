import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useToasts } from 'react-toast-notifications';

import API from "../../apis/API";
import Routes from "../../router/Routes";
import makeFormData from "../../utils/makeFormData";
import useApiResource from "../../hooks/useApiResource";
import SiteInfoContext from "../../utils/SiteInfoContext";
import Title from "../../components/Text/Title";
import Progress from "../../components/Main/Progress";
import SaveButton from "../../components/Buttons/SaveButton";
import BackToMain from "../../components/Buttons/BackToMain";
import DeleteButton from "../../components/Buttons/DeleteButton";
import ValidatorInput from "../../components/Form/ValidatorInput";
import LocaleButtons from 'components/Buttons/LocaleButtons';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	label: {
		'& > .MuiGrid-root': {
			alignItems: 'flex-start'
		}
	}
}));

const ZdarzeniaForm = props => {
	const { history, title } = props;
	const { id } = useParams();
	const classes = useStyles();
	const siteInfo = useContext(SiteInfoContext);
	const { addToast, removeAllToasts } = useToasts();
	const [progressStatus, setProgressStatus] = useState(false);
	const [saving, setSaving] = useState(false);
	const [flag_name_value, setFlagNameValue] = useState('pl');
	const [zdarzeniaTypes] = useApiResource(API.zdarzenia.types);
	const [data, setData] = useState({
		name: {
			pl: '',
			en: ''
		},
		description: {
			pl: '',
			en: '',
		},
		type: 0
	});
	const [errors, setErrors] = useState({});

	useEffect(() => siteInfo.user?.role?.id !== 1 && history.push('/login'), [siteInfo, history]);

	useEffect(() => {
		let typeId = zdarzeniaTypes?.types[0].id;

		if (typeId) {
			setData(prev => ({ ...prev, type: typeId }));
		}
	}, [zdarzeniaTypes]);

	useEffect(() => {
		if (!id) return;
		API.zdarzenia.single(id).then(zdarzenia => {
			setData({
				id,
				name: zdarzenia.name,
				description: zdarzenia.description,
				type: parseInt(zdarzenia.type)
			});
		});
	}, [id]);

	const handleSave = () => {
		let formData = makeFormData(data);

		removeAllToasts();
		if (checkValidate()) {
			handleValidate();
			addToast(<label>Proszę wypełnić wszystkie wymagane pola.</label>, { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false });
		} else {
			setProgressStatus(true);
			setSaving(true);
			API.zdarzenia.save(formData)
				.then(response => {
					goToList(response);
				});
		}
	};

	const handleDelete = () => {
		removeAllToasts();
		setProgressStatus(true);
		API.zdarzenia.delete(id)
			.then(response => {
				goToList(response);
			});
	};

	const goToList = (response) => {
		if (response.code === 401) {
			history.push('/login');
		} else {
			let appearance = 'error';
			let autoDismissTimeout = 3000;
			let autoDismiss = false;

			if (response.code === 200) {
				appearance = 'success';
				autoDismissTimeout = 1000;
				autoDismiss = true;
			}
			addToast(<label>{response.message}</label>, { appearance, autoDismissTimeout, autoDismiss });

			if (response.code === 200) {
				setTimeout(function () { history.push(Routes.Zdarzenia.List); }, 1000);
			}
			setProgressStatus(false);
			setSaving(false);
		}
	};

	const handleChange = (value, error, type, lang = '') => {
		setData(prev => ({
			...prev,
			[type]: lang === ''
				? value
				: { ...prev[type], [lang]: value }
		}));
	};

	const checkValidate = () => {
		return data.name.pl.length === 0
			|| data.name.en.length === 0
			|| data.description.pl.length === 0
			|| data.description.en.length === 0;
	};

	const handleValidate = () => {
		setErrors(prev => {
			prev['name_pl'] = data.name.pl.length === 0 ? true : false;
			prev['name_en'] = data.name.en.length === 0 ? true : false;
			prev['description_pl'] = data.description.pl.length === 0 ? true : false;
			prev['description_en'] = data.description.en.length === 0 ? true : false;
			return prev;
		});
	};

	return (
		<>
			<BackToMain title="Powrót do listy Zdarzenia" to={Routes.Zdarzenia.List} />
			<Title value={title ? title : 'Zdarzenie - informacje'} />
			<LocaleButtons value={flag_name_value} onChange={setFlagNameValue} />
			<Grid container spacing={3}>
				<Grid container item xs={9} direction="column" spacing={3}>
					<Grid item>
						<Card className={classes.card}>
							{flag_name_value === 'pl' ? (
								<>
									<ValidatorInput
										title="Nazwa zdarzenia"
										type="text"
										value={data.name.pl}
										handleChange={(value, error) => handleChange(value, error, 'name', 'pl')}
										hasError={errors?.name_pl}
										error_message="Proszę wypełnić to pole."
									/>
									<ValidatorInput
										title="Opis"
										type="autosizetxt"
										className={classes.label}
										value={data.description.pl}
										handleChange={(value, error) => handleChange(value, error, 'description', 'pl')}
										hasError={errors?.description_pl}
										error_message="Proszę wypełnić to pole."
									/>
								</>
							) : (
								<>
									<ValidatorInput
										title="Nazwa zdarzenia"
										type="text"
										value={data.name.en}
										handleChange={(value, error) => handleChange(value, error, 'name', 'en')}
										hasError={errors?.name_en}
										error_message="Proszę wypełnić to pole."
									/>
									<ValidatorInput
										title="Opis"
										type="autosizetxt"
										className={classes.label}
										value={data.description.en}
										handleChange={(value, error) => handleChange(value, error, 'description', 'en')}
										hasError={errors?.description_en}
										error_message="Proszę wypełnić to pole."
									/>
								</>
							)}

							<ValidatorInput
								title="Rodzaj zdarzenia"
								type="single"
								value={data.type}
								list={zdarzeniaTypes ? zdarzeniaTypes?.types : []}
								handleChange={(value, error) => handleChange(parseInt(value), error, 'type')}
							/>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<Grid container spacing={2}>
							{id &&
								<Grid item xs={6}>
									<DeleteButton
										title="Czy na pewno chcesz usunąć ten zdarzenia?"
										handleDelete={handleDelete} />
								</Grid>
							}
							<Grid item xs={id ? 6 : 12}>
								<SaveButton title="Zapisz zmiany" onClick={handleSave} saving={saving} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default ZdarzeniaForm;
