import React, { useState } from "react";
import { Grid, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inputBox: {
        width: '100%',
    },
}));

export default function PasswordInput({ title, error, ...rest }) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4} className={classes.title}><label>{title}</label></Grid>
                <Grid item xs={8}>
                    <OutlinedInput
                        className={classes.inputBox}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        error={!!error}
                        {...rest}
                    />
                    <ErrorLabel error={error} />
                </Grid>
            </Grid>
        </>
    );
};
