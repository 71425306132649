import Repository from "./Repository";
import Routes from "../router/Routes";
import Validator, {Email, Latitude, LengthLimit, Longitude, Required} from "../utils/Validator";

export default new class CalendarEventsRepository extends Repository {
    Path = 'markers';
    Routes = Routes.Markers;
    Validators = {
        title: new Validator(Required),
        category_id: new Validator(Required),
        address: new Validator(Required, LengthLimit()),
        latitude: new Validator(Required, Latitude, LengthLimit(10)),
        longitude: new Validator(Required, Longitude, LengthLimit(11)),
        phone: new Validator(LengthLimit()),
        second_phone: new Validator(LengthLimit()),
        email: new Validator(Email, LengthLimit()),
    };

    defaultValues = () => ({
        title: {
            pl: '',
            en: '',
        },
        category_id: '',
    });
}
