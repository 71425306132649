import React, {useEffect} from 'react';
import {FormControl, InputLabel, Select} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
    },
    name_select_box: {
        fontStyle: 'italic',
        color: '#aeaeae',
        fontWeight: '400',
        '& .MuiInputLabel-outlined .MuiInputLabel-shrink': {
            transform: 'translate(14px -100px) scale(0.5)',
        },
    },
    input_box: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
        '& svg': {
            fill: theme.palette.text.secondary
        },
        color: theme.palette.text.primary,
        '& select': {
            border: `1px solid ${theme.palette.gray}`,
            borderRadius: '0px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 20px'
        }
    }
}));


const SingleSelect = (props) => {
    const classes = useStyles();
    const {value, handleChange, list, error, disabled} = props;

    return (
        <FormControl variant="outlined" className={classes.formControl} error={error} disabled={disabled}>
            <Select
                native
                value={value}
                onChange={(event) => handleChange(event.target.value ? event.target.value : {})}
                inputProps={{
                    name: 'age',
                    // id: 'outlined-age-native-simple',
                }}
                className={classes.input_box}
            >
                {list.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                ))}
            </Select>
        </FormControl>
    );
};

export default withRouter(SingleSelect);
