import React, {useState, useEffect, useRef} from 'react';
import {
    Card, TableCell, TableRow
} from '@material-ui/core';
import API from "../../apis/API";
import FillButton from "../../components/Buttons/FillButton";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import Routes from "../../router/Routes";
import moment from "moment";
import PaginatedTable from "../../components/Table/PaginatedTable";
import {useToasts} from "react-toast-notifications";


const useStyles = makeStyles((theme) => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%'
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0,)
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em'
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagenation_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    }
}));

const Groups = props => {
    const fileInput = useRef(null);
    const classes = useStyles();
    const history = useHistory();
    const { addToast } = useToasts();

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa grupy',
            name: 'name',
        },
        {
            title: 'Liczba przedmiotów w grupie',
            name: 'subject_count',
        },
        {
            title: 'Data ostatniej edycji',
            name: 'updated_at',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nową grupę',
            onClick: () => history.push(Routes.Groups.Create)
        },
        {
            component: FillButton,
            title: 'Importuj identyfikatory kursów',
            onClick: () => { fileInput.current.click() }
        }
    ];

    const handleFileUpload = event => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        API.groups.import(formData).then(result => {
            addToast('File imported!');
        });
    };

    const handleEditItem = item => history.push(Routes.Groups.Edit(item.id));

    const renderRow = (item, index) => (
        <TableRow key={index} className={classes.root} onClick={() => handleEditItem(item)}>
            <TableCell>{item.id}</TableCell>
            <TableCell className={classes.highlight}>{item.name}</TableCell>
            <TableCell>{item.courses_count}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    );

	return (
        <>
            <input
                ref={fileInput}
                onChange={handleFileUpload}
                type="file"
                hidden
            />
            <PaginatedTable
                buttons={buttons}
                endpoint={API.groups.byOption}
                renderRow={renderRow}
                columns={columns}
            />
        </>
	);
};

export default Groups;
