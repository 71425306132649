import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import Routes from "../../router/Routes";
import {useHistory} from "react-router-dom";
import PaginatedTable from "../../components/Table/PaginatedTable";
import FillButton from "../../components/Buttons/FillButton";
import {makeStyles} from "@material-ui/styles";
import {formatDateTime} from "../../utils/formatters";
import BackToMain from "../../components/Buttons/BackToMain";
import API from "../../apis/API";

const useStyles = makeStyles((theme) => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%'
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0,)
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em'
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1)
    },
    pagination_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color
        }
    }
}));

const AcademicYearsListScreen = ({children, title}) => {
    const classes = useStyles();
    const history = useHistory();

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa',
            name: 'name',
        },
        {
            title: 'Klucz sortowania',
            name: 'order_key',
        },
        {
            title: 'USOS ID',
            name: 'usos_id',
        },
        {
            title: 'Data rozpoczęcia',
            name: 'start_date',
        },
        {
            title: 'Data zakończenia (end_date)',
            name: 'end_date',
        },
        {
            title: 'Data zakończenia (finish_date)',
            name: 'finish_date',
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj nowy rok akademicki',
            onClick: () => history.push(Routes.AcademicYears.Create)
        }
    ];

    const handleEditItem = item => history.push(Routes.AcademicYears.Edit(item.id));

    const renderRow = (item, index) => (
        <TableRow key={index} className={classes.root} onClick={() => handleEditItem(item)}>
            <TableCell>{item.id}</TableCell>
            <TableCell className={classes.highlight}>{item.name}</TableCell>
            <TableCell>{item.order_key}</TableCell>
            <TableCell>{item.usos_id}</TableCell>
            <TableCell>{item.start_date}</TableCell>
            <TableCell>{item.end_date}</TableCell>
            <TableCell>{item.finish_date}</TableCell>
        </TableRow>
    );

    return (
        <>
            <BackToMain title="Powrót do listy" to={Routes.CalendarEvents.List}/>
            <PaginatedTable
                title={'Kalendarz akademicki - Zarządzaj Rokami Akademickimi'}
                buttons={buttons}
                endpoint={API.Terms.all}
                renderRow={renderRow}
                columns={columns}
            />
        </>
    );
};

export default AcademicYearsListScreen;
