import React from 'react'
import {ColorPicker as MaterialColorPicker} from 'material-ui-color';
import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center'
    },
    error: {
        border: '0.5px solid red',
    },
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
        fontFamily: 'roboto',
    },
}));

export default function ColorPicker({title, value, name, error, onChange, ...rest}) {
    const classes = useStyles();
    const change = color => onChange({target: {name, value: color?.hex ? ('#' + color.hex) : color}});
    return (
        <Grid container spacing={3}>
            <Grid item xs={3} className={classes.title}>
                <label>{title}</label>
            </Grid>
            <Grid item xs={9}>
                <MaterialColorPicker
                    name='color'
                    value={value}
                    onChange={change}
                    {...rest}
                />
                <div className={classes.error_label} style={{display: error ? 'block' : 'none'}}>
                    {error}
                </div>
            </Grid>
        </Grid>
    );
};
